/* begin handbook */
.handbook {
  &__header {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 70px;
    text-align: center;

    @include xs {
      align-items: flex-start;
      margin-bottom: 50px;
      text-align: left;
    }

    &--inner {
      align-items: flex-start;
      flex-direction: row;
      justify-content: center;
      margin-bottom: 39px;

      @include xs {
        align-items: center;
        margin-bottom: 58px;
      }

      .handbook {
        &__title {
          margin: 0;
          text-align: left;
        }
      }
    }
  }

  &__header-body {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }

  &__back {
    display: flex;
    align-items: center;
    margin: 7px 0 5px;
    transition: $transition;

    &:hover {
      opacity: 0.75;

      @include lg {
        opacity: 1;
      }
    }
  }

  &__back-icon {
    width: 10px;
    height: 10px;
    margin-right: 10px;
    transform: rotate(-90deg);
    fill: $orange;
  }

  &__back-text {
    font-size: 14px;
    color: $orange;
  }

  &__title {
    margin-bottom: 10px;
  }

  &__subtitle {
    font-size: 14px;
    color: $charcoal;

    @include xs {
      display: none;
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    width: 870px;
    max-width: 100%;
    margin: 0 auto;
  }

  &__item {
    width: 270px;
    height: 200px;
    margin: 0 30px 30px 0;

    @include md {
      width: 216px;
    }

    @include sm {
      width: 243px;
    }

    @include xs {
      width: 100%;
      height: auto;
      margin: 0 0 40px;
    }

    &:nth-child(3n) {
      margin-right: 0;

      @include sm {
        margin-right: 30px;
      }

      @include xs {
        margin-right: 0;
      }
    }

    &:nth-child(2n) {
      @include sm {
        margin-right: 0;
      }
    }

    &--inner {
      width: 270px;
      height: 240px;
      background-color: $white;
      box-shadow: 0 0 7px 0 rgba($charcoal, 0.1);

      @include xs {
        width: calc(100% + 40px);
        margin: 0 -20px !important;
        border-bottom: 1px solid $whiteTwo;
        box-shadow: none;
      }

      &:last-child {
        @include xs {
          border: 0;
        }
      }

      .handbook {
        &__link {
          padding: 20px 20px 30px;

          @include xs {
            position: relative;
            align-items: flex-start;
            padding: 20px;
          }
        }

        &__item-title {
          font-size: 14px;
          line-height: 20px;
          color: $charcoal;
          text-align: left;

          @include xs {
            font-size: 16px;
            font-weight: 300;
            line-height: 24px;
            color: $black;
          }
        }
      }
    }
  }

  &__link {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 38px 60px 0;

    @include xs {
      flex-direction: row;
      padding: 0 11px;
    }

    &:hover {
      .handbook {
        &__item-title {
          color: $orange;

          @include lg {
            color: $charcoal;
          }
        }
      }
    }
  }

  &__icon {
    width: 65px;
    height: 65px;
    margin: 0 0 18px;

    @include xs {
      margin: 0 32px 0 0;
    }

    &--header {
      width: 73px;
      height: 73px;
      margin: 0 50px 0 0;
    }
  }

  &__item-title {
    font-size: 14px;
    line-height: 20px;
    color: $charcoal;
    text-align: center;
    transition: $transition;

    @include xs {
      line-height: 16px;
      text-align: left;
    }
  }

  &__item-arrow {
    position: absolute;
    top: 50%;
    right: 20px;
    display: none;
    flex-shrink: 0;
    width: 12px;
    height: 12px;
    transform: translateY(-50%);

    @include xs {
      display: inline-flex;
    }
  }

  &__img {
    @include objectFit(contain, center);
    width: 100%;
    height: 130px;
    margin-bottom: 20px;

    @include xs {
      width: 100px;
      height: 100px;
      margin: 0 10px 0 0;
    }
  }
}
/* end handbook */
