/* begin devices */
.devices {
  &__list {
    display: flex;
    flex-wrap: wrap;
    padding: 0 129px;

    @include lg {
      padding: 0;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    margin: 0 58px 30px 0;

    @include md {
      width: 50%;
      padding-right: 58px;
      margin-right: 0;
    }

    @include sm {
      width: 100%;
      padding-right: 0;
    }

    &:nth-child(3n) {
      margin-right: 0;
    }

    &:hover {
      .devices__title {
        color: $orange;

        @include lg {
          color: $black;
        }
      }
    }
  }

  &__img {
    @include objectFit(contain, center);
    width: 85px;
    height: 85px;
    margin-right: 20px;
  }

  &__title {
    width: 160px;
    transition: $transition;

    @include md {
      width: 100%;
    }
  }
}
/* end devices */
