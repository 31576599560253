/* begin services */
.services {
  &__title {
    text-align: center;
  }

  &__form {
    width: 545px;
    max-width: 100%;
    margin: 50px auto;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    width: 25%;
    padding: 20px;
    cursor: pointer;
    transition: $transition;

    @include lg {
      width: 33.33%;
    }

    @include md {
      width: 50%;
    }

    @include sm {
      width: 100%;
      padding: 20px 0;
      border-bottom: 1px solid $whiteTwo;
    }

    &:hover {
      background-color: rgba(254, 144, 11, 0.05);
      box-shadow: 0 2px 4px 0 rgba(74, 74, 74, 0.1);

      @include lg {
        background-color: $white;
        box-shadow: none;
      }
    }

    &:first-child {
      @include sm {
        padding-top: 0;
      }
    }

    &:last-child {
      @include sm {
        border-bottom: 0;
      }
    }
  }

  &__item-title {
    margin-bottom: 20px;
    font-size: 16px;
    color: $black;
  }

  &__data-list-key {
    display: inline;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: $black;
  }

  &__data-list-value {
    display: inline;
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    color: $black;
  }

  &__link {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: $orange;
  }

  &__map {
    height: 552px;
    margin-top: 30px;

    @include sm {
      display: none;
    }
  }
}
/* end services */
