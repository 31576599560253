/* begin radio */
.radio {
  display: inline-flex;

  &__input {
    display: none;

    &:checked {
      & + .radio__body {
        .radio {
          &__el {
            border: 8px solid $orange;
          }
        }
      }
    }
  }

  &__body {
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
      .radio__el {
        border-color: $orange;
      }
    }
  }

  &__el {
    flex-shrink: 0;
    width: 25px;
    height: 25px;
    background-color: $white;
    border: 2px solid $greyPinkish;
    border-radius: 50%;
    transition: $transition;
  }

  &__text {
    margin-left: 20px;
    font-size: 16px;
    color: $charcoal;
  }
}
/* end radio */
