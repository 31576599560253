/* begin wrapper */
.wrapper {
  width: 1230px;
  padding: 0 30px;
  margin: 0 auto;

  &--mini {
    width: 1000px;

    @include lg {
      width: 972px;
      padding: 0 20px;
    }

    @include md {
      width: 748px;
    }

    @include sm {
      width: 556px;
    }

    @include xs {
      width: 100%;
    }
  }

  @include lg {
    width: 972px;
    padding: 0 20px;
  }

  @include md {
    width: 748px;
  }

  @include sm {
    width: 556px;
  }

  @include xs {
    width: 100%;
  }
}
/* end wrapper */
