/* begin basket */
.basket {
  margin-top: 70px;

  @include lg {
    margin-top: 30px;
  }

  &__wrapper {
    display: flex;

    @include lg {
      flex-wrap: wrap;
    }

    @include md {
      flex-direction: column;
    }
  }

  &__title {
    display: none;

    @include lg {
      display: block;
      width: 100%;
      margin-bottom: 50px;
    }
  }

  &__body {
    width: calc(100% - 277px);
    margin-right: 50px;

    @include md {
      width: 100%;
      margin-bottom: 30px;
    }

    @include xs {
      margin-bottom: 0;
    }
  }

  &__list {
    margin-bottom: 31px;

    @include xs {
      margin-bottom: 49px;
    }
  }

  &__item {
    &:last-child {
      .product--basket {
        margin: 0;
        border: 0;
      }
    }
  }

  &__coupon {
    width: 100%;

    @include xs {
      width: calc(100% + 40px);
      padding: 20px 20px 30px;
      margin: 0 -20px;
      background-color: $whisper;
    }
  }

  &__coupon-form {
    display: flex;
    flex-wrap: wrap;

    @include xs {
      flex-direction: column;
      height: auto;
    }
  }

  &__coupon-input-box {
    position: relative;
    width: calc(100% - 175px);
    margin-right: 10px;

    @include sm {
      width: 100%;
    }

    @include sm {
      order: 1;
      margin: 0 0 10px;
    }

    &--checked {
      .basket__coupon-checked {
        opacity: 1;
      }
    }
  }

  &__coupon-input {
    width: 100%;
    height: 60px;
    padding: 0 40px 0 20px;
    font-size: 16px;
    font-weight: 300;
    color: $charcoal;
    background-color: rgba($orange, 0.05);
    border: 0;
    border-radius: 5px;
    outline: 0;

    @include xs {
      height: 57px;
      background-color: $white;
    }
  }

  &__coupon-checked {
    position: absolute;
    top: 50%;
    right: 20px;
    width: 10px;
    height: 10px;
    opacity: 0;
    transform: translateY(-50%);
    transition: $transition;
    fill: $orange;
  }

  &__coupon-button {
    flex-shrink: 0;
    height: 60px;
    min-width: 165px;
    color: $grey;

    @include sm {
      order: 3;
      width: 100%;
      min-width: 0;
    }

    @include xs {
      height: 50px;
    }
  }

  &__coupon-error {
    width: 100%;
    margin-top: 5px;
    font-size: 14px;
    color: $red;

    @include sm {
      order: 2;
      margin: 2px 0 10px;
    }
  }

  &__sidebar {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    width: 227px;

    @include md {
      width: 100%;
    }
  }

  &__info {
    padding: 20px;
    margin-bottom: 20px;
    background-color: rgba($orange, 0.05);

    @include xs {
      padding: 0 20px 30px;
      margin: 0 -20px 30px;
      background-color: $whisper;
    }
  }

  &__order-price {
    display: flex;
    align-items: flex-end;
    margin-bottom: 30px;
  }

  &__order-price-title {
    font-size: 14px;
    color: $black;
  }

  &__order-price-num {
    margin-left: auto;
    font-size: 20px;
    line-height: 20px;
    color: $black;
  }

  &__info-text {
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    color: $black;
    text-align: center;

    @include md {
      text-align: left;
    }
  }

  &__sidebar-button {
    width: 250px;
    max-width: 100%;

    @include xs {
      width: 100%;
    }
  }
}
/* end basket */
