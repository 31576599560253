/* begin breadcrumbs */
.breadcrumbs {
  margin: 48px 0 50px;

  @include xs {
    margin-top: 30px;
  }

  &__list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  &__item {
    position: relative;
    margin-right: 24px;

    @include sm {
      display: none;

      &:nth-last-child(2) {
        display: inline-flex;
        align-items: center;
        margin: 0;

        &::before {
          position: relative;
          width: 11px;
          height: 11px;
          margin-right: 10px;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' fill='%23f68e5f'%3E%3Cpath d='M492 236H68.442l70.164-69.824c7.829-7.792 7.859-20.455.067-28.284-7.792-7.83-20.456-7.859-28.285-.068l-104.504 104c-.007.006-.012.013-.018.019-7.809 7.792-7.834 20.496-.002 28.314.007.006.012.013.018.019l104.504 104c7.828 7.79 20.492 7.763 28.285-.068 7.792-7.829 7.762-20.492-.067-28.284L68.442 276H492c11.046 0 20-8.954 20-20s-8.954-20-20-20z'/%3E%3C/svg%3E");
          background-size: contain;
          content: "";
        }

        &::after {
          content: none;
        }
      }
    }

    &::after {
      position: absolute;
      top: 3px;
      right: -14px;
      font-size: 14px;
      font-weight: 300;
      color: $black;
      content: "-";
    }

    &:last-child {
      margin-right: 0;
    }

    &--current {
      &::after {
        content: none;
      }

      .breadcrumbs {
        &__link {
          color: $grayDusty;
          pointer-events: none;
        }
      }
    }
  }

  &__link {
    font-size: 14px;
    font-weight: 300;
    color: $black;
    transition: $transition;

    &:hover {
      color: $orange;

      @include lg {
        color: $black;
      }
    }
  }
}
/* end breadcrumbs */
