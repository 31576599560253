/* begin range */
.range {
  position: relative;
  display: flex;
  align-items: center;
  height: 25px;

  &__line {
    width: 100%;
    height: 5px;
    background-color: rgba($greyPinkish, 0.5);
    border-radius: 2px;
  }

  &__connector {
    position: absolute;
    top: 50%;
    left: 0;
    z-index: 1;
    height: 5px;
    background-color: $orange;
    border-radius: 2px;
    transform: translateY(-50%);
  }

  &__circle {
    position: absolute;
    top: 0;
    z-index: 2;
    width: 25px;
    height: 25px;
    cursor: pointer;
    background-color: $white;
    border: 2px solid $orange;
    border-radius: 50%;

    &--start {
      left: 0;
    }

    &--finish {
      right: 40%;
    }
  }
}
/* end range */
