/* begin leader-list */
.leader-list {
  display: flex;
  flex-direction: column;

  &__row {
    position: relative;
    display: flex;
    align-items: flex-end;
    margin: 0 0 10px;

    &:last-child {
      margin-bottom: 0;
    }

    &::after {
      position: absolute;
      bottom: 7px;
      left: 0;
      width: 100%;
      border-bottom: 1px dotted $gainsboro;
      content: "";
    }
  }

  &__col {
    position: relative;
    z-index: 1;
    padding: 5px 5px 5px 0;
    font-size: 14px;
    font-weight: 300;
    color: $grey;
    background-color: #ffffff;

    &:first-child {
      display: inline-flex;
    }

    &:last-child {
      flex-shrink: 0;
      width: 51%;
      padding-left: 5px;
      margin: 0 0 0 auto;
      color: $black;
    }
  }
}
/* end leader-list */
