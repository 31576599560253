/* begin page */
body,
.page {
  overflow-y: scroll;
  font-family: "ubuntu", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #000000;
}

.page {
  position: relative;
  left: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  transition: 0.5s left;

  @include lg {
    padding-top: 65px;
  }

  &--push-open {
    left: 310px;
  }

  &__section-margin {
    margin-top: 100px;

    @include md {
      margin-top: 50px;
    }

    &--mini {
      margin-top: 52px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
}
/* end page */

