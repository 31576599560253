/* begin materials */
.materials {
  &__title-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 50px;
    text-align: center;
  }

  &__subtitle {
    margin-top: 20px;
    font-size: 14px;
    color: rgba($charcoal, 0.5);
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    display: flex;
    flex-direction: column;
    width: calc(16.66% - 15px);
    padding: 20px;
    margin: 0 18px 20px 0;
    background-color: $white;
    border-radius: 5px;
    box-shadow: 0 0 7px 0 rgba($charcoal, 0.1);
    transition: $transition box-shadow;

    @include lg {
      width: calc(20% - 14.4px);
    }

    @include md {
      width: calc(33.33% - 12px);
    }

    @include sm {
      width: calc(50% - 9px);
      padding: 17px 10px 20px;
    }

    &:hover {
      box-shadow: 0 0 10px 0 rgba($charcoal, 0.2);

      @include lg {
        box-shadow: 0 0 7px 0 rgba($charcoal, 0.1);
      }
    }

    &:nth-child(6n) {
      margin-right: 0;

      @include lg {
        margin-right: 18px;
      }
    }

    &:nth-child(5n) {
      @include lg {
        margin-right: 0;
      }

      @include md {
        margin-right: 18px;
      }
    }

    &:nth-child(3n) {
      @include md {
        margin-right: 0;
      }

      @include sm {
        margin-right: 18px;
      }
    }

    &:nth-child(2n) {
      @include sm {
        margin-right: 0;
      }
    }
  }

  &__img {
    @include objectFit(contain, center);
    width: auto;
    height: 109px;
    max-width: 100%;

    @include sm {
      height: 117px;
      max-width: calc(100% + 20px);
      margin: 0 -10px;
    }
  }

  &__res-list {
    margin-top: 30px;
  }

  &__res-item {
    display: flex;
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__res {
    margin-right: 10px;
    font-size: 14px;
    line-height: 1.43;
    color: $charcoal;
  }

  &__res-link {
    font-size: 14px;
    line-height: 1.43;
    color: $orange;
    transition: $transition;

    &:hover {
      color: lighten($orange, 3%);

      @include lg {
        color: $orange;
      }
    }
  }

  &__link {
    margin-top: 20px;
    font-size: 14px;
    line-height: 1.43;
    color: $charcoal;
    transition: $transition;

    &:hover {
      color: $orange;

      @include lg {
        color: $charcoal;
      }
    }
  }
}
/* end materials */
