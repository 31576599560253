/* begin colors */
/* end colors */
/* begin other */
/* end other */
/* begin fonts */
@font-face {
  font-family: "ubuntu";
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/ubuntu/ubuntu-v14-latin-ext_latin_cyrillic-ext_cyrillic-300.woff2") format("woff2"), url("../fonts/ubuntu/ubuntu-v14-latin-ext_latin_cyrillic-ext_cyrillic-300.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "ubuntu";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/ubuntu/ubuntu-v14-latin-ext_latin_cyrillic-ext_cyrillic-regular.woff2") format("woff2"), url("../fonts/ubuntu/ubuntu-v14-latin-ext_latin_cyrillic-ext_cyrillic-regular.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "ubuntu";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/ubuntu/ubuntu-v14-latin-ext_latin_cyrillic-ext_cyrillic-500.woff2") format("woff2"), url("../fonts/ubuntu/ubuntu-v14-latin-ext_latin_cyrillic-ext_cyrillic-500.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "robotofont";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/roboto/roboto-v20-latin-ext_latin_cyrillic-regular.woff2") format("woff2"), url("../fonts/roboto/roboto-v20-latin-ext_latin_cyrillic-regular.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "lg";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/lg.woff") format("woff");
  font-display: swap;
}

/* end fonts */
/* begin wrapper */
.wrapper {
  width: 1230px;
  padding: 0 30px;
  margin: 0 auto;
}

.wrapper--mini {
  width: 1000px;
}

@media (max-width: 1279.98px) {
  .wrapper--mini {
    width: 972px;
    padding: 0 20px;
  }
}

@media (max-width: 991.98px) {
  .wrapper--mini {
    width: 748px;
  }
}

@media (max-width: 767.98px) {
  .wrapper--mini {
    width: 556px;
  }
}

@media (max-width: 575.98px) {
  .wrapper--mini {
    width: 100%;
  }
}

@media (max-width: 1279.98px) {
  .wrapper {
    width: 972px;
    padding: 0 20px;
  }
}

@media (max-width: 991.98px) {
  .wrapper {
    width: 748px;
  }
}

@media (max-width: 767.98px) {
  .wrapper {
    width: 556px;
  }
}

@media (max-width: 575.98px) {
  .wrapper {
    width: 100%;
  }
}

/* end wrapper */
/* begin title */
.title {
  font-weight: 400;
  color: #000000;
}

.title--reviews .title__h1 {
  margin-bottom: 30px;
  font-weight: 400;
  color: #000000;
  text-align: center;
}

.title--h1, .title__h1 {
  font-size: 30px;
}

@media (max-width: 575.98px) {
  .title--h1, .title__h1 {
    font-size: 22px;
  }
}

.title--h2, .title__h2 {
  font-size: 22px;
}

.title--h4, .title__h4 {
  font-size: 16px;
}

.title--h5, .title__h5 {
  font-size: 14px;
}

.title--h6, .title__h6 {
  font-size: 13px;
}

/* end title */
/* begin button */
.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  padding: 0 15px;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
  border: 0;
  border-radius: 5px;
  outline: 0;
  transition: 0.3s background-color, 0.3s color;
}

.button--orange {
  color: #ffffff;
  background-color: #fe900b;
}

.button--orange:hover {
  background-color: #fe971a;
}

@media (max-width: 1279.98px) {
  .button--orange:hover {
    background-color: #fe900b;
  }
}

.button--botticelli {
  color: #555555;
  background-color: #d7dcea;
}

.button--botticelli:hover {
  background-color: #e1e5ef;
}

@media (max-width: 1279.98px) {
  .button--botticelli:hover {
    background-color: #d7dcea;
  }
}

.button--red {
  color: #ffffff;
  background-color: #ff5656;
}

.button--red:hover {
  background-color: #ff7070;
}

@media (max-width: 1279.98px) {
  .button--red:hover {
    background-color: #ff5656;
  }
}

.button--orange-opacity {
  color: #fe900b;
  background-color: rgba(254, 144, 11, 0.2);
}

.button--orange-opacity:hover {
  background-color: rgba(239, 131, 1, 0.2);
}

@media (max-width: 1279.98px) {
  .button--orange-opacity:hover {
    background-color: rgba(254, 144, 11, 0.2);
  }
}

/* end button */
/* begin search */
.search__form {
  display: flex;
  width: 552px;
  height: 52px;
  border: 2px solid #cccccc;
  border-radius: 20px;
}

.search__input {
  width: 100%;
  padding: 0 0 0 18px;
  font-size: 16px;
  font-weight: 300;
  color: #4a4a4a;
  border: 0;
  border-radius: 20px 0 0 20px;
  outline: 0;
}

.search__button {
  flex-shrink: 0;
  height: 100%;
  padding: 0 17px;
  background: transparent;
  border: 0;
  border-radius: 0 20px 20px 0;
  outline: 0;
}

.search__button-icon {
  width: 24px;
  height: 24px;
  fill: #555555;
}

/* end search */
/* begin slider */
.slider--main-slider {
  width: 100%;
  padding-bottom: 30px;
}

.slider--main-slider .slider__img {
  width: 100%;
}

.slider--main-slider .swiper-pagination {
  position: static;
  margin-top: 20px;
}

.slider--product-slider, .slider--news-slider, .slider--product-slider-mini {
  padding: 0 30px 45px;
  margin: 0 -30px;
}

@media (max-width: 1279.98px) {
  .slider--product-slider, .slider--news-slider, .slider--product-slider-mini {
    padding: 0 20px 45px;
    margin: 0 -20px;
  }
}

@media (max-width: 991.98px) {
  .slider--product-slider, .slider--news-slider, .slider--product-slider-mini {
    padding: 0 0 45px;
    margin: 0;
  }
}

@media (max-width: 991.98px) {
  .slider--product-slider .swiper-slide, .slider--product-slider-mini .swiper-slide {
    width: 270px !important;
  }
}

.slider__title {
  margin-bottom: 35px;
}

.slider .swiper-pagination {
  bottom: -3px;
}

.slider .swiper-pagination-bullet {
  width: 25px;
  height: 10px;
  background-color: #ffe3c2;
  border-radius: 8px;
  opacity: 1;
  transition: 0.3s;
}

.slider .swiper-pagination-bullet:hover {
  background-color: #fe900b;
}

.slider .swiper-pagination-bullet-active {
  background-color: #fe900b;
}

.slider > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 10px 0 0;
}

.slider > .swiper-pagination-bullets .swiper-pagination-bullet:last-child {
  margin-right: 0;
}

/* end slider */
/* begin product */
.product {
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  padding: 40px 33px 33px;
  overflow: hidden;
  border: 2px solid #f2f6fb;
  border-radius: 20px;
  transition: 0.3s box-shadow;
}

@media (max-width: 1279.98px) {
  .product {
    transition: 0s;
  }
}

.product:hover {
  box-shadow: 0 2px 5px 0 rgba(155, 155, 155, 0.5);
}

@media (max-width: 1279.98px) {
  .product:hover {
    box-shadow: none;
  }
}

.product--quick-order {
  padding: 0;
  border: 0;
  border-radius: 0;
}

.product--quick-order:hover {
  box-shadow: none;
}

.product--quick-order .product__img-link {
  padding: 0 37px;
}

@media (max-width: 767.98px) {
  .product--quick-order .product__img-link {
    padding: 0 17px;
  }
}

.product--quick-order .product__img {
  height: 225px;
}

.product--quick-order .product__title {
  height: auto;
  margin: 49px 0 30px;
}

@media (max-width: 767.98px) {
  .product--quick-order .product__title {
    margin: 48px 0 42px;
    line-height: 23px;
  }
}

.product--quick-order .product__price {
  padding: 0 0 22px;
}

.product--basket {
  flex-direction: row;
  padding: 25px 0 24px 0;
  margin-bottom: 20px;
  border: 0;
  border-bottom: 1px solid #f3f6fb;
  border-radius: 0;
}

@media (max-width: 767.98px) {
  .product--basket {
    flex-wrap: wrap;
    padding: 0 0 22px;
    margin: 0;
  }
}

.product--basket:hover {
  box-shadow: none;
}

.product--basket .product__img-link {
  margin-right: 30px;
}

@media (max-width: 767.98px) {
  .product--basket .product__img-link {
    margin-right: 15px;
    margin-bottom: 34px;
  }
}

.product--basket .product__img {
  width: 100px;
  height: 100px;
}

.product--basket .product__title {
  width: 270px;
  margin: 10px 30px 0 0;
}

@media (max-width: 767.98px) {
  .product--basket .product__title {
    width: calc(100% - 115px);
    margin: 9px 0 34px 0;
  }
}

.product--basket .product__footer {
  align-items: flex-start;
  padding-top: 30px;
  margin-left: auto;
}

@media (max-width: 767.98px) {
  .product--basket .product__footer {
    padding: 0;
    margin: 0;
  }
}

.product--basket .product__price {
  width: 125px;
  padding-bottom: 22px;
  margin: 0;
}

@media (max-width: 767.98px) {
  .product--basket .product__price {
    width: auto;
    padding: 0;
    margin-top: 8px;
  }
}

@media (max-width: 767.98px) {
  .product--basket .product__price-current {
    font-size: 22px;
  }
}

@media (max-width: 767.98px) {
  .product--basket .product__price-old {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .product--basket .product__price-profit {
    display: none;
  }
}

.product__badges {
  position: absolute;
  top: 12px;
  left: 18px;
}

.product__compare {
  position: absolute;
  top: 18px;
  right: 18px;
}

.product__compare-input {
  display: none;
}

.product__compare-input:checked + .product__compare-icon {
  fill: #fe900b;
}

.product__compare-icon {
  width: 15px;
  height: 15px;
  cursor: pointer;
  transition: 0.3s;
  fill: #aaaaaa;
}

.product__img {
  object-fit: contain;
  font-family: "object-fit: contain; object-position: center";
  object-position: center;
  width: 100%;
  height: 184px;
}

.product__title {
  flex-grow: 1;
  margin: 18px 0 12px;
  line-height: 20px;
  transition: 0.3s color;
}

.product__title:hover {
  color: #fe900b;
}

@media (max-width: 1279.98px) {
  .product__title:hover {
    color: #000000;
  }
}

.product__footer {
  display: flex;
}

.product__price {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  margin: 8px 15px 0 0;
}

.product__price-current {
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  color: #000000;
}

.product__price-old {
  position: absolute;
  top: -20px;
  left: 0;
  font-size: 13px;
  font-weight: 300;
  line-height: 20px;
  color: #979797;
  text-decoration: line-through;
}

.product__price-profit {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 17px;
  padding: 0 9px;
  font-size: 12px;
  font-weight: 300;
  line-height: 17px;
  color: #979797;
  background-color: #fff5eb;
  border-radius: 7.5px;
}

.product__button {
  display: inline-flex;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
  width: 50px;
  height: 50px;
  cursor: pointer;
  background: transparent;
  border: 1px solid #e6e6e6;
  border-radius: 25px;
  outline: 0;
  transition: 0.3s;
}

.product__button--checked {
  border-color: #fe900b;
}

.product__button--checked .product__button-icon {
  width: 15px;
  height: 15px;
  fill: #fe900b;
}

.product__button:hover {
  border-color: #fe900b;
}

@media (max-width: 1279.98px) {
  .product__button:hover {
    border-color: #e6e6e6;
  }
}

.product__button:hover .product__button-icon {
  fill: #fe900b;
}

@media (max-width: 1279.98px) {
  .product__button:hover .product__button-icon {
    fill: #555555;
  }
}

.product__button-icon {
  width: 25px;
  height: 25px;
  transition: 0.3s;
  fill: #555555;
}

.product__counter {
  display: inline-flex;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
  margin-right: 20px;
}

@media (max-width: 767.98px) {
  .product__counter {
    margin-right: 30px;
  }
}

.product__counter-button {
  width: 30px;
  height: 30px;
  background-color: rgba(254, 144, 11, 0.05);
  border: 0;
  border-radius: 5px;
  outline: 0;
}

.product__counter-input {
  width: 28px;
  font-size: 14px;
  color: #000000;
  text-align: center;
  border: 0;
  outline: 0;
}

.product__delete {
  position: absolute;
  top: 10px;
  right: 10px;
  display: inline-flex;
  width: 16px;
  height: 16px;
  background-color: transparent;
  border: 0;
  outline: 0;
}

@media (max-width: 767.98px) {
  .product__delete {
    top: 87px;
    right: 0;
  }
}

.product__delete-icon {
  width: 16px;
  height: 16px;
  fill: #555555;
}

@media (max-width: 767.98px) {
  .product__delete-icon {
    width: 11px;
    height: 11px;
  }
}

/* end product */
/* begin badge */
.badge {
  display: inline-flex;
  align-items: center;
  height: 26px;
  padding: 0 10px;
  font-size: 14px;
  font-weight: 300;
  color: #ffffff;
  background-color: #456990;
  border-radius: 9px;
}

.badge--blue {
  background-color: #456990;
}

.badge--black {
  background-color: #4a4a4a;
}

.badge--orange {
  background-color: #fe900b;
}

.badge--red {
  background-color: #ff5656;
}

.badge--green {
  background-color: #00ad4d;
}

/* end badge */
/* begin section */
@media (max-width: 575.98px) {
  .section--main-slider .section__wrapper {
    padding: 0;
  }
}

@media (max-width: 575.98px) {
  .section--product-slider .section__wrapper {
    padding-right: 0;
  }
}

.section--devices-mobile {
  display: none;
}

@media (max-width: 767.98px) {
  .section--devices-mobile {
    display: block;
  }
}

/* end section */
/* begin mmenu */
.mmenu {
  position: fixed;
  top: 0;
  left: -310px;
  z-index: 10;
  width: 310px;
  height: 100%;
  overflow: hidden;
  box-shadow: 0 0 7px 0 rgba(74, 74, 74, 0.1);
}

.mmenu__frame {
  position: absolute;
  top: 0;
  left: 0;
  width: 310px;
  height: 100%;
  overflow: hidden;
  background-color: #ffffff;
  transition: 0.5s;
}

.mmenu__frame--scroll {
  overflow-y: auto;
}

.mmenu__frame-header {
  display: flex;
  align-items: center;
  height: 66px;
  padding: 0 20px;
  border-bottom: 1px solid #f4f4f4;
}

.mmenu__frame-header-icon {
  width: 26px;
  height: 26px;
  margin-right: 21px;
  fill: #555555;
}

.mmenu__frame-header-text {
  font-size: 16px;
  font-weight: 300;
  color: #4a4a4a;
}

.mmenu__frame-list {
  display: flex;
  flex-direction: column;
}

.mmenu__frame-list--account > .mmenu__frame-item > .mmenu__frame-link {
  height: 66px;
  padding: 0 20px 0 0;
}

.mmenu__frame-list--account > .mmenu__frame-item > .mmenu__frame-link > .mmenu__frame-link-icon-box > .mmenu__frame-link-icon {
  width: 26px;
  height: 26px;
  fill: #555555;
}

.mmenu__frame-list--account > .mmenu__frame-item > .mmenu__frame-link > .mmenu__frame-link-text {
  font-size: 16px;
}

.mmenu__frame-list .mmenu__frame {
  left: 310px;
}

.mmenu__frame-list .mmenu__frame--current {
  left: 0;
}

.mmenu__frame-item--separator {
  cursor: default;
  background-color: #fef3e6;
}

.mmenu__frame-item--separator .mmenu__frame-link {
  border: 0;
}

.mmenu__frame-item--separator .mmenu__frame-link-text {
  color: #fe900b;
}

.mmenu__frame-item--separator + .mmenu__frame-item .mmenu__frame-link {
  border: 0;
}

.mmenu__frame-item:last-child .mmenu__frame-link {
  border-bottom: 1px solid #f4f4f4;
}

.mmenu__frame-link, .mmenu__back {
  display: flex;
  align-items: center;
  height: 60px;
  padding-left: 20px;
  border-top: 1px solid #f4f4f4;
}

.mmenu__frame-link-text, .mmenu__back-text {
  width: 100%;
  font-size: 14px;
  font-weight: 300;
  color: #4a4a4a;
}

.mmenu__frame-link-icon-box, .mmenu__back-icon-box {
  display: inline-flex;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
  width: 52px;
  height: 100%;
}

.mmenu__frame-link-icon {
  width: 12px;
  height: 12px;
  fill: #000000;
}

.mmenu__phone {
  display: flex;
  margin: 50px 20px 0 20px;
  font-size: 16px;
  color: #4a4a4a;
}

.mmenu__address {
  display: flex;
  max-width: 195px;
  margin: 50px 0 0 20px;
  font-size: 14px;
  line-height: 20px;
  color: #4a4a4a;
}

.mmenu__feedback {
  display: inline-flex;
  margin: 20px 0 50px 20px;
  font-size: 14px;
  color: #fe900b;
}

.mmenu__back {
  padding: 0 20px 0 0;
}

.mmenu__back-icon {
  width: 12px;
  height: 12px;
  transform: rotate(180deg);
  fill: #000000;
}

.mmenu__back-text {
  font-size: 16px;
}

/* end mmenu */
/* begin push */
.push {
  transition: 0.5s;
}

.push--open {
  left: 0;
}

@media (max-width: 1279.98px) {
  .push--open {
    left: 0 !important;
  }
}

.push-button {
  position: relative;
}

/* end push */
/* begin radio */
.radio {
  display: inline-flex;
}

.radio__input {
  display: none;
}

.radio__input:checked + .radio__body .radio__el {
  border: 8px solid #fe900b;
}

.radio__body {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.radio__body:hover .radio__el {
  border-color: #fe900b;
}

.radio__el {
  flex-shrink: 0;
  width: 25px;
  height: 25px;
  background-color: #ffffff;
  border: 2px solid #cccccc;
  border-radius: 50%;
  transition: 0.3s;
}

.radio__text {
  margin-left: 20px;
  font-size: 16px;
  color: #4a4a4a;
}

/* end radio */
/* begin form */
.form {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.form--quick-order {
  margin: 53px 0 0;
}

.form--support-popup {
  padding: 30px 20px;
  background-color: #f2f3f8;
}

.form--support-popup .form__title {
  width: 100%;
  font-weight: 700;
  line-height: 24px;
  color: #252525;
  text-align: center;
}

.form--support-popup .form__inner-title {
  margin-bottom: 20px;
  font-size: 15px;
  line-height: 24px;
  color: #252525;
  text-align: center;
}

.form--support-popup .form__block {
  width: calc(100% + 40px);
  padding: 30px 20px;
  margin: 0 -20px;
  border-top: 1px solid #e4e8f1;
}

.form--support-popup .form__block:last-child {
  padding-bottom: 0;
}

.form--support-popup .form__input, .form--support-popup .form__textarea {
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  color: #4a4a4a;
  border: 1px solid #cccfd8;
}

.form--support-popup .form__input::placeholder, .form--support-popup .form__textarea::placeholder {
  color: #4a4a4a;
}

.form--support-popup .form__input:last-child, .form--support-popup .form__textarea:last-child {
  margin-bottom: 0;
}

.form--support-popup .form__button {
  margin-top: 20px;
}

.form--support-popup .form__disclaimer {
  margin-top: 20px;
  font-size: 13px;
  font-weight: 300;
  line-height: 20px;
  color: #4a4a4a;
  text-align: center;
}

.form--support-popup .form__row {
  margin-bottom: 10px;
}

.form--support-popup .ss-main .ss-content {
  border: 1px solid #cccfd8;
}

.form--support-popup .ss-main .ss-option {
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  color: #4a4a4a;
}

.form--support-popup .ss-main .ss-single-selected {
  border: 1px solid #cccfd8;
}

.form--support-popup .ss-main .ss-single-selected .placeholder {
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  color: #4a4a4a;
}

.form--support-popup .ss-main .ss-single-selected .placeholder .ss-disabled {
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  color: #4a4a4a;
}

.form__title {
  margin-bottom: 23px;
}

.form__row {
  width: 100%;
  margin-bottom: 23px;
}

.form__row:last-child {
  margin-bottom: 0;
}

.form__row--login-code-phone {
  margin-bottom: 42px;
}

.form__row--2col {
  display: flex;
}

@media (max-width: 575.98px) {
  .form__row--2col {
    flex-direction: column;
  }
}

.form__row--2col .form__col {
  width: calc(50% - 10px);
}

@media (max-width: 575.98px) {
  .form__row--2col .form__col {
    width: 100%;
    margin: 0 0 23px;
  }
}

.form__row--2col .form__col:first-child {
  margin-right: 10px;
}

@media (max-width: 575.98px) {
  .form__row--2col .form__col:first-child {
    margin-right: 0;
  }
}

.form__row--2col .form__col:last-child {
  margin-left: 10px;
}

@media (max-width: 575.98px) {
  .form__row--2col .form__col:last-child {
    margin: 0;
  }
}

.form__col {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.form__col--rating {
  align-items: center;
  flex-direction: row;
  margin-top: 9px;
  margin-bottom: 30px;
}

@media (max-width: 575.98px) {
  .form__col--rating {
    margin-top: 42px;
  }
}

.form__col--rating .form__label {
  margin: 0 42px 0 0;
}

.form__label {
  margin-bottom: 12px;
  font-size: 14px;
  color: #000000;
}

.form__label--required {
  position: relative;
}

.form__label--required::after {
  position: absolute;
  top: -3px;
  right: -14px;
  font-size: 16px;
  color: #ff5656;
  content: "*";
}

.form__input {
  width: 100%;
  height: 60px;
  padding: 0 18px;
  font-size: 16px;
  color: #555555;
  background-color: #ffffff;
  border: 2px solid #cccccc;
  border-radius: 5px;
  outline: none;
}

.form__textarea {
  width: 100%;
  height: 120px;
  padding: 18px;
  background-color: #ffffff;
  border: 2px solid #cccccc;
  border-radius: 5px;
  outline: none;
  resize: none;
}

.form__button {
  width: 100%;
}

.form__delivery-input-box {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 60px;
  padding-left: 12px;
  background-color: #ffffff;
  border: solid 2px #cccccc;
  border-radius: 5px;
}

.form__delivery-input-icon {
  flex-shrink: 0;
  width: 30px;
  height: 30px;
  fill: #cccccc;
}

.form__delivery-input {
  width: 100%;
  height: 100%;
  padding-left: 20px;
  font-size: 16px;
  color: #000000;
  border: 0;
  outline: 0;
}

.form__delivery-input-clear-box {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0 18px;
  background: transparent;
  border: 0;
  outline: 0;
}

.form__delivery-input-clear-box:hover .form__delivery-input-clear {
  fill: #000000;
}

@media (max-width: 1279.98px) {
  .form__delivery-input-clear-box:hover .form__delivery-input-clear {
    fill: #555555;
  }
}

.form__delivery-input-clear {
  flex-shrink: 0;
  width: 10px;
  height: 10px;
  transition: 0.3s;
  fill: #555555;
}

.form__hint {
  margin: 5px 5px 0;
  font-size: 14px;
  color: #ff5656;
}

.form__input-box {
  position: relative;
  width: 100%;
}

.form__input-drop {
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  z-index: 10;
  width: 100%;
  max-height: 148px;
  padding: 30px 20px;
  overflow-y: auto;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(74, 74, 74, 0.25);
}

.form__input-drop-list {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.form__input-drop-item {
  margin-bottom: 20px;
  font-size: 14px;
  color: #000000;
  cursor: pointer;
  transition: 0.3s;
}

.form__input-drop-item:last-child {
  margin-bottom: 0;
}

.form__input-drop-item:hover {
  color: #fe900b;
}

@media (max-width: 1279.98px) {
  .form__input-drop-item:hover {
    color: #000000;
  }
}

.form__erorr {
  border-color: #ff5656;
}

/* end form */
/* begin checkbox */
.checkbox--red .checkbox__el {
  border-color: #d63031;
}

.checkbox--red .checkbox__el-icon {
  fill: #d63031;
}

.checkbox--quick-order {
  margin-bottom: 23px;
}

.checkbox--quick-order .checkbox__text {
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  color: #999999;
}

.checkbox--quick-order .checkbox__el {
  width: 24px;
  height: 24px;
  margin: 4px 0 0;
  border-radius: 5px;
}

.checkbox__input {
  display: none;
}

.checkbox__input:checked + .checkbox__body .checkbox__el-icon {
  opacity: 1;
}

.checkbox__input:disabled + .checkbox__body {
  cursor: default;
  opacity: 0.5;
}

.checkbox__body {
  display: inline-flex;
  align-items: flex-start;
  cursor: pointer;
}

.checkbox__el {
  display: inline-flex;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
  width: 22px;
  height: 22px;
  border: solid 2px #cccccc;
  border-radius: 2px;
}

.checkbox__el-icon {
  width: 10px;
  height: 10px;
  opacity: 0;
  transition: 0.3s;
  fill: #cccccc;
}

.checkbox__text {
  margin-left: 9px;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: #4a4a4a;
}

.checkbox__link {
  color: #d63031;
  transition: 0.3s;
}

.checkbox__link:hover {
  opacity: 0.75;
}

@media (max-width: 1279.98px) {
  .checkbox__link:hover {
    opacity: 1;
  }
}

/* end checkbox */
/* begin breadcrumbs */
.breadcrumbs {
  margin: 48px 0 50px;
}

@media (max-width: 575.98px) {
  .breadcrumbs {
    margin-top: 30px;
  }
}

.breadcrumbs__list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.breadcrumbs__item {
  position: relative;
  margin-right: 24px;
}

@media (max-width: 767.98px) {
  .breadcrumbs__item {
    display: none;
  }
  .breadcrumbs__item:nth-last-child(2) {
    display: inline-flex;
    align-items: center;
    margin: 0;
  }
  .breadcrumbs__item:nth-last-child(2)::before {
    position: relative;
    width: 11px;
    height: 11px;
    margin-right: 10px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' fill='%23f68e5f'%3E%3Cpath d='M492 236H68.442l70.164-69.824c7.829-7.792 7.859-20.455.067-28.284-7.792-7.83-20.456-7.859-28.285-.068l-104.504 104c-.007.006-.012.013-.018.019-7.809 7.792-7.834 20.496-.002 28.314.007.006.012.013.018.019l104.504 104c7.828 7.79 20.492 7.763 28.285-.068 7.792-7.829 7.762-20.492-.067-28.284L68.442 276H492c11.046 0 20-8.954 20-20s-8.954-20-20-20z'/%3E%3C/svg%3E");
    background-size: contain;
    content: "";
  }
  .breadcrumbs__item:nth-last-child(2)::after {
    content: none;
  }
}

.breadcrumbs__item::after {
  position: absolute;
  top: 3px;
  right: -14px;
  font-size: 14px;
  font-weight: 300;
  color: #000000;
  content: "-";
}

.breadcrumbs__item:last-child {
  margin-right: 0;
}

.breadcrumbs__item--current::after {
  content: none;
}

.breadcrumbs__item--current .breadcrumbs__link {
  color: #9b9b9b;
  pointer-events: none;
}

.breadcrumbs__link {
  font-size: 14px;
  font-weight: 300;
  color: #000000;
  transition: 0.3s;
}

.breadcrumbs__link:hover {
  color: #fe900b;
}

@media (max-width: 1279.98px) {
  .breadcrumbs__link:hover {
    color: #000000;
  }
}

/* end breadcrumbs */
/* begin rating */
.rating {
  display: flex;
}

.rating--static .rating__item {
  pointer-events: none;
}

.rating__item {
  display: inline-flex;
  margin-right: 3px;
  cursor: pointer;
}

.rating__item:last-of-type {
  margin-right: 0;
}

.rating__item--active .rating__icon {
  fill: #fe900b;
}

.rating__input {
  display: none;
}

.rating__icon {
  width: 15px;
  height: 15px;
  transition: 0.3s;
  fill: #cccccc;
}

.rating__text {
  margin-left: 13px;
  font-size: 16px;
  line-height: 16px;
  color: #4a4a4a;
}

/* end rating */
/* begin leader-list */
.leader-list {
  display: flex;
  flex-direction: column;
}

.leader-list__row {
  position: relative;
  display: flex;
  align-items: flex-end;
  margin: 0 0 10px;
}

.leader-list__row:last-child {
  margin-bottom: 0;
}

.leader-list__row::after {
  position: absolute;
  bottom: 7px;
  left: 0;
  width: 100%;
  border-bottom: 1px dotted #e6e6e6;
  content: "";
}

.leader-list__col {
  position: relative;
  z-index: 1;
  padding: 5px 5px 5px 0;
  font-size: 14px;
  font-weight: 300;
  color: #555555;
  background-color: #ffffff;
}

.leader-list__col:first-child {
  display: inline-flex;
}

.leader-list__col:last-child {
  flex-shrink: 0;
  width: 51%;
  padding-left: 5px;
  margin: 0 0 0 auto;
  color: #000000;
}

/* end leader-list */
/* begin support-nav */
.support-nav {
  margin: 0 0 50px;
}

@media (max-width: 767.98px) {
  .support-nav--reviews {
    display: none;
  }
}

.support-nav--reviews .support-nav__slider {
  position: relative;
  padding: 0 20px 2px;
}

.support-nav--reviews .support-nav__slider::after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: rgba(74, 74, 74, 0.1);
  content: "";
}

.support-nav--reviews .support-nav__list {
  justify-content: flex-start;
  border: 0;
}

.support-nav--reviews .support-nav__item {
  position: relative;
  width: auto;
  margin: 0 30px 0 0;
}

.support-nav__list {
  display: flex;
  justify-content: center;
  border-bottom: 2px solid rgba(74, 74, 74, 0.1);
}

@media (max-width: 767.98px) {
  .support-nav__list {
    flex-direction: column;
    border: 0;
  }
}

@media (max-width: 575.98px) {
  .support-nav__list {
    margin: 0 -20px;
  }
}

.support-nav__item {
  margin-right: 50px;
}

@media (max-width: 991.98px) {
  .support-nav__item {
    margin-right: 19px;
  }
}

@media (max-width: 767.98px) {
  .support-nav__item {
    margin: 0;
  }
}

.support-nav__item:last-child {
  margin-right: 0;
}

@media (max-width: 767.98px) {
  .support-nav__item:last-child .support-nav__link {
    border: 0;
  }
}

.support-nav__item--active .support-nav__link {
  pointer-events: none;
}

.support-nav__item--active .support-nav__link::after {
  opacity: 1;
}

@media (max-width: 767.98px) {
  .support-nav__item--active .support-nav__icon {
    display: none;
  }
}

.support-nav__link {
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 20px 0;
}

@media (max-width: 767.98px) {
  .support-nav__link {
    width: 100%;
    padding: 21.5px 20px;
    border-bottom: 1px solid #f4f4f4;
  }
}

.support-nav__link::after {
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #4a4a4a;
  content: "";
  opacity: 0;
  transition: 0.3s opacity;
}

@media (max-width: 767.98px) {
  .support-nav__link::after {
    top: 50%;
    width: 2px;
    height: 30px;
    transform: translateY(-50%);
  }
}

.support-nav__link:hover::after {
  opacity: 1;
}

@media (max-width: 1279.98px) {
  .support-nav__link:hover::after {
    opacity: 0;
  }
}

.support-nav__text {
  font-size: 14px;
  font-weight: 400;
  color: #4a4a4a;
}

.support-nav__icon {
  display: none;
  width: 12px;
  height: 12px;
  margin-left: auto;
  fill: #000000;
}

@media (max-width: 767.98px) {
  .support-nav__icon {
    display: inline-flex;
  }
}

.support-nav__button {
  position: relative;
  left: 50%;
  margin: 0 0 30px;
  transform: translateX(-50%);
}

/* end support-nav */
/* begin text-block */
.text-block--shipping-payment .wrapper {
  max-width: 585px;
}

.text-block--shipping-payment img {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.text-block--support .wrapper {
  max-width: 720px;
}

.text-block--qa .wrapper {
  max-width: 860px;
}

.text-block--review .wrapper {
  max-width: 680px;
}

.text-block--review .reviews {
  display: flex;
  justify-content: center;
}

.text-block--review .reviews__header {
  margin-bottom: 50px;
}

.text-block--review h1.title {
  margin-bottom: 30px;
  text-align: center;
}

.text-block p {
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  color: #4a4a4a;
}

.text-block p:last-child {
  margin-bottom: 0;
}

@media (max-width: 991.98px) {
  .text-block p {
    font-size: 14px;
    line-height: 20px;
  }
}

.text-block h1 {
  margin: 50px 0;
  text-align: center;
}

@media (max-width: 575.98px) {
  .text-block h1 {
    text-align: left;
  }
}

.text-block h1:first-child {
  margin-top: 0;
}

.text-block h3 {
  margin: 30px 0;
  font-size: 20px;
  font-weight: 400;
  color: #000000;
}

.text-block h3:first-child {
  margin-top: 0;
}

.text-block h4 {
  margin: 20px 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  color: #000000;
}

.text-block h4:first-child {
  margin-top: 0;
}

.text-block h5 {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #4a4a4a;
}

.text-block img {
  display: block;
  width: 100%;
  height: auto;
  max-width: 580px;
  margin: 30px auto;
}

.text-block a {
  color: #fe900b;
  transition: 0.3s;
}

.text-block a:hover {
  opacity: 0.75;
}

@media (max-width: 1279.98px) {
  .text-block a:hover {
    opacity: 1;
  }
}

.text-block ul {
  display: flex;
  flex-direction: column;
  margin: 20px 0;
}

.text-block li {
  position: relative;
  padding-left: 15px;
  font-size: 14px;
  font-weight: 300;
  line-height: 30px;
  color: #4a4a4a;
}

.text-block li::before {
  position: absolute;
  top: 14px;
  left: 0;
  width: 5px;
  height: 5px;
  background-color: #fe900b;
  border-radius: 50%;
  content: "";
}

.text-block hr {
  width: 100%;
  height: 1px;
  margin: 50px 0;
  background-color: #fef3e6;
  border: 0;
}

.text-block__subtitle {
  margin: 20px 0 50px;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.43;
  color: rgba(74, 74, 74, 0.5);
  text-align: center;
}

/* end text-block */
/* begin select */
.select .ss-single-selected {
  height: 60px;
  padding: 0 18px;
  font-size: 14px;
  color: #000000;
  background-color: #ffffff;
  border: 2px solid #cccccc;
  border-radius: 5px;
}

.select .ss-content {
  margin: -2px 0 0;
  border: 2px solid #cccccc;
  border-radius: 5px;
}

.select .ss-content .ss-list .ss-option:hover {
  color: #000000;
  background-color: rgba(254, 144, 11, 0.1);
}

.select .ss-content .ss-list .ss-option.ss-highlighted:hover {
  color: #000000;
  background-color: rgba(254, 144, 11, 0.1);
}

.select .ss-content .ss-list .ss-option.ss-disabled:hover {
  color: #dedede;
  cursor: not-allowed;
  background-color: #ffffff;
}

.select .ss-option {
  display: flex;
  align-items: center;
  height: 60px;
  padding: 0 18px;
  font-size: 14px;
  color: #000000;
}

/* end select */
/* begin page */
body,
.page {
  overflow-y: scroll;
  font-family: "ubuntu", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #000000;
}

.page {
  position: relative;
  left: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  transition: 0.5s left;
}

@media (max-width: 1279.98px) {
  .page {
    padding-top: 65px;
  }
}

.page--push-open {
  left: 310px;
}

.page__section-margin {
  margin-top: 100px;
}

@media (max-width: 991.98px) {
  .page__section-margin {
    margin-top: 50px;
  }
}

.page__section-margin--mini {
  margin-top: 52px;
}

.page__content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

/* end page */
/* begin header */
@media (max-width: 1279.98px) {
  .header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 8;
    width: 100%;
    background-color: #ffffff;
    box-shadow: 0 0 7px 0 rgba(74, 74, 74, 0.1);
    transition: 0.5s left;
  }
}

@media (max-width: 1279.98px) {
  .header--push-open {
    left: 310px;
  }
}

@media (max-width: 1279.98px) {
  .header--search-open {
    z-index: 99;
  }
}

.header--mini {
  padding: 32px 0;
}

@media (max-width: 1279.98px) {
  .header--mini {
    padding: 0;
  }
}

.header--mini .header__buttons {
  display: none;
}

@media (max-width: 1279.98px) {
  .header--mini .header__buttons {
    display: flex;
  }
}

.header--mini .header__body {
  margin: 0;
}

@media (max-width: 1279.98px) {
  .header--mini .header__mini-title {
    display: none;
  }
}

@media (max-width: 1279.98px) {
  .header--mini .header__mini-phone {
    display: none;
  }
}

.header__top {
  display: flex;
  padding: 13px 0;
  margin: 0 0 23px;
}

@media (max-width: 1279.98px) {
  .header__top {
    display: none;
  }
}

.header__top-left-side {
  display: flex;
  align-items: center;
}

.header__city {
  margin-right: 50px;
}

.header__city-button {
  position: relative;
  top: 2px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.header__city-button:hover .header__city-title {
  color: #fe900b;
}

.header__city-button:hover .header__city-icon {
  fill: #fe900b;
}

.header__city-icon {
  width: 22px;
  height: 22px;
  margin-right: 7px;
  transition: 0.3s;
  fill: #555555;
}

.header__city-title {
  font-size: 16px;
  font-weight: 400;
  color: #555555;
  transition: 0.3s;
}

.header__top-nav {
  display: flex;
}

.header__top-nav-link {
  margin-right: 21px;
  font-size: 16px;
  font-weight: 300;
  color: #555555;
  transition: 0.3s;
}

.header__top-nav-link:last-child {
  margin-right: 0;
}

.header__top-nav-link:hover {
  color: #fe900b;
}

.header__top-right-side {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.header__top-phone {
  margin-left: 53px;
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  transition: 0.3s;
}

.header__top-phone:hover {
  color: #fe900b;
}

.header__body {
  display: flex;
  align-items: center;
  margin: 0 0 34px;
}

@media (max-width: 1279.98px) {
  .header__body {
    padding: 5.5px 0;
    margin: 0;
  }
}

.header__logo {
  display: inline-flex;
  flex-shrink: 0;
}

@media (max-width: 1279.98px) {
  .header__logo {
    margin-left: 32px;
  }
}

.header__logo-img {
  width: 142px;
  height: 32px;
}

@media (max-width: 1279.98px) {
  .header__logo-img {
    width: 130px;
    height: 32px;
  }
}

.header__search {
  margin: 0 89px 0 90px;
}

@media (max-width: 1279.98px) {
  .header__search {
    display: none;
  }
}

.header__body-check-order {
  height: 40px;
  min-width: 152px;
  font-size: 14px;
  border-radius: 20px;
}

@media (max-width: 1279.98px) {
  .header__body-check-order {
    display: none;
  }
}

.header__buttons {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.header__buttons-item {
  display: inline-flex;
  margin-right: 36px;
  cursor: pointer;
}

.header__buttons-item:last-child {
  margin-right: 0;
}

@media (max-width: 1279.98px) {
  .header__buttons-item--account {
    display: none;
  }
}

@media (max-width: 1279.98px) {
  .header__buttons-item--logout {
    display: none;
  }
}

.header__buttons-item--search {
  display: none;
}

@media (max-width: 1279.98px) {
  .header__buttons-item--search {
    display: inline-flex;
  }
}

.header__buttons-item--search .header__buttons-item-link {
  position: relative;
}

.header__buttons-item--search .header__buttons-item-link::before {
  position: absolute;
  top: -12px;
  left: -12px;
  width: 39px;
  height: 39px;
  background-color: rgba(254, 144, 11, 0.1);
  border-radius: 50%;
  content: "";
}

.header__buttons-item--search .header__buttons-item-icon {
  width: 14px;
  height: 14px;
  fill: #fe900b;
}

.header__buttons-item-link {
  position: relative;
  display: inline-flex;
}

.header__buttons-item-icon {
  width: 26px;
  height: 26px;
  fill: #555555;
}

.header__buttons-item-num {
  position: absolute;
  top: -8px;
  right: -3px;
  z-index: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  font-size: 11px;
  color: #ffffff;
  background-color: #fe900b;
  border: 2px solid #ffffff;
  border-radius: 50%;
}

.header__nav {
  background-color: #f2f3f8;
}

@media (max-width: 1279.98px) {
  .header__nav {
    display: none;
  }
}

.header__nav-list {
  display: flex;
  height: 70px;
}

.header__nav-list-item {
  display: inline-flex;
  align-items: center;
  height: 100%;
  padding: 0 15px;
  font-size: 16px;
  color: #555555;
  transition: 0.3s;
}

.header__nav-list-item:hover {
  background-color: #e4e8f1;
}

.header__toggle-mobile-nav {
  display: none;
  padding: 20px;
}

@media (max-width: 1279.98px) {
  .header__toggle-mobile-nav {
    display: inline-flex;
  }
}

@media (max-width: 575.98px) {
  .header__toggle-mobile-nav {
    padding-left: 0;
  }
}

.header__toggle-mobile-nav-icon {
  width: 14px;
  height: 14px;
  fill: #fe900b;
}

.header__mini-title {
  margin-left: 31px;
  font-size: 32px;
}

.header__mini-phone {
  margin-left: auto;
  font-size: 20px;
  color: #000000;
  transition: 0.3s;
}

.header__mini-phone:hover {
  color: #fe900b;
}

@media (max-width: 1279.98px) {
  .header__mini-phone:hover {
    color: #000000;
  }
}

/* end header */
/* begin devices */
.devices__list {
  display: flex;
  flex-wrap: wrap;
  padding: 0 129px;
}

@media (max-width: 1279.98px) {
  .devices__list {
    padding: 0;
  }
}

.devices__item {
  display: flex;
  align-items: center;
  margin: 0 58px 30px 0;
}

@media (max-width: 991.98px) {
  .devices__item {
    width: 50%;
    padding-right: 58px;
    margin-right: 0;
  }
}

@media (max-width: 767.98px) {
  .devices__item {
    width: 100%;
    padding-right: 0;
  }
}

.devices__item:nth-child(3n) {
  margin-right: 0;
}

.devices__item:hover .devices__title {
  color: #fe900b;
}

@media (max-width: 1279.98px) {
  .devices__item:hover .devices__title {
    color: #000000;
  }
}

.devices__img {
  object-fit: contain;
  font-family: "object-fit: contain; object-position: center";
  object-position: center;
  width: 85px;
  height: 85px;
  margin-right: 20px;
}

.devices__title {
  width: 160px;
  transition: 0.3s;
}

@media (max-width: 991.98px) {
  .devices__title {
    width: 100%;
  }
}

/* end devices */
/* begin news */
.news__img {
  object-fit: cover;
  font-family: "object-fit: cover; object-position: center";
  object-position: center;
  width: 100%;
  height: 200px;
  border-radius: 20px;
  opacity: 0.69;
}

.news__title {
  display: flex;
  margin: 20px 0 10px;
  font-weight: 500;
  line-height: 28px;
  transition: 0.3s;
}

.news__title:hover {
  color: #fe900b;
}

@media (max-width: 1279.98px) {
  .news__title:hover {
    color: #000000;
  }
}

.news__text {
  font-size: 14px;
  font-weight: 300;
  line-height: 25px;
  color: #4a4a4a;
}

/* end news */
/* begin newsletter */
.newsletter {
  padding: 18px 0 16px;
  background-color: #e4e8f1;
}

@media (max-width: 991.98px) {
  .newsletter {
    display: none;
  }
}

.newsletter__wrapper {
  display: flex;
  align-items: center;
}

@media (max-width: 1279.98px) {
  .newsletter__wrapper {
    flex-wrap: wrap;
  }
}

.newsletter__logo {
  flex-shrink: 0;
  margin-right: 51px;
}

.newsletter__logo-img {
  width: 142px;
  height: 32px;
}

.newsletter__info {
  display: flex;
  flex-direction: column;
  max-width: 424px;
}

@media (max-width: 1279.98px) {
  .newsletter__info {
    margin-left: auto;
    text-align: right;
  }
}

.newsletter__info-title {
  margin-bottom: 6px;
  font-size: 22px;
  line-height: 20px;
  color: #000000;
}

.newsletter__info-text {
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  color: #4a4a4a;
}

.newsletter__form {
  position: relative;
  display: flex;
  flex-direction: row;
  margin-left: auto;
}

@media (max-width: 1279.98px) {
  .newsletter__form {
    margin-top: 18px;
  }
}

.newsletter__form .form__row {
  margin: 0;
  opacity: 1;
  transition: 0.3s;
  transition-delay: 0.3s;
}

.newsletter__form--success .form__row {
  opacity: 0;
  transition-delay: 0s;
}

.newsletter__form--success .newsletter__button {
  opacity: 0;
  transition-delay: 0s;
}

.newsletter__form--success .newsletter__success {
  opacity: 1;
  transition: 0.3s;
  transition-delay: 0.3s;
}

.newsletter__input {
  width: 360px;
  height: 60px;
  padding: 0 60px 0 30px;
  margin-right: -35px;
  background-color: #ffffff;
  border: 0;
  border-radius: 20px 0 0 20px;
  outline: none;
}

.newsletter__input::placeholder {
  color: #979797;
}

.newsletter__button {
  min-width: 165px;
  background-color: #d7dcea;
  border-radius: 20px;
  transition: 0.3s;
  transition-delay: 0.3s;
}

.newsletter__success {
  position: absolute;
  top: 50%;
  left: 0;
  font-size: 14px;
  font-weight: 300;
  color: #4a4a4a;
  opacity: 0;
  transform: translateY(-50%);
  transition: 0.3s;
}

@media (max-width: 1279.98px) {
  .newsletter__success {
    right: 0;
    left: auto;
    text-align: right;
  }
}

/* end newsletter */
/* begin footer */
.footer {
  background-color: #f2f3f8;
}

@media (max-width: 991.98px) {
  .footer--mini .footer__row--bottom {
    padding: 20px 0 30px;
  }
  .footer--mini .footer__col--mini-phone {
    order: -1;
    margin-bottom: 20px;
  }
  .footer--mini .footer__copyright {
    font-size: 14px;
    font-weight: 300;
    color: #9b9b9b;
    text-align: left;
  }
}

.footer__row {
  display: flex;
}

@media (max-width: 991.98px) {
  .footer__row {
    flex-direction: column;
  }
}

.footer__row--top {
  padding: 50px 0 40px;
  border-bottom: 1px solid #d7dcea;
}

@media (max-width: 991.98px) {
  .footer__row--top {
    padding: 0;
    border: 0;
  }
}

.footer__row--bottom {
  padding: 32px 0;
}

@media (max-width: 991.98px) {
  .footer__row--bottom {
    padding: 30px 0;
  }
}

.footer__col {
  display: flex;
  flex-direction: column;
  width: 182px;
  margin-right: 66px;
}

@media (max-width: 1279.98px) {
  .footer__col {
    width: 171px;
    margin-right: 20px;
  }
}

@media (max-width: 991.98px) {
  .footer__col {
    width: 100%;
    margin-right: 0;
  }
}

.footer__col:last-child {
  margin-right: 0;
}

.footer__col--bottom-links {
  flex-direction: row;
  width: auto;
  margin-left: auto;
}

@media (max-width: 1279.98px) {
  .footer__col--bottom-links {
    width: auto;
  }
}

@media (max-width: 991.98px) {
  .footer__col--bottom-links {
    display: none;
  }
}

.footer__col--copyright {
  width: auto;
  margin-right: 0;
}

@media (max-width: 1279.98px) {
  .footer__col--copyright {
    width: auto;
  }
}

.footer__col--mini-phone {
  width: auto;
  margin: 0 0 0 auto;
}

.footer__col-title {
  margin-bottom: 26px;
  color: #fe900b;
}

@media (max-width: 991.98px) {
  .footer__col-title {
    display: flex;
    align-items: center;
    height: 60px;
    margin: 0;
    font-size: 14px;
    font-weight: 300;
    color: #4a4a4a;
    cursor: pointer;
    border-bottom: 1px solid #e4e8f1;
  }
}

.footer__col-title--open .footer__col-title-icon {
  transform: rotate(-90deg);
}

@media (max-width: 991.98px) {
  .footer__col-title-text {
    width: 100%;
  }
}

.footer__col-title-icon {
  display: none;
  flex-shrink: 0;
  width: 12px;
  height: 12px;
  transition: 0.5s;
  fill: #000000;
}

@media (max-width: 991.98px) {
  .footer__col-title-icon {
    display: inline-flex;
  }
}

.footer__list {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

@media (max-width: 991.98px) {
  .footer__list {
    display: none;
    padding: 28px 0;
    border-bottom: 1px solid #e4e8f1;
  }
}

.footer__link {
  margin-bottom: 11px;
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  color: #4a4a4a;
  transition: 0.3s;
}

@media (max-width: 991.98px) {
  .footer__link {
    margin-bottom: 16px;
  }
}

.footer__link:last-child {
  margin-bottom: 0;
}

.footer__link:hover {
  color: #fe900b;
}

@media (max-width: 1279.98px) {
  .footer__link:hover {
    color: #4a4a4a;
  }
}

.footer__phone {
  font-size: 16px;
  color: #4a4a4a;
  transition: 0.3s;
}

.footer__phone:hover {
  color: #fe900b;
}

@media (max-width: 1279.98px) {
  .footer__phone:hover {
    color: #4a4a4a;
  }
}

@media (max-width: 991.98px) {
  .footer__phone {
    margin-top: 20px;
  }
}

.footer__address {
  margin-top: 50px;
  font-size: 14px;
  line-height: 20px;
  color: #4a4a4a;
}

.footer__feedback {
  margin-top: 20px;
  font-size: 14px;
  color: #fe900b;
}

.footer__social {
  display: flex;
  margin-top: 50px;
}

@media (max-width: 991.98px) {
  .footer__social {
    justify-content: center;
    margin-top: 30px;
  }
}

.footer__social-link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  margin: 0 10px 0 0;
  background-color: #cbdaed;
  border-radius: 50%;
  transition: 0.3s;
}

.footer__social-link:last-child {
  margin-right: 0;
}

.footer__social-link:hover {
  background-color: #f2c8ce;
}

.footer__social-link:hover .footer__social-icon {
  fill: #ff3d00;
}

.footer__social-icon {
  width: 14px;
  height: 14px;
  fill: #4669ae;
  transition: 0.3s;
}

.footer__copyright {
  font-size: 14px;
  color: #9b9b9b;
  opacity: 0.97;
}

@media (max-width: 991.98px) {
  .footer__copyright {
    font-size: 13px;
    font-weight: 300;
    color: #4a4a4a;
    text-align: center;
    opacity: 1;
  }
}

.footer__bottom-link {
  padding-bottom: 3px;
  margin-right: 30px;
  font-size: 14px;
  color: #9b9b9b;
  border-bottom: 1px solid rgba(155, 155, 155, 0.1);
  opacity: 0.97;
  transition: 0.3s;
}

.footer__bottom-link:hover {
  border-color: rgba(155, 155, 155, 0);
}

.footer__bottom-link:last-child {
  margin-right: 0;
}

.footer__newsletter {
  display: none;
  flex-direction: column;
  margin-top: 20px;
}

@media (max-width: 991.98px) {
  .footer__newsletter {
    display: flex;
  }
}

.footer__newsletter-form {
  position: relative;
  display: flex;
  height: 100%;
  padding: 8px 9px 10px 0;
  margin-bottom: 10px;
}

.footer__newsletter-form .form__row {
  position: relative;
  width: 100%;
}

.footer__newsletter-form .form__row {
  transition: 0.3s;
  transition-delay: 0.3s;
}

.footer__newsletter-form--success .form__row {
  opacity: 0;
  transition-delay: 0s;
}

.footer__newsletter-form--success .footer__newsletter-success {
  opacity: 1;
  transition-delay: 0.3s;
}

.footer__newsletter-input {
  width: 100%;
  height: 57px;
  padding: 0 170px 0 20px;
  font-size: 16px;
  color: #4a4a4a;
  background-color: #ffffff;
  border: 0;
  border-radius: 20px;
  outline: 0;
}

.footer__newsletter-input::placeholder {
  color: #979797;
}

.footer__newsletter-button {
  position: absolute;
  top: 8px;
  right: 10px;
  flex-shrink: 0;
  height: 40px;
  min-width: 150px;
  font-size: 14px;
  color: #555555;
  border-radius: 20px;
}

.footer__newsletter-text {
  margin: 0 0 22px;
  font-size: 13px;
  font-weight: 300;
  line-height: 20px;
  color: #4a4a4a;
}

.footer__newsletter-text:last-child {
  margin-bottom: 0;
}

.footer__newsletter-success {
  position: absolute;
  top: 20px;
  left: 0;
  font-size: 13px;
  font-weight: 300;
  line-height: 18px;
  color: #4a4a4a;
  opacity: 0;
  transition: 0.3s;
  transition-delay: 0s;
}

.footer__mini-phone {
  font-size: 14px;
  color: #000000;
  transition: 0.3s;
}

.footer__mini-phone:hover {
  color: #fe900b;
}

@media (max-width: 1279.98px) {
  .footer__mini-phone:hover {
    color: #000000;
  }
}

.footer__support-button {
  width: 100%;
  height: 40px;
  padding: 0 10px;
  margin-top: 20px;
  font-size: 14px;
  border-radius: 20px;
}

/* end footer */
/* begin overlay */
.overlay {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9;
  width: 100%;
  height: 100%;
}

/* end overlay */
/* begin msearch */
.msearch {
  display: flex;
  flex-direction: column;
}

@media (max-width: 575.98px) {
  .msearch__wrapper {
    padding: 0;
  }
}

.msearch__header {
  padding: 17px 0;
  background-color: #f4f4f4;
}

@media (max-width: 575.98px) {
  .msearch__header {
    padding: 17px 20px;
  }
}

.msearch__input {
  width: 100%;
  height: 50px;
  padding: 0 18px;
  font-size: 15px;
  color: #4a4a4a;
  background-color: #ffffff;
  border: 2px solid #e5e5e5;
  outline: none;
}

.msearch__list {
  display: flex;
  flex-direction: column;
}

.msearch__item {
  border-top: 1px solid #e4e8f1;
}

.msearch__item:first-child {
  border-top: 0;
}

.msearch__item--all {
  border-top: 0;
}

.msearch__item--all .msearch__link {
  background-color: #fef3e6;
}

.msearch__item--all .msearch__text {
  font-weight: 400;
  color: #fe900b;
}

.msearch__link {
  display: flex;
  align-items: center;
  min-height: 59px;
  padding: 13px 20px;
}

.msearch__text {
  width: 100%;
  margin-right: 20px;
  font-size: 14px;
  font-weight: 300;
  color: #4a4a4a;
}

.msearch__icon {
  flex-shrink: 0;
  width: 12px;
  height: 12px;
  fill: #000000;
}

/* end msearch */
/* begin popup */
.popup__item#mobile-search-popup .hystmodal__window {
  width: 100%;
  height: calc(100% - 65px);
  margin: 65px 0 0;
}

@media (max-width: 1279.98px) {
  .popup__item#mobile-search-popup .hystmodal__window {
    border-radius: 0;
  }
}

.popup__item#choose-your-city-popup .hystmodal__window {
  width: 1130px;
  max-width: 100%;
  padding: 30px 50px 50px;
}

.popup__item#choose-your-city-popup .popup__title {
  margin-bottom: 42px;
}

.popup__item#login-popup .hystmodal__window, .popup__item#login-code-popup .hystmodal__window {
  width: 420px;
  max-width: 100%;
  padding: 50px 30px;
}

.popup__item#login-popup .popup__title, .popup__item#login-code-popup .popup__title {
  margin-bottom: 63px;
}

.popup__item#quick-order-popup .hystmodal__window {
  width: 650px;
  max-width: 100%;
  padding: 50px 138px;
}

@media (max-width: 767.98px) {
  .popup__item#quick-order-popup .hystmodal__window {
    width: 100%;
    height: 100%;
    padding: 73px 20px 50px;
    border-radius: 0;
  }
}

.popup__item#support-popup .hystmodal__window {
  width: 420px;
  max-width: 100%;
  overflow: hidden;
  border-radius: 10px;
}

@media (max-width: 575.98px) {
  .popup__item#support-popup .hystmodal__window {
    width: 100%;
    border-radius: 0;
  }
}

.popup__close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 20px;
  cursor: pointer;
}

.popup__close:hover .popup__close-icon {
  fill: #000000;
}

.popup__close-icon {
  width: 16px;
  height: 16px;
  fill: #555555;
  transition: 0.3s;
}

.popup__choose-city-list {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -30px;
}

.popup__choose-city-radio {
  width: 25%;
  margin-bottom: 29px;
}

.popup__choose-city-search {
  margin-top: 51px;
}

.popup__choose-city-search .search__form {
  width: 100%;
  height: 60px;
  border-radius: 5px;
}

.popup__form .form__button {
  margin-top: 20px;
}

.popup__link {
  font-size: 14px;
  color: #4a4a4a;
  transition: 0.3s;
}

.popup__link:hover {
  color: #fe900b;
}

@media (max-width: 1279.98px) {
  .popup__link:hover {
    color: #4a4a4a;
  }
}

.popup__link--login {
  display: inline-flex;
  justify-content: center;
  width: 100%;
  margin-top: 50px;
  text-align: center;
}

.popup__link--resend {
  margin-top: 34px;
  color: #ff5656;
  text-align: center;
}

.popup__link--resend:hover {
  color: #ff5656;
}

.popup__link--resend-seconds {
  margin-top: 34px;
  text-align: center;
  pointer-events: none;
}

.popup__login-code {
  display: flex;
  justify-content: space-between;
}

.popup__login-code-input {
  display: inline-flex;
  width: calc(100% / 6 - 10px);
  height: 50px;
  font-size: 16px;
  color: #555555;
  text-align: center;
  border: 2px solid #cccccc;
  border-radius: 10px;
  outline: none;
  -moz-appearance: textfield;
}

.popup__login-code-input::-webkit-outer-spin-button, .popup__login-code-input::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none;
}

.popup__login-code-phone {
  display: flex;
  align-items: flex-end;
}

.popup__login-code-phone-num {
  font-size: 16px;
  color: #4a4a4a;
}

.popup__login-code-phone-button {
  margin-left: 30px;
  font-size: 14px;
  color: #ff5656;
  cursor: pointer;
}

.popup__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 23px 20px;
  background-color: #fe900b;
}

.popup__header .popup__close {
  position: static;
  padding: 0;
}

.popup__header .popup__close-icon {
  width: 12px;
  height: 12px;
  fill: #ffffff;
}

.popup__header-title {
  font-size: 16px;
  color: #ffffff;
}

.popup__text-block {
  padding: 20px 20px 50px;
}

.hystmodal__window {
  border-radius: 10px;
  transition: transform 0.4s ease-in-out 0s, opacity 0.4s ease-in-out 0s;
}

.hystmodal__shadow {
  background-color: #000000;
  transition: opacity 0.4s ease-in-out;
}

.hystmodal__shadow--show {
  opacity: 0.7;
}

/* end popup */
/* begin catalog */
.catalog__wrapper {
  display: flex;
  flex-wrap: wrap;
}

.catalog__header {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-bottom: 70px;
}

@media (max-width: 575.98px) {
  .catalog__header {
    align-items: flex-start;
    margin-bottom: 50px;
  }
}

.catalog__header-img {
  width: 67px;
  height: 67px;
  margin-bottom: 20px;
}

@media (max-width: 575.98px) {
  .catalog__header-img {
    display: none;
  }
}

.catalog__title {
  line-height: 30px;
  color: #000000;
  text-align: center;
}

@media (max-width: 575.98px) {
  .catalog__title {
    text-align: left;
  }
}

.catalog__filters-toggle {
  display: none;
  width: 335px;
  max-width: 100%;
  margin-top: 30px;
  text-transform: uppercase;
  border-radius: 20px;
}

@media (max-width: 1279.98px) {
  .catalog__filters-toggle {
    display: inline-flex;
  }
}

@media (max-width: 575.98px) {
  .catalog__filters-toggle {
    width: 100%;
  }
}

.catalog__filters {
  width: 270px;
  margin-right: 30px;
  transition: 0s;
}

@media (max-width: 1279.98px) {
  .catalog__filters {
    position: fixed;
    top: 0;
    left: -310px;
    z-index: 10;
    width: 310px;
    height: 100%;
    padding: 40px 20px;
    margin-right: 0;
    overflow: hidden;
    box-shadow: 0 0 7px 0 rgba(74, 74, 74, 0.1);
    transition: 0.5s !important;
  }
}

.catalog__filter {
  margin-bottom: 20px;
}

.catalog__filter--no-title .catalog__filter-list {
  padding-top: 0;
}

.catalog__filter-toggle {
  display: flex;
  align-items: center;
  min-height: 40px;
  cursor: pointer;
}

.catalog__filter-toggle--open .catalog__filter-toggle-icon {
  transform: rotate(-90deg);
}

.catalog__filter-title {
  width: 100%;
  margin-right: 20px;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}

.catalog__filter-toggle-icon {
  flex-shrink: 0;
  width: 10px;
  height: 10px;
  transform: rotate(90deg);
  transition: 0.3s;
  fill: #555555;
}

.catalog__filter-list {
  padding: 28px 0;
}

.catalog__filter-item {
  margin-bottom: 18px;
}

.catalog__filter-item:last-child {
  margin-bottom: 0;
}

.catalog__filters-button {
  width: 100%;
}

.catalog__body {
  width: calc(100% - 300px);
}

@media (max-width: 1279.98px) {
  .catalog__body {
    width: 100%;
  }
}

.catalog__list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: -30px;
}

@media (max-width: 1279.98px) {
  .catalog__list {
    width: 870px;
    margin: 0 auto -30px;
  }
}

@media (max-width: 991.98px) {
  .catalog__list {
    width: 570px;
    margin: 0 auto -30px;
  }
}

@media (max-width: 767.98px) {
  .catalog__list {
    width: 100%;
    margin: 0 auto -12px;
  }
}

@media (max-width: 575.98px) {
  .catalog__list {
    width: calc(100% + 40px);
    margin: 0 -20px -12px;
  }
}

.catalog__product {
  width: 270px;
  margin: 0 30px 30px 0;
}

@media (max-width: 767.98px) {
  .catalog__product {
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    padding: 46px 20px 12px;
    margin: 0 0 12px;
    border: 0;
    border-bottom: 1px solid #f4f4f4;
    border-radius: 0;
  }
  .catalog__product .product__badges {
    top: 8px;
    left: 21px;
  }
  .catalog__product .product__compare {
    top: 10px;
  }
  .catalog__product .product__img-link {
    width: 100px;
    height: 100px;
  }
  .catalog__product .product__img {
    width: 100px;
    height: 100px;
  }
  .catalog__product .product__title {
    width: calc(100% - 110px);
    height: 72px;
    margin: 0 0 0 10px;
    line-height: 24px;
  }
  .catalog__product .product__footer {
    position: relative;
    width: 100%;
    padding-left: 108px;
    margin-top: -7px;
  }
  .catalog__product .product__price {
    width: 100%;
    height: 50px;
    padding-right: 30px;
    margin-right: 0;
  }
  .catalog__product .product__price-profit {
    bottom: 8px;
  }
  .catalog__product .product__button {
    display: none;
    flex-shrink: 0;
  }
  .catalog__product .product__button--mobile-arrow {
    position: absolute;
    top: -6px;
    right: -20px;
    display: inline-flex;
    border: 0;
  }
  .catalog__product .product__button--mobile-arrow .product__button-icon {
    width: 12px;
    height: 12px;
    fill: #000000;
  }
}

.catalog__product:nth-child(3n) {
  margin-right: 0;
}

@media (max-width: 991.98px) {
  .catalog__product:nth-child(3n) {
    margin-right: 30px;
  }
}

@media (max-width: 767.98px) {
  .catalog__product:nth-child(3n) {
    margin-right: 0;
  }
}

@media (max-width: 991.98px) {
  .catalog__product:nth-child(2n) {
    margin-right: 0;
  }
}

.catalog__product .product__button--mobile-arrow {
  display: none;
}

.catalog__price {
  padding: 0 0 9px;
  margin: 10px 0 0;
}

.catalog__price-inputs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.catalog__price-input {
  width: 110px;
  height: 50px;
  font-size: 16px;
  font-weight: 300;
  color: #555555;
  text-align: center;
  border: 1px solid #cccccc;
  border-radius: 4px;
  outline: none;
}

.catalog__price-inputs-sep {
  width: 10px;
  height: 2px;
  background-color: #cccccc;
  border-radius: 1px;
}

/* end catalog */
/* begin product-card */
.product-card--handbook {
  display: flex;
  flex-wrap: wrap;
  margin-top: 89px;
}

@media (max-width: 575.98px) {
  .product-card--handbook {
    margin: auto;
  }
}

.product-card--handbook .product-card__spec-tab {
  padding: 0;
  margin-top: 46px;
}

@media (max-width: 575.98px) {
  .product-card--handbook .product-card__spec-tab {
    width: 100%;
  }
}

@media (max-width: 575.98px) {
  .product-card--handbook .product-card__info-specifications, .product-card--handbook .product-card__go-to-tab {
    display: none;
  }
}

@media (max-width: 575.98px) {
  .product-card--handbook .product-card__sliders {
    margin: 31px 0 0;
  }
}

.product-card__wrapper {
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 991.98px) {
  .product-card__wrapper {
    flex-direction: column;
    flex-wrap: nowrap;
  }
}

@media (max-width: 575.98px) {
  .product-card__title {
    line-height: 30px;
  }
}

.product-card__sliders {
  display: flex;
  flex-shrink: 0;
  width: 565px;
  margin-right: 40px;
}

@media (max-width: 1279.98px) {
  .product-card__sliders {
    width: 490px;
  }
}

@media (max-width: 991.98px) {
  .product-card__sliders {
    width: 565px;
    margin: 50px 0 0;
  }
}

@media (max-width: 767.98px) {
  .product-card__sliders {
    width: 100%;
    margin: 50px 0 0;
  }
}

@media (max-width: 575.98px) {
  .product-card__sliders {
    flex-direction: column;
  }
}

.product-card__slider-thumbs {
  flex-shrink: 0;
  width: 100px;
  height: 500px;
  padding: 20px 0;
  margin: -20px 10px -20px 0;
  margin-right: 10px;
}

.product-card__slider-thumbs .swiper-slide {
  padding: 8px;
  cursor: pointer;
  background-color: #ffffff;
  border: 2px solid rgba(254, 144, 11, 0.1);
  border-radius: 20px;
  transition: 0.3s border-color;
}

.product-card__slider-thumbs .swiper-slide:hover {
  border-color: #fe900b;
}

.product-card__slider-thumbs .swiper-slide.swiper-slide-thumb-active {
  border-color: #fe900b;
}

.product-card__slider-thumbs .swiper-button-prev,
.product-card__slider-thumbs .swiper-button-next {
  left: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  transform: translateX(-50%);
}

@media (max-width: 575.98px) {
  .product-card__slider-thumbs .swiper-button-prev,
  .product-card__slider-thumbs .swiper-button-next {
    display: none;
  }
}

.product-card__slider-thumbs .swiper-button-prev::after,
.product-card__slider-thumbs .swiper-button-next::after {
  content: none;
}

.product-card__slider-thumbs .swiper-button-prev:hover .swiper-button-icon,
.product-card__slider-thumbs .swiper-button-next:hover .swiper-button-icon {
  fill: #fe900b;
}

.product-card__slider-thumbs .swiper-button-prev.swiper-button-disabled,
.product-card__slider-thumbs .swiper-button-next.swiper-button-disabled {
  opacity: 1 !important;
}

.product-card__slider-thumbs .swiper-button-prev {
  top: 15px;
}

.product-card__slider-thumbs .swiper-button-prev .swiper-button-icon {
  transform: rotate(-90deg);
}

.product-card__slider-thumbs .swiper-button-next {
  top: auto;
  bottom: -7px;
}

.product-card__slider-thumbs .swiper-button-next .swiper-button-icon {
  transform: rotate(90deg);
}

.product-card__slider-thumbs .swiper-button-icon {
  width: 16px;
  height: 16px;
  transition: 0.3s;
  fill: #ffe3c2;
}

@media (max-width: 575.98px) {
  .product-card__slider-thumbs {
    order: 1;
    width: 100%;
    height: calc((100vw - 40px - 15px) / 4);
  }
}

.product-card__slider-main {
  width: 455px;
  height: 455px;
}

@media (max-width: 1279.98px) {
  .product-card__slider-main {
    width: 380px;
  }
}

@media (max-width: 991.98px) {
  .product-card__slider-main {
    width: 455px;
  }
}

@media (max-width: 767.98px) {
  .product-card__slider-main {
    width: 406px;
  }
}

@media (max-width: 575.98px) {
  .product-card__slider-main {
    width: 100%;
    height: calc(100vw - 40px);
    margin-bottom: 29px;
  }
}

.product-card__img {
  object-fit: contain;
  font-family: "object-fit: contain; object-position: center";
  object-position: center;
  width: 100%;
  height: 100%;
}

.product-card__info {
  flex-shrink: 0;
  width: calc(100% - 565px - 40px);
}

@media (max-width: 1279.98px) {
  .product-card__info {
    width: calc(100% - 490px - 40px);
  }
}

@media (max-width: 991.98px) {
  .product-card__info {
    width: 100%;
  }
}

.product-card__undertitle {
  display: flex;
  margin-top: 23px;
}

@media (max-width: 1279.98px) {
  .product-card__undertitle {
    flex-wrap: wrap;
  }
}

@media (max-width: 991.98px) {
  .product-card__undertitle {
    flex-wrap: nowrap;
  }
}

@media (max-width: 575.98px) {
  .product-card__undertitle {
    flex-wrap: wrap;
    margin-top: 32px;
  }
}

.product-card__rating {
  margin-right: 49px;
}

@media (max-width: 1279.98px) {
  .product-card__rating {
    width: 100%;
    margin: 0 0 20px;
  }
}

@media (max-width: 991.98px) {
  .product-card__rating {
    width: auto;
    margin: 0 49px 0 0;
  }
}

@media (max-width: 575.98px) {
  .product-card__rating {
    width: 100%;
    margin: 0 0 30px;
  }
}

.product-card__checkbox-button {
  margin-right: 28px;
}

.product-card__checkbox-button:last-child {
  margin-right: 0;
}

.product-card__checkbox-button--compare .product-card__checkbox-button-icon {
  width: 12px;
  height: 12px;
}

.product-card__checkbox-button-body {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.product-card__checkbox-button-icon {
  width: 14px;
  height: 14px;
  margin-right: 10px;
  transition: 0.3s fill;
  fill: #4a4a4a;
}

.product-card__checkbox-button-input {
  display: none;
}

.product-card__checkbox-button-input:checked + .product-card__checkbox-button-body .product-card__checkbox-button-icon {
  fill: #fe900b;
}

.product-card__checkbox-button-input:checked + .product-card__checkbox-button-body .product-card__checkbox-button-text {
  color: #fe900b;
}

.product-card__checkbox-button-text {
  position: relative;
  bottom: 1px;
  font-size: 16px;
  color: #4a4a4a;
  transition: 0.3s color;
}

.product-card__info-specifications {
  margin: 26px 0 0;
}

.product-card__go-to-tab {
  display: inline-flex;
  margin: 25px 0 0;
  font-size: 14px;
  color: #fe900b;
  background: transparent;
  border: 0;
  outline: 0;
  transition: 0.3s;
}

.product-card__go-to-tab:hover {
  opacity: 0.75;
}

@media (max-width: 1279.98px) {
  .product-card__go-to-tab:hover {
    opacity: 1;
  }
}

.product-card__price {
  margin: 52px 0 0 0;
  font-size: 36px;
  color: #555555;
}

@media (max-width: 575.98px) {
  .product-card__price {
    margin-top: 34px;
  }
}

.product-card__buttons {
  display: flex;
  margin: 30px 0 0;
}

@media (max-width: 575.98px) {
  .product-card__buttons {
    flex-direction: column;
  }
}

.product-card__button--buy {
  min-width: 155px;
  margin-right: 30px;
}

@media (max-width: 575.98px) {
  .product-card__button--buy {
    margin: 0 0 10px;
  }
}

.product-card__button--buy-one-click {
  min-width: 217px;
}

.product-card__button-icon {
  width: 22px;
  height: 22px;
  margin-right: 10px;
  fill: #ffffff;
}

.product-card__tabs {
  width: 100%;
  margin-top: 50px;
}

@media (max-width: 575.98px) {
  .product-card__tabs {
    margin-top: 53px;
  }
}

.product-card__tabs-slider {
  width: 100%;
}

@media (max-width: 575.98px) {
  .product-card__tabs-slider--thumbs {
    position: relative;
    width: calc(100% + 40px);
    padding-bottom: 3px;
    margin: 0 -20px;
  }
}

@media (max-width: 575.98px) {
  .product-card__tabs-slider--thumbs::after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: rgba(74, 74, 74, 0.1);
    content: "";
  }
}

@media (max-width: 575.98px) {
  .product-card__tabs-slider--body {
    width: calc(100% + 40px);
    margin: 0 -20px;
  }
}

.product-card__tabs-slider--body .swiper-slide {
  padding-top: 50px;
}

.product-card__tabs-nav-link {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: 60px;
  padding: 0 35px;
  font-size: 16px;
  color: #555555;
  cursor: pointer;
  background-color: rgba(254, 144, 11, 0.1);
  border-radius: 5px;
  outline: 0;
  transition: 0.3s background-color;
}

@media (max-width: 575.98px) {
  .product-card__tabs-nav-link {
    height: auto;
    padding: 17px 0;
    font-size: 14px;
    color: #4a4a4a;
    background-color: transparent;
  }
}

@media (max-width: 575.98px) {
  .product-card__tabs-nav-link::after {
    position: absolute;
    bottom: -3px;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: #4a4a4a;
    content: "";
    opacity: 0;
    transition: 0.3s;
  }
}

@media (max-width: 575.98px) {
  .product-card__tabs-nav-link:first-child {
    padding-left: 20px;
  }
  .product-card__tabs-nav-link:first-child::after {
    left: 20px;
    width: calc(100% - 20px);
  }
}

.product-card__tabs-nav-link.swiper-slide-thumb-active, .product-card__tabs-nav-link:hover {
  background-color: rgba(254, 144, 11, 0.2);
}

@media (max-width: 575.98px) {
  .product-card__tabs-nav-link.swiper-slide-thumb-active, .product-card__tabs-nav-link:hover {
    background-color: transparent;
  }
}

.product-card__tabs-nav-link.swiper-slide-thumb-active {
  pointer-events: none;
}

@media (max-width: 575.98px) {
  .product-card__tabs-nav-link.swiper-slide-thumb-active::after {
    opacity: 1;
  }
}

.product-card__desc-tab {
  display: flex;
  padding: 0 20px;
}

@media (max-width: 991.98px) {
  .product-card__desc-tab {
    flex-direction: column;
  }
}

.product-card__desc-tab-text-block {
  width: 565px;
  max-width: 100%;
  padding: 0 21px;
  margin-right: 20px;
}

@media (max-width: 767.98px) {
  .product-card__desc-tab-text-block {
    margin-right: 0;
  }
}

@media (max-width: 575.98px) {
  .product-card__desc-tab-text-block {
    padding: 0;
  }
}

.product-card__desc-tab-text-block--docs {
  width: auto;
  margin-right: 0;
}

@media (max-width: 991.98px) {
  .product-card__desc-tab-text-block--docs {
    margin-top: 40px;
  }
}

.product-card__desc-tab-text-block .product-card__docs-item {
  padding: 0;
  line-height: inherit;
}

.product-card__desc-tab-text-block .product-card__docs-item::before {
  content: none;
}

.product-card__desc-tab-text-block .product-card__docs-name {
  color: #000000;
}

.product-card__docs-list {
  display: flex;
  flex-direction: column;
  margin: -3px 0 0;
}

.product-card__docs-item {
  display: inline-flex;
  margin-bottom: 20px;
}

.product-card__docs-item:last-child {
  margin-bottom: 0;
}

.product-card__docs-format {
  display: inline-flex;
  align-items: center;
  width: auto;
  height: 20px;
  padding: 0 5px;
  margin-right: 10px;
  font-size: 13px;
  line-height: 20px;
  color: #ffffff;
  background-color: #555555;
  border-radius: 5px;
}

.product-card__docs-name {
  font-size: 14px;
  font-weight: 300;
  color: #000000;
  transition: 0.3s color;
}

.product-card__docs-name:hover {
  color: #fe900b;
}

@media (max-width: 1279.98px) {
  .product-card__docs-name:hover {
    color: #000000;
  }
}

.product-card__spec-tab {
  padding: 0 20px;
}

.product-card__spec-table {
  display: flex;
  flex-direction: column;
  width: 540px;
  max-width: 100%;
}

@media (max-width: 575.98px) {
  .product-card__spec-table {
    width: calc(100% + 40px);
    max-width: calc(100% + 40px);
  }
}

.product-card__spec-table:last-child {
  margin-bottom: 0;
}

.product-card__spec-table-row {
  display: flex;
  padding: 9px 0;
}

.product-card__spec-table-row:nth-child(odd) {
  background-color: #f5f5f5;
}

.product-card__spec-table-key, .product-card__spec-table-val {
  padding: 0 20px;
  font-size: 14px;
  font-weight: 300;
  color: #000000;
}

.product-card__spec-table-key {
  width: 372px;
}

@media (max-width: 767.98px) {
  .product-card__spec-table-key {
    width: 320px;
  }
}

@media (max-width: 575.98px) {
  .product-card__spec-table-key {
    width: 200px;
  }
}

.product-card__spec-tab-text-block .product-card__spec-table {
  margin-bottom: 30px;
}

@media (max-width: 575.98px) {
  .product-card__spec-tab-text-block .product-card__spec-table {
    margin: 0 -20px 30px;
  }
}

.product-card__spec-tab-text-block .product-card__spec-table-row {
  padding: 9px 0;
  line-height: 16px;
}

.product-card__spec-tab-text-block .product-card__spec-table-row::before {
  content: none;
}

.product-card__comment-tab {
  padding: 0 20px;
}

.product-card__delivery-tab {
  width: 575px;
  max-width: 100%;
  padding: 0 20px;
}

.product-card__delivery-tab .form__row {
  margin-bottom: 27px;
}

.product-card__delivery-list {
  width: 505px;
  max-width: 100%;
}

.lg-backdrop {
  background-color: rgba(0, 0, 0, 0.6);
}

/* end product-card */
/* begin comment */
.comment {
  width: 575px;
  max-width: 100%;
}

.comment--message {
  width: 100%;
  padding: 50px 0 200px;
}

@media (max-width: 575.98px) {
  .comment--message {
    padding: 70px 0 100px;
  }
}

.comment__header {
  display: flex;
  align-items: center;
  padding: 0 30px 0 0;
}

@media (max-width: 767.98px) {
  .comment__header {
    align-items: flex-start;
  }
}

.comment__avatar {
  display: inline-flex;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
  width: 55px;
  height: 55px;
  margin-right: 20px;
  font-size: 14px;
  color: #4a4a4a;
  background-color: #fef3e6;
  border-radius: 50%;
}

.comment__header-info {
  display: flex;
  align-items: center;
  width: 100%;
}

@media (max-width: 767.98px) {
  .comment__header-info {
    align-items: center;
    flex-wrap: wrap;
    padding: 8px 0 0;
  }
}

.comment__username {
  flex-shrink: 0;
  margin-right: 30px;
  font-size: 16px;
  line-height: 17px;
  color: #000000;
}

.comment__date {
  font-size: 14px;
  color: #9b9b9b;
}

.comment__rating {
  margin: 0 0 0 auto;
}

@media (max-width: 767.98px) {
  .comment__rating {
    order: -1;
    width: 100%;
    margin: 0 0 10px;
  }
}

.comment__rating .rating__text {
  margin: 0 15px 0 0;
  font-size: 18.2px;
  color: #4a4a4a;
}

.comment__body {
  padding: 33px 0 0 75px;
}

@media (max-width: 767.98px) {
  .comment__body {
    padding-left: 0;
  }
}

.comment__form {
  padding: 0 20px 0 75px;
  margin: 99px 0 0 0;
}

@media (max-width: 767.98px) {
  .comment__form {
    padding: 0;
    margin: 70px 0 0 0;
  }
}

.comment__form-button {
  width: auto;
  min-width: 160px;
  margin-top: 27px;
}

@media (max-width: 575.98px) {
  .comment__form-button {
    width: 100%;
    min-width: auto;
    margin-top: 7px;
  }
}

.comment__message {
  font-size: 20px;
  font-weight: 300;
  color: #4a4a4a;
  text-align: center;
}

@media (max-width: 575.98px) {
  .comment__message {
    font-size: 14px;
  }
}

.comment__na-title {
  margin: 0 0 21px;
}

.comment__na-message {
  font-size: 14px;
  font-weight: 300;
  line-height: 22px;
  color: #000000;
}

.comment__na-button {
  min-width: 198px;
  margin-top: 29px;
}

@media (max-width: 575.98px) {
  .comment__na-button {
    width: 100%;
    min-width: auto;
  }
}

/* end comment */
/* begin basket */
.basket {
  margin-top: 70px;
}

@media (max-width: 1279.98px) {
  .basket {
    margin-top: 30px;
  }
}

.basket__wrapper {
  display: flex;
}

@media (max-width: 1279.98px) {
  .basket__wrapper {
    flex-wrap: wrap;
  }
}

@media (max-width: 991.98px) {
  .basket__wrapper {
    flex-direction: column;
  }
}

.basket__title {
  display: none;
}

@media (max-width: 1279.98px) {
  .basket__title {
    display: block;
    width: 100%;
    margin-bottom: 50px;
  }
}

.basket__body {
  width: calc(100% - 277px);
  margin-right: 50px;
}

@media (max-width: 991.98px) {
  .basket__body {
    width: 100%;
    margin-bottom: 30px;
  }
}

@media (max-width: 575.98px) {
  .basket__body {
    margin-bottom: 0;
  }
}

.basket__list {
  margin-bottom: 31px;
}

@media (max-width: 575.98px) {
  .basket__list {
    margin-bottom: 49px;
  }
}

.basket__item:last-child .product--basket {
  margin: 0;
  border: 0;
}

.basket__coupon {
  width: 100%;
}

@media (max-width: 575.98px) {
  .basket__coupon {
    width: calc(100% + 40px);
    padding: 20px 20px 30px;
    margin: 0 -20px;
    background-color: #f2f3f8;
  }
}

.basket__coupon-form {
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 575.98px) {
  .basket__coupon-form {
    flex-direction: column;
    height: auto;
  }
}

.basket__coupon-input-box {
  position: relative;
  width: calc(100% - 175px);
  margin-right: 10px;
}

@media (max-width: 767.98px) {
  .basket__coupon-input-box {
    width: 100%;
  }
}

@media (max-width: 767.98px) {
  .basket__coupon-input-box {
    order: 1;
    margin: 0 0 10px;
  }
}

.basket__coupon-input-box--checked .basket__coupon-checked {
  opacity: 1;
}

.basket__coupon-input {
  width: 100%;
  height: 60px;
  padding: 0 40px 0 20px;
  font-size: 16px;
  font-weight: 300;
  color: #4a4a4a;
  background-color: rgba(254, 144, 11, 0.05);
  border: 0;
  border-radius: 5px;
  outline: 0;
}

@media (max-width: 575.98px) {
  .basket__coupon-input {
    height: 57px;
    background-color: #ffffff;
  }
}

.basket__coupon-checked {
  position: absolute;
  top: 50%;
  right: 20px;
  width: 10px;
  height: 10px;
  opacity: 0;
  transform: translateY(-50%);
  transition: 0.3s;
  fill: #fe900b;
}

.basket__coupon-button {
  flex-shrink: 0;
  height: 60px;
  min-width: 165px;
  color: #555555;
}

@media (max-width: 767.98px) {
  .basket__coupon-button {
    order: 3;
    width: 100%;
    min-width: 0;
  }
}

@media (max-width: 575.98px) {
  .basket__coupon-button {
    height: 50px;
  }
}

.basket__coupon-error {
  width: 100%;
  margin-top: 5px;
  font-size: 14px;
  color: #ff5656;
}

@media (max-width: 767.98px) {
  .basket__coupon-error {
    order: 2;
    margin: 2px 0 10px;
  }
}

.basket__sidebar {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 227px;
}

@media (max-width: 991.98px) {
  .basket__sidebar {
    width: 100%;
  }
}

.basket__info {
  padding: 20px;
  margin-bottom: 20px;
  background-color: rgba(254, 144, 11, 0.05);
}

@media (max-width: 575.98px) {
  .basket__info {
    padding: 0 20px 30px;
    margin: 0 -20px 30px;
    background-color: #f2f3f8;
  }
}

.basket__order-price {
  display: flex;
  align-items: flex-end;
  margin-bottom: 30px;
}

.basket__order-price-title {
  font-size: 14px;
  color: #000000;
}

.basket__order-price-num {
  margin-left: auto;
  font-size: 20px;
  line-height: 20px;
  color: #000000;
}

.basket__info-text {
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  color: #000000;
  text-align: center;
}

@media (max-width: 991.98px) {
  .basket__info-text {
    text-align: left;
  }
}

.basket__sidebar-button {
  width: 250px;
  max-width: 100%;
}

@media (max-width: 575.98px) {
  .basket__sidebar-button {
    width: 100%;
  }
}

/* end basket */
/* begin checkout */
.checkout {
  margin-top: 23px;
}

@media (max-width: 1279.98px) {
  .checkout {
    margin-top: 30px;
  }
}

.checkout__wrapper {
  display: flex;
  flex-wrap: wrap;
}

.checkout__title {
  display: none;
}

@media (max-width: 1279.98px) {
  .checkout__title {
    display: block;
    width: 100%;
    margin-bottom: 50px;
  }
}

@media (max-width: 575.98px) {
  .checkout__title {
    margin-bottom: 43px;
  }
}

.checkout__body {
  width: calc(100% - 300px - 100px);
  margin-right: 100px;
}

@media (max-width: 991.98px) {
  .checkout__body {
    width: 100%;
    margin: 0;
  }
}

.checkout__form-title {
  margin: 27px 0 23px;
  font-weight: 500;
  color: #000000;
}

@media (max-width: 575.98px) {
  .checkout__form-title {
    margin: 33px 0 24px;
    font-size: 16px;
  }
}

.checkout__form-title span {
  color: #9b9b9b;
}

.checkout__form-radio-row {
  margin-top: 27px;
}

.checkout__form-radio {
  width: 100%;
  height: 100%;
}

@media (max-width: 575.98px) {
  .checkout__form-radio--payment-method .checkout__form-radio-body {
    min-height: 145px;
  }
}

.checkout__form-radio-input {
  display: none;
}

.checkout__form-radio-input:checked + .checkout__form-radio-body {
  border-color: #fe900b;
}

.checkout__form-radio-input:checked + .checkout__form-radio-body .checkout__form-radio-el {
  background-color: #fe900b;
  border-color: #fe900b;
}

.checkout__form-radio-input:checked + .checkout__form-radio-body .checkout__form-radio-el::after {
  opacity: 1;
}

.checkout__form-radio-body {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 18px;
  cursor: pointer;
  background-color: #ffffff;
  border: solid 2px #cccccc;
  border-radius: 5px;
  transition: 0.3s;
}

.checkout__form-radio-title {
  font-size: 16px;
  color: #000000;
}

.checkout__form-radio-info {
  margin: 10px 0 21px;
  font-size: 14px;
  color: #9b9b9b;
}

.checkout__form-radio-el {
  position: relative;
  width: 24px;
  height: 24px;
  margin-top: auto;
  border: 2px solid #cccccc;
  border-radius: 50%;
}

.checkout__form-radio-el::after {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background-color: #ffffff;
  border-radius: 50%;
  content: "";
  opacity: 0;
  transform: translate(-50%, -50%);
  transition: 0.3s;
}

.checkout__form-leader-list {
  width: 100%;
  margin-top: 21px;
}

.checkout__form-leader-list .leader-list__row:last-child .leader-list__col:last-child {
  font-size: 22px;
  font-weight: 500;
}

.checkout__form-leader-list .leader-list__col {
  font-size: 16px;
  font-weight: 400;
  color: #000000;
}

.checkout__form-leader-list .leader-list__col:last-child {
  width: auto;
}

.checkout__form-checkox {
  margin: 49px 0 0;
}

.checkout__form-checkox .checkbox__text {
  font-size: 14px;
  color: #999999;
}

.checkout__form-checkox .checkbox__el {
  width: 24px;
  height: 24px;
  margin: 4px 0 0;
  border-radius: 5px;
}

.checkout__form-button {
  width: auto;
  min-width: 204px;
  margin-top: 29px;
}

@media (max-width: 575.98px) {
  .checkout__form-button {
    width: 100%;
  }
}

.checkout__sidebar {
  flex-shrink: 0;
  width: 300px;
  padding: 27px 0 0;
}

@media (max-width: 991.98px) {
  .checkout__sidebar {
    display: none;
  }
}

.checkout__sidebar-info {
  padding: 30px 20px;
  font-size: 14px;
  line-height: 20px;
  color: #9b9b9b;
  background-color: rgba(254, 144, 11, 0.05);
  border-radius: 5px;
}

.checkout__sidebar-info-list {
  margin-top: 20px;
}

.checkout__sidebar-info-link {
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  transition: 0.3s;
}

.checkout__sidebar-info-link:hover {
  color: #fe900b;
}

@media (max-width: 1279.98px) {
  .checkout__sidebar-info-link:hover {
    color: #000000;
  }
}

.checkout__sidebar-info-price {
  font-size: 14px;
  color: #9b9b9b;
}

.checkout__sidebar-features {
  margin-top: 50px;
}

.checkout__sidebar-features-item {
  display: flex;
  margin-bottom: 30px;
}

.checkout__sidebar-features-item:last-child {
  margin-bottom: 0;
}

.checkout__sidebar-features-icon {
  flex-shrink: 0;
  width: 35px;
  height: 35px;
  margin-right: 20px;
  fill: #4a4a4a;
}

.checkout__sidebar-features-text {
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  color: #555555;
}

.checkout__pickpoint {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 23px;
}

.checkout__pickpoint-title {
  margin-bottom: 12px;
  font-weight: 500;
  color: #000000;
}

.checkout__pickpoint-info {
  margin-bottom: 30px;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  color: #000000;
}

.checkout__pickpoint-button {
  height: 60px;
  min-width: 153px;
}

/* end checkout */
/* begin checkout-success */
.checkout-success {
  display: flex;
  align-items: center;
  flex-grow: 1;
  justify-content: center;
}

.checkout-success__wrapper {
  width: 580px;
  max-width: 100%;
  padding: 20px;
}

.checkout-success__title {
  margin-bottom: 30px;
  text-align: center;
}

.checkout-success__info {
  font-size: 16px;
  line-height: 25px;
  color: #000000;
  text-align: center;
}

@media (max-width: 575.98px) {
  .checkout-success__info {
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    color: #4a4a4a;
  }
}

.checkout-success__leader-list {
  width: 100%;
  margin-top: 105px;
}

@media (max-width: 575.98px) {
  .checkout-success__leader-list {
    margin-top: 100px;
  }
}

.checkout-success__leader-list .leader-list__col:first-child {
  font-size: 16px;
  font-weight: 400;
  color: #000000;
}

.checkout-success__leader-list .leader-list__col:last-child {
  width: auto;
  font-size: 22px;
  font-weight: 500;
  color: #000000;
}

.checkout-success__footer {
  display: flex;
  margin-top: 50px;
}

@media (max-width: 575.98px) {
  .checkout-success__footer {
    flex-direction: column;
    margin-top: 35px;
  }
}

.checkout-success__disclaimer {
  margin-right: 30px;
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  color: #4a4a4a;
}

@media (max-width: 575.98px) {
  .checkout-success__disclaimer {
    order: 1;
    margin-top: 20px;
    text-align: center;
  }
}

.checkout-success__button {
  height: 60px;
  min-width: 151px;
}

/* end checkout-success */
/* begin loader */
.loader {
  position: relative;
  overflow: hidden;
  pointer-events: none;
}

.loader.product__button .loader__item {
  background-size: 40px;
}

.loader.product__button .product__button-icon {
  opacity: 0;
}

.loader__item {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' fill='%23fe900b'%3E%3Cpath opacity='0.4' d='M20.201 5.169c-8.254 0-14.946 6.692-14.946 14.946 0 8.255 6.692 14.946 14.946 14.946s14.946-6.691 14.946-14.946c-.001-8.254-6.692-14.946-14.946-14.946zm0 26.58c-6.425 0-11.634-5.208-11.634-11.634 0-6.425 5.209-11.634 11.634-11.634 6.425 0 11.633 5.209 11.633 11.634 0 6.426-5.208 11.634-11.633 11.634z'/%3E%3Cpath d='M26.013 10.047l1.654-2.866a14.855 14.855 0 00-7.466-2.012v3.312c2.119 0 4.1.576 5.812 1.566z'%3E%3CanimateTransform attributeType='xml' attributeName='transform' type='rotate' from='0 20 20' to='360 20 20' dur='0.8s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50px;
}

/* end loader */
/* begin range */
.range {
  position: relative;
  display: flex;
  align-items: center;
  height: 25px;
}

.range__line {
  width: 100%;
  height: 5px;
  background-color: rgba(204, 204, 204, 0.5);
  border-radius: 2px;
}

.range__connector {
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 1;
  height: 5px;
  background-color: #fe900b;
  border-radius: 2px;
  transform: translateY(-50%);
}

.range__circle {
  position: absolute;
  top: 0;
  z-index: 2;
  width: 25px;
  height: 25px;
  cursor: pointer;
  background-color: #ffffff;
  border: 2px solid #fe900b;
  border-radius: 50%;
}

.range__circle--start {
  left: 0;
}

.range__circle--finish {
  right: 40%;
}

/* end range */
/* begin buy */
.buy__wrapper {
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 991.98px) {
  .buy__wrapper {
    align-items: center;
    flex-direction: column;
  }
}

.buy__form {
  width: 100%;
  margin-bottom: 50px;
}

.buy__form .form__row {
  width: 545px;
  max-width: 100%;
  margin: 0 auto;
}

.buy__nav {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 270px;
  margin-right: 30px;
}

@media (max-width: 991.98px) {
  .buy__nav {
    display: none;
  }
}

.buy__nav-item {
  transition: 0.3s;
}

.buy__nav-item--active .buy__nav-link {
  color: #fe900b;
  border-color: #fef3e6;
}

.buy__nav-item--active .buy__nav-link::before {
  background-color: #fedfba;
}

.buy__nav-item:hover .buy__nav-link {
  color: #fe900b;
}

.buy__nav-link {
  position: relative;
  display: flex;
  align-items: center;
  height: 60px;
  margin: 0 0 0 20px;
  font-size: 14px;
  font-weight: 300;
  color: #000000;
  border-bottom: 1px solid rgba(74, 74, 74, 0.1);
  transition: 0.3s;
}

.buy__nav-link::before {
  position: absolute;
  top: 50%;
  left: -20px;
  width: 2px;
  height: 50px;
  background-color: #4a4a4a;
  content: "";
  transform: translateY(-50%);
  transition: 0.3s;
}

.buy__list {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% - 300px);
}

@media (max-width: 991.98px) {
  .buy__list {
    display: inline-flex;
    width: 570px;
    margin: 0 auto;
  }
}

@media (max-width: 767.98px) {
  .buy__list {
    width: 100%;
  }
}

.buy__item {
  width: 270px;
  height: 250px;
  padding: 30px 35px;
  margin: 0 30px 30px 0;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 0 7px 0 rgba(74, 74, 74, 0.1);
}

@media (max-width: 767.98px) {
  .buy__item {
    width: 100%;
    height: 223px;
    margin-right: 0;
    text-align: center;
  }
}

.buy__item:nth-child(3n) {
  margin-right: 0;
}

@media (max-width: 1279.98px) {
  .buy__item:nth-child(3n) {
    margin-right: 30px;
  }
}

@media (max-width: 767.98px) {
  .buy__item:nth-child(3n) {
    margin-right: 0;
  }
}

@media (max-width: 1279.98px) {
  .buy__item:nth-child(2n) {
    margin-right: 0;
  }
}

.buy__logo {
  object-fit: contain;
  font-family: "object-fit: contain; object-position: center";
  object-position: center;
  width: 200px;
  height: 60px;
}

.buy__title {
  max-height: 80px;
  min-height: 40px;
  margin: 10px 0;
  line-height: 20px;
  color: #4a4a4a;
}

.buy__address {
  margin-bottom: 10px;
  font-size: 13px;
  font-weight: 300;
  line-height: 20px;
  color: #4a4a4a;
}

.buy__link {
  padding-bottom: 2px;
  font-size: 13px;
  font-weight: 300;
  color: #fe900b;
  border-bottom: 1px solid rgba(254, 144, 11, 0.1);
}

.buy__nav-mobile {
  position: relative;
  display: none;
  justify-content: center;
  width: 375px;
  height: 60px;
  margin-bottom: 20px;
  border-bottom: 1px solid #f4f4f4;
}

@media (max-width: 991.98px) {
  .buy__nav-mobile {
    display: inline-flex;
  }
}

@media (max-width: 767.98px) {
  .buy__nav-mobile {
    width: 100%;
  }
}

@media (max-width: 575.98px) {
  .buy__nav-mobile {
    width: calc(100% + 40px);
    margin: 0 -20px 20px;
  }
}

.buy__nav-mobile::before {
  position: absolute;
  top: 50%;
  left: 0;
  width: 2px;
  height: 30px;
  background-color: #4a4a4a;
  content: "";
  transform: translateY(-50%);
}

.buy__nav-mobile-select {
  width: 100%;
  padding: 0 20px;
  border: 0;
  outline: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}

.buy__nav-mobile-icon {
  position: absolute;
  top: 50%;
  right: 20px;
  width: 12px;
  height: 12px;
  transform: rotate(90deg);
  fill: #000000;
}

/* end buy */
/* begin accordion */
.accordion {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  overflow: hidden;
  border: solid 1px rgba(74, 74, 74, 0.05);
  border-radius: 15px;
}

.accordion:last-child {
  margin-bottom: 0;
}

.accordion--open .accordion__body {
  display: flex;
}

.accordion__header {
  display: flex;
  align-items: center;
  padding: 17px 20px;
  cursor: pointer;
  background-color: rgba(74, 74, 74, 0.05);
}

.accordion__header--open .accordion__icon {
  transform: rotate(270deg);
}

.accordion__title {
  margin-right: 10px;
  font-size: 14px;
  color: #000000;
  user-select: none;
}

.accordion__icon {
  flex-shrink: 0;
  width: 14px;
  height: 14px;
  margin-left: auto;
  transform: rotate(90deg);
  transition: 0.3s;
  fill: #4a4a4a;
}

.accordion__body {
  display: none;
  flex-direction: column;
  padding: 30px 20px;
}

/* end accordion */
/* begin handbook */
.handbook__header {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 70px;
  text-align: center;
}

@media (max-width: 575.98px) {
  .handbook__header {
    align-items: flex-start;
    margin-bottom: 50px;
    text-align: left;
  }
}

.handbook__header--inner {
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 39px;
}

@media (max-width: 575.98px) {
  .handbook__header--inner {
    align-items: center;
    margin-bottom: 58px;
  }
}

.handbook__header--inner .handbook__title {
  margin: 0;
  text-align: left;
}

.handbook__header-body {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.handbook__back {
  display: flex;
  align-items: center;
  margin: 7px 0 5px;
  transition: 0.3s;
}

.handbook__back:hover {
  opacity: 0.75;
}

@media (max-width: 1279.98px) {
  .handbook__back:hover {
    opacity: 1;
  }
}

.handbook__back-icon {
  width: 10px;
  height: 10px;
  margin-right: 10px;
  transform: rotate(-90deg);
  fill: #fe900b;
}

.handbook__back-text {
  font-size: 14px;
  color: #fe900b;
}

.handbook__title {
  margin-bottom: 10px;
}

.handbook__subtitle {
  font-size: 14px;
  color: #4a4a4a;
}

@media (max-width: 575.98px) {
  .handbook__subtitle {
    display: none;
  }
}

.handbook__list {
  display: flex;
  flex-wrap: wrap;
  width: 870px;
  max-width: 100%;
  margin: 0 auto;
}

.handbook__item {
  width: 270px;
  height: 200px;
  margin: 0 30px 30px 0;
}

@media (max-width: 991.98px) {
  .handbook__item {
    width: 216px;
  }
}

@media (max-width: 767.98px) {
  .handbook__item {
    width: 243px;
  }
}

@media (max-width: 575.98px) {
  .handbook__item {
    width: 100%;
    height: auto;
    margin: 0 0 40px;
  }
}

.handbook__item:nth-child(3n) {
  margin-right: 0;
}

@media (max-width: 767.98px) {
  .handbook__item:nth-child(3n) {
    margin-right: 30px;
  }
}

@media (max-width: 575.98px) {
  .handbook__item:nth-child(3n) {
    margin-right: 0;
  }
}

@media (max-width: 767.98px) {
  .handbook__item:nth-child(2n) {
    margin-right: 0;
  }
}

.handbook__item--inner {
  width: 270px;
  height: 240px;
  background-color: #ffffff;
  box-shadow: 0 0 7px 0 rgba(74, 74, 74, 0.1);
}

@media (max-width: 575.98px) {
  .handbook__item--inner {
    width: calc(100% + 40px);
    margin: 0 -20px !important;
    border-bottom: 1px solid #f4f4f4;
    box-shadow: none;
  }
}

@media (max-width: 575.98px) {
  .handbook__item--inner:last-child {
    border: 0;
  }
}

.handbook__item--inner .handbook__link {
  padding: 20px 20px 30px;
}

@media (max-width: 575.98px) {
  .handbook__item--inner .handbook__link {
    position: relative;
    align-items: flex-start;
    padding: 20px;
  }
}

.handbook__item--inner .handbook__item-title {
  font-size: 14px;
  line-height: 20px;
  color: #4a4a4a;
  text-align: left;
}

@media (max-width: 575.98px) {
  .handbook__item--inner .handbook__item-title {
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    color: #000000;
  }
}

.handbook__link {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 38px 60px 0;
}

@media (max-width: 575.98px) {
  .handbook__link {
    flex-direction: row;
    padding: 0 11px;
  }
}

.handbook__link:hover .handbook__item-title {
  color: #fe900b;
}

@media (max-width: 1279.98px) {
  .handbook__link:hover .handbook__item-title {
    color: #4a4a4a;
  }
}

.handbook__icon {
  width: 65px;
  height: 65px;
  margin: 0 0 18px;
}

@media (max-width: 575.98px) {
  .handbook__icon {
    margin: 0 32px 0 0;
  }
}

.handbook__icon--header {
  width: 73px;
  height: 73px;
  margin: 0 50px 0 0;
}

.handbook__item-title {
  font-size: 14px;
  line-height: 20px;
  color: #4a4a4a;
  text-align: center;
  transition: 0.3s;
}

@media (max-width: 575.98px) {
  .handbook__item-title {
    line-height: 16px;
    text-align: left;
  }
}

.handbook__item-arrow {
  position: absolute;
  top: 50%;
  right: 20px;
  display: none;
  flex-shrink: 0;
  width: 12px;
  height: 12px;
  transform: translateY(-50%);
}

@media (max-width: 575.98px) {
  .handbook__item-arrow {
    display: inline-flex;
  }
}

.handbook__img {
  object-fit: contain;
  font-family: "object-fit: contain; object-position: center";
  object-position: center;
  width: 100%;
  height: 130px;
  margin-bottom: 20px;
}

@media (max-width: 575.98px) {
  .handbook__img {
    width: 100px;
    height: 100px;
    margin: 0 10px 0 0;
  }
}

/* end handbook */
/* begin firmware */
.firmware__wrapper {
  width: 680px;
  max-width: 100%;
  margin: 0 auto;
}

.firmware__title {
  margin-bottom: 50px;
  text-align: center;
}

@media (max-width: 767.98px) {
  .firmware__title {
    margin-bottom: 23px;
  }
}

.firmware__desc {
  margin-bottom: 50px;
  font-size: 14px;
  line-height: 20px;
  color: #4a4a4a;
}

@media (max-width: 767.98px) {
  .firmware__desc {
    text-align: center;
  }
}

.firmware__selects {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 30px;
}

@media (max-width: 767.98px) {
  .firmware__selects {
    flex-direction: column;
  }
}

.firmware__select {
  width: calc(50% - 20px);
  margin-bottom: 20px;
}

@media (max-width: 767.98px) {
  .firmware__select {
    width: 100%;
  }
}

.firmware__list {
  display: flex;
  flex-direction: column;
}

.firmware__item {
  margin-bottom: 20px;
}

.firmware__link {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 80px;
  padding: 15px 20px 15px 30px;
  overflow: hidden;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 0 7px 0 rgba(74, 74, 74, 0.1);
  transition: 0.3s;
}

@media (max-width: 767.98px) {
  .firmware__link {
    flex-wrap: wrap;
    min-height: 0;
    padding: 30px 20px 30px 30px;
  }
}

.firmware__link::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 3px;
  height: 100%;
  background-color: #456990;
  content: "";
}

.firmware__link:hover {
  background-color: rgba(254, 144, 11, 0.1);
}

.firmware__item-type {
  margin-right: 20px;
  font-size: 14px;
  color: #4a4a4a;
}

@media (max-width: 767.98px) {
  .firmware__item-type {
    order: 1;
  }
}

.firmware__item-changelog {
  margin-right: 30px;
  overflow: hidden;
  font-size: 13px;
  font-weight: 300;
  line-height: 20px;
  color: #4a4a4a;
  text-align: center;
}

@media (max-width: 767.98px) {
  .firmware__item-changelog {
    margin-top: 20px;
    margin-right: 0;
    text-align: left;
  }
}

.firmware__item-series {
  margin-right: 30px;
  font-size: 13px;
  font-weight: 300;
  line-height: 20px;
  color: #4a4a4a;
  text-align: center;
}

@media (max-width: 767.98px) {
  .firmware__item-series {
    margin-top: 20px;
    margin-right: 0;
    text-align: left;
  }
}

.firmware__item-model {
  font-size: 13px;
  line-height: 20px;
  color: #fe900b;
  text-align: center;
}

@media (max-width: 767.98px) {
  .firmware__item-model {
    text-align: right;
  }
}

.firmware__item-col {
  margin-left: auto;
}

.firmware__item-col:first-child {
  margin-left: 0;
}

.firmware__item-col--type {
  flex-shrink: 0;
  width: 101px;
}

@media (max-width: 767.98px) {
  .firmware__item-col--type {
    order: 1;
    width: auto;
  }
}

.firmware__item-col--changelog {
  flex-shrink: 0;
  width: 218px;
}

@media (max-width: 767.98px) {
  .firmware__item-col--changelog {
    order: 3;
    width: 100%;
  }
}

.firmware__item-col--series {
  flex-shrink: 0;
  width: 210px;
}

@media (max-width: 767.98px) {
  .firmware__item-col--series {
    order: 4;
    width: 100%;
  }
}

@media (max-width: 767.98px) {
  .firmware__item-col--model {
    order: 2;
  }
}

/* end firmware */
/* begin services */
.services__title {
  text-align: center;
}

.services__form {
  width: 545px;
  max-width: 100%;
  margin: 50px auto;
}

.services__list {
  display: flex;
  flex-wrap: wrap;
}

.services__item {
  width: 25%;
  padding: 20px;
  cursor: pointer;
  transition: 0.3s;
}

@media (max-width: 1279.98px) {
  .services__item {
    width: 33.33%;
  }
}

@media (max-width: 991.98px) {
  .services__item {
    width: 50%;
  }
}

@media (max-width: 767.98px) {
  .services__item {
    width: 100%;
    padding: 20px 0;
    border-bottom: 1px solid #f4f4f4;
  }
}

.services__item:hover {
  background-color: rgba(254, 144, 11, 0.05);
  box-shadow: 0 2px 4px 0 rgba(74, 74, 74, 0.1);
}

@media (max-width: 1279.98px) {
  .services__item:hover {
    background-color: #ffffff;
    box-shadow: none;
  }
}

@media (max-width: 767.98px) {
  .services__item:first-child {
    padding-top: 0;
  }
}

@media (max-width: 767.98px) {
  .services__item:last-child {
    border-bottom: 0;
  }
}

.services__item-title {
  margin-bottom: 20px;
  font-size: 16px;
  color: #000000;
}

.services__data-list-key {
  display: inline;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #000000;
}

.services__data-list-value {
  display: inline;
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  color: #000000;
}

.services__link {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #fe900b;
}

.services__map {
  height: 552px;
  margin-top: 30px;
}

@media (max-width: 767.98px) {
  .services__map {
    display: none;
  }
}

/* end services */
/* begin reviews */
.reviews--news .reviews__item {
  padding: 20px;
}

.reviews--news .reviews__date {
  font-size: 14px;
  color: rgba(74, 74, 74, 0.3);
}

.reviews--news .reviews__text {
  height: 60px;
  margin: 10px 0 0;
  color: rgba(74, 74, 74, 0.3);
}

.reviews__list {
  display: flex;
  flex-wrap: wrap;
}

.reviews__item {
  width: calc(33.33% - 10px);
  padding: 25px 20px;
  margin: 0 15px 15px 0;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 0 7px 0 rgba(74, 74, 74, 0.1);
  transition: 0.3s;
}

@media (max-width: 991.98px) {
  .reviews__item {
    width: calc(50% - 7.5px);
  }
}

@media (max-width: 767.98px) {
  .reviews__item {
    width: 100%;
    margin-right: 0;
  }
}

.reviews__item:hover {
  box-shadow: 0 0 10px 0 rgba(74, 74, 74, 0.2);
}

.reviews__item:nth-child(3n) {
  margin-right: 0;
}

@media (max-width: 991.98px) {
  .reviews__item:nth-child(3n) {
    margin-right: 15px;
  }
}

@media (max-width: 767.98px) {
  .reviews__item:nth-child(3n) {
    margin-right: 0;
  }
}

@media (max-width: 991.98px) {
  .reviews__item:nth-child(2n) {
    margin-right: 0;
  }
}

.reviews__header {
  display: flex;
  margin-bottom: 20px;
  font-size: 13px;
  line-height: 14px;
  color: #c8c8c8;
}

.reviews__section {
  position: relative;
  margin-right: 22px;
}

.reviews__section::after {
  position: absolute;
  top: 0;
  right: -12px;
  width: 1px;
  height: 100%;
  background: #c8c8c8;
  content: "";
}

.reviews__link:hover .reviews__title {
  color: #fe900b;
}

.reviews__title {
  height: 40px;
  line-height: 20px;
  color: #4a4a4a;
}

.reviews__text {
  height: 100px;
  margin-top: 20px;
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  color: #4a4a4a;
}

.reviews__select {
  display: none;
  margin-bottom: 30px;
}

@media (max-width: 767.98px) {
  .reviews__select {
    display: block;
  }
}

.reviews__select .ss-single-selected {
  height: 50px;
  color: #4a4a4a;
  border: 1px solid #d7dae3;
  border-radius: 6px;
}

.reviews__select .ss-single-selected .ss-arrow span {
  border-color: #4a4a4a;
}

.reviews__select .ss-content {
  border: 1px solid #d7dae3;
}

.reviews__select .ss-content .ss-option {
  height: 50px;
}

.reviews__body {
  display: flex;
  margin-top: 20px;
}

.reviews__img {
  object-fit: cover;
  font-family: "object-fit: cover; object-position: center";
  object-position: center;
  width: 100px;
  height: 100px;
  margin-right: 20px;
  border-radius: 5px;
}

.reviews__slider-container {
  max-width: 460px;
  margin: 100px auto;
}

@media (max-width: 575.98px) {
  .reviews__slider-container {
    max-width: 100%;
    margin: 50px auto;
  }
}

.reviews__slider, .reviews__slider-thumbs {
  width: 100%;
}

.reviews__slider-thumbs {
  height: 100px;
  margin-top: 30px;
}

@media (max-width: 575.98px) {
  .reviews__slider-thumbs {
    height: calc((100vw - 40px - 15px) / 4);
  }
}

.reviews__slider-thumbs .swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  cursor: pointer;
  border: 2px solid rgba(254, 144, 11, 0.1);
  border-radius: 20px;
  transition: 0.3s;
}

.reviews__slider-thumbs .swiper-slide.swiper-slide-thumb-active {
  pointer-events: none;
  border-color: #fe900b;
}

.reviews__slider-img {
  object-fit: contain;
  font-family: "object-fit: contain; object-position: center";
  object-position: center;
  margin: 0 !important;
}

.reviews__slider-img--thumb {
  width: 100% !important;
  height: 100% !important;
  max-width: 100% !important;
  max-height: 100% !important;
}

/* end reviews */
/* begin materials */
.materials__title-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 50px;
  text-align: center;
}

.materials__subtitle {
  margin-top: 20px;
  font-size: 14px;
  color: rgba(74, 74, 74, 0.5);
}

.materials__list {
  display: flex;
  flex-wrap: wrap;
}

.materials__item {
  display: flex;
  flex-direction: column;
  width: calc(16.66% - 15px);
  padding: 20px;
  margin: 0 18px 20px 0;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 0 7px 0 rgba(74, 74, 74, 0.1);
  transition: 0.3s box-shadow;
}

@media (max-width: 1279.98px) {
  .materials__item {
    width: calc(20% - 14.4px);
  }
}

@media (max-width: 991.98px) {
  .materials__item {
    width: calc(33.33% - 12px);
  }
}

@media (max-width: 767.98px) {
  .materials__item {
    width: calc(50% - 9px);
    padding: 17px 10px 20px;
  }
}

.materials__item:hover {
  box-shadow: 0 0 10px 0 rgba(74, 74, 74, 0.2);
}

@media (max-width: 1279.98px) {
  .materials__item:hover {
    box-shadow: 0 0 7px 0 rgba(74, 74, 74, 0.1);
  }
}

.materials__item:nth-child(6n) {
  margin-right: 0;
}

@media (max-width: 1279.98px) {
  .materials__item:nth-child(6n) {
    margin-right: 18px;
  }
}

@media (max-width: 1279.98px) {
  .materials__item:nth-child(5n) {
    margin-right: 0;
  }
}

@media (max-width: 991.98px) {
  .materials__item:nth-child(5n) {
    margin-right: 18px;
  }
}

@media (max-width: 991.98px) {
  .materials__item:nth-child(3n) {
    margin-right: 0;
  }
}

@media (max-width: 767.98px) {
  .materials__item:nth-child(3n) {
    margin-right: 18px;
  }
}

@media (max-width: 767.98px) {
  .materials__item:nth-child(2n) {
    margin-right: 0;
  }
}

.materials__img {
  object-fit: contain;
  font-family: "object-fit: contain; object-position: center";
  object-position: center;
  width: auto;
  height: 109px;
  max-width: 100%;
}

@media (max-width: 767.98px) {
  .materials__img {
    height: 117px;
    max-width: calc(100% + 20px);
    margin: 0 -10px;
  }
}

.materials__res-list {
  margin-top: 30px;
}

.materials__res-item {
  display: flex;
  margin-bottom: 5px;
}

.materials__res-item:last-child {
  margin-bottom: 0;
}

.materials__res {
  margin-right: 10px;
  font-size: 14px;
  line-height: 1.43;
  color: #4a4a4a;
}

.materials__res-link {
  font-size: 14px;
  line-height: 1.43;
  color: #fe900b;
  transition: 0.3s;
}

.materials__res-link:hover {
  color: #fe971a;
}

@media (max-width: 1279.98px) {
  .materials__res-link:hover {
    color: #fe900b;
  }
}

.materials__link {
  margin-top: 20px;
  font-size: 14px;
  line-height: 1.43;
  color: #4a4a4a;
  transition: 0.3s;
}

.materials__link:hover {
  color: #fe900b;
}

@media (max-width: 1279.98px) {
  .materials__link:hover {
    color: #4a4a4a;
  }
}

/* end materials */
/* begin contacts */
.contacts__wrapper {
  display: flex;
}

@media (max-width: 991.98px) {
  .contacts__wrapper {
    flex-direction: column;
  }
}

.contacts__map {
  flex-shrink: 0;
  width: 545px;
  height: 1033px;
  margin-right: 40px;
}

@media (max-width: 991.98px) {
  .contacts__map {
    width: 100%;
    height: 300px;
    margin-bottom: 50px;
  }
}

@media (max-width: 575.98px) {
  .contacts__map {
    width: calc(100% + 40px);
    margin: 0 -20px 50px;
  }
}

.contacts__text-block h1 {
  margin-bottom: 30px;
  text-align: left;
}

.contacts__text-block h5 {
  color: #fe900b;
}

.contacts__button {
  min-width: 249px;
}

/* end contacts */
