/* begin footer */
.footer {
  background-color: $whisper;

  &--mini {
    @include md {
      .footer {
        &__row {
          &--bottom {
            padding: 20px 0 30px;
          }
        }

        &__col {
          &--mini-phone {
            order: -1;
            margin-bottom: 20px;
          }
        }

        &__copyright {
          font-size: 14px;
          font-weight: 300;
          color: $grayDusty;
          text-align: left;
        }
      }
    }
  }

  &__row {
    display: flex;

    @include md {
      flex-direction: column;
    }

    &--top {
      padding: 50px 0 40px;
      border-bottom: 1px solid $botticelli;

      @include md {
        padding: 0;
        border: 0;
      }
    }

    &--bottom {
      padding: 32px 0;

      @include md {
        padding: 30px 0;
      }
    }
  }

  &__col {
    display: flex;
    flex-direction: column;
    width: 182px;
    margin-right: 66px;

    @include lg {
      width: 171px;
      margin-right: 20px;
    }

    @include md {
      width: 100%;
      margin-right: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    &--bottom-links {
      flex-direction: row;
      width: auto;
      margin-left: auto;

      @include lg {
        width: auto;
      }

      @include md {
        display: none;
      }
    }

    &--copyright {
      width: auto;
      margin-right: 0;

      @include lg {
        width: auto;
      }
    }

    &--mini-phone {
      width: auto;
      margin: 0 0 0 auto;
    }
  }

  &__col-title {
    margin-bottom: 26px;
    color: $orange;

    @include md {
      display: flex;
      align-items: center;
      height: 60px;
      margin: 0;
      font-size: 14px;
      font-weight: 300;
      color: $charcoal;
      cursor: pointer;
      border-bottom: 1px solid $mystic;
    }

    &--open {
      .footer__col-title-icon {
        transform: rotate(-90deg);
      }
    }
  }

  &__col-title-text {
    @include md {
      width: 100%;
    }
  }

  &__col-title-icon {
    display: none;
    flex-shrink: 0;
    width: 12px;
    height: 12px;
    transition: 0.5s;
    fill: $black;

    @include md {
      display: inline-flex;
    }
  }

  &__list {
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    @include md {
      display: none;
      padding: 28px 0;
      border-bottom: 1px solid $mystic;
    }
  }

  &__link {
    margin-bottom: 11px;
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    color: $charcoal;
    transition: $transition;

    @include md {
      margin-bottom: 16px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      color: $orange;

      @include lg {
        color: $charcoal;
      }
    }
  }

  &__phone {
    font-size: 16px;
    color: $charcoal;
    transition: $transition;

    &:hover {
      color: $orange;

      @include lg {
        color: $charcoal;
      }
    }

    @include md {
      margin-top: 20px;
    }
  }

  &__address {
    margin-top: 50px;
    font-size: 14px;
    line-height: 20px;
    color: $charcoal;
  }

  &__feedback {
    margin-top: 20px;
    font-size: 14px;
    color: $orange;
  }

  &__social {
    display: flex;
    margin-top: 50px;

    @include md {
      justify-content: center;
      margin-top: 30px;
    }
  }

  &__social-link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    margin: 0 10px 0 0;
    background-color: $grayPeriwinkle;
    border-radius: 50%;
    transition: $transition;

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      background-color: #f2c8ce;

      .footer {
        &__social-icon {
          fill: #ff3d00;
        }
      }
    }
  }

  &__social-icon {
    width: 14px;
    height: 14px;
    fill: #4669ae;
    transition: $transition;
  }

  &__copyright {
    font-size: 14px;
    color: $grayDusty;
    opacity: 0.97;

    @include md {
      font-size: 13px;
      font-weight: 300;
      color: $charcoal;
      text-align: center;
      opacity: 1;
    }
  }

  &__bottom-link {
    padding-bottom: 3px;
    margin-right: 30px;
    font-size: 14px;
    color: $grayDusty;
    border-bottom: 1px solid rgba($grayDusty, 0.1);
    opacity: 0.97;
    transition: $transition;

    &:hover {
      border-color: rgba($grayDusty, 0);
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &__newsletter {
    display: none;
    flex-direction: column;
    margin-top: 20px;

    @include md {
      display: flex;
    }
  }

  &__newsletter-form {
    position: relative;
    display: flex;
    height: 100%;
    padding: 8px 9px 10px 0;
    margin-bottom: 10px;

    .form {
      &__row {
        position: relative;
        width: 100%;
      }
    }

    .form__row {
      transition: $transition;
      transition-delay: $transition;
    }

    &--success {
      .form__row {
        opacity: 0;
        transition-delay: 0s;
      }

      .footer__newsletter-success {
        opacity: 1;
        transition-delay: $transition;
      }
    }
  }

  &__newsletter-input {
    width: 100%;
    height: 57px;
    padding: 0 170px 0 20px;
    font-size: 16px;
    color: $charcoal;
    background-color: $white;
    border: 0;
    border-radius: 20px;
    outline: 0;

    &::placeholder {
      color: $greyWarm;
    }
  }

  &__newsletter-button {
    position: absolute;
    top: 8px;
    right: 10px;
    flex-shrink: 0;
    height: 40px;
    min-width: 150px;
    font-size: 14px;
    color: $grey;
    border-radius: 20px;
  }

  &__newsletter-text {
    margin: 0 0 22px;
    font-size: 13px;
    font-weight: 300;
    line-height: 20px;
    color: $charcoal;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__newsletter-success {
    position: absolute;
    top: 20px;
    left: 0;
    font-size: 13px;
    font-weight: 300;
    line-height: 18px;
    color: #4a4a4a;
    opacity: 0;
    transition: $transition;
    transition-delay: 0s;
  }

  &__mini-phone {
    font-size: 14px;
    color: $black;
    transition: $transition;

    &:hover {
      color: $orange;

      @include lg {
        color: $black;
      }
    }
  }

  &__support-button {
    width: 100%;
    height: 40px;
    padding: 0 10px;
    margin-top: 20px;
    font-size: 14px;
    border-radius: 20px;
  }
}
/* end footer */
