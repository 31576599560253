/* begin catalog */
.catalog {
  &__wrapper {
    display: flex;
    flex-wrap: wrap;
  }

  &__header {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin-bottom: 70px;

    @include xs {
      align-items: flex-start;
      margin-bottom: 50px;
    }
  }

  &__header-img {
    width: 67px;
    height: 67px;
    margin-bottom: 20px;

    @include xs {
      display: none;
    }
  }

  &__title {
    line-height: 30px;
    color: $black;
    text-align: center;

    @include xs {
      text-align: left;
    }
  }

  &__filters-toggle {
    display: none;
    width: 335px;
    max-width: 100%;
    margin-top: 30px;
    text-transform: uppercase;
    border-radius: 20px;

    @include lg {
      display: inline-flex;
    }

    @include xs {
      width: 100%;
    }
  }

  &__filters {
    width: 270px;
    margin-right: 30px;
    transition: 0s;

    @include lg {
      position: fixed;
      top: 0;
      left: -310px;
      z-index: 10;
      width: 310px;
      height: 100%;
      padding: 40px 20px;
      margin-right: 0;
      overflow: hidden;
      box-shadow: 0 0 7px 0 rgba(74, 74, 74, 0.1);
      transition: 0.5s !important;
    }
  }

  &__filter {
    margin-bottom: 20px;

    &--no-title {
      .catalog__filter-list {
        padding-top: 0;
      }
    }
  }

  &__filter-toggle {
    display: flex;
    align-items: center;
    min-height: 40px;
    cursor: pointer;

    &--open {
      .catalog__filter-toggle-icon {
        transform: rotate(-90deg);
      }
    }
  }

  &__filter-title {
    width: 100%;
    margin-right: 20px;
    font-size: 16px;
    line-height: 20px;
    color: $black;
  }

  &__filter-toggle-icon {
    flex-shrink: 0;
    width: 10px;
    height: 10px;
    transform: rotate(90deg);
    transition: $transition;
    fill: $grey;
  }

  &__filter-list {
    padding: 28px 0;
  }

  &__filter-item {
    margin-bottom: 18px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__filters-button {
    width: 100%;
  }

  &__body {
    width: calc(100% - 300px);

    @include lg {
      width: 100%;
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: -30px;

    @include lg {
      width: 870px;
      margin: 0 auto -30px;
    }

    @include md {
      width: 570px;
      margin: 0 auto -30px;
    }

    @include sm {
      width: 100%;
      margin: 0 auto -12px;
    }

    @include xs {
      width: calc(100% + 40px);
      margin: 0 -20px -12px;
    }
  }

  &__product {
    width: 270px;
    margin: 0 30px 30px 0;

    @include sm {
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
      padding: 46px 20px 12px;
      margin: 0 0 12px;
      border: 0;
      border-bottom: 1px solid $whiteTwo;
      border-radius: 0;

      .product {
        &__badges {
          top: 8px;
          left: 21px;
        }

        &__compare {
          top: 10px;
        }

        &__img-link {
          width: 100px;
          height: 100px;
        }

        &__img {
          width: 100px;
          height: 100px;
        }

        &__title {
          width: calc(100% - 110px);
          height: 72px;
          margin: 0 0 0 10px;
          line-height: 24px;
        }

        &__footer {
          position: relative;
          width: 100%;
          padding-left: 108px;
          margin-top: -7px;
        }

        &__price {
          width: 100%;
          height: 50px;
          padding-right: 30px;
          margin-right: 0;
        }

        &__price-profit {
          bottom: 8px;
        }

        &__button {
          display: none;
          flex-shrink: 0;

          &--mobile-arrow {
            position: absolute;
            top: -6px;
            right: -20px;
            display: inline-flex;
            border: 0;

            .product__button-icon {
              width: 12px;
              height: 12px;
              fill: $black;
            }
          }
        }
      }
    }

    &:nth-child(3n) {
      margin-right: 0;

      @include md {
        margin-right: 30px;
      }

      @include sm {
        margin-right: 0;
      }
    }

    &:nth-child(2n) {
      @include md {
        margin-right: 0;
      }
    }

    .product {
      &__button {
        &--mobile-arrow {
          display: none;
        }
      }
    }
  }

  &__price {
    padding: 0 0 9px;
    margin: 10px 0 0;
  }

  &__price-inputs {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  &__price-input {
    width: 110px;
    height: 50px;
    font-size: 16px;
    font-weight: 300;
    color: $grey;
    text-align: center;
    border: 1px solid $greyPinkish;
    border-radius: 4px;
    outline: none;
  }

  &__price-inputs-sep {
    width: 10px;
    height: 2px;
    background-color: $greyPinkish;
    border-radius: 1px;
  }
}
/* end catalog */
