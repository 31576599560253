/* begin checkout-success */
.checkout-success {
  display: flex;
  align-items: center;
  flex-grow: 1;
  justify-content: center;

  &__wrapper {
    width: 580px;
    max-width: 100%;
    padding: 20px;
  }

  &__title {
    margin-bottom: 30px;
    text-align: center;
  }

  &__info {
    font-size: 16px;
    line-height: 25px;
    color: $black;
    text-align: center;

    @include xs {
      font-size: 14px;
      font-weight: 300;
      line-height: 20px;
      color: $charcoal;
    }
  }

  &__leader-list {
    width: 100%;
    margin-top: 105px;

    @include xs {
      margin-top: 100px;
    }

    .leader-list__col {
      &:first-child {
        font-size: 16px;
        font-weight: 400;
        color: $black;
      }

      &:last-child {
        width: auto;
        font-size: 22px;
        font-weight: 500;
        color: $black;
      }
    }
  }

  &__footer {
    display: flex;
    margin-top: 50px;

    @include xs {
      flex-direction: column;
      margin-top: 35px;
    }
  }

  &__disclaimer {
    margin-right: 30px;
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    color: $charcoal;

    @include xs {
      order: 1;
      margin-top: 20px;
      text-align: center;
    }
  }

  &__button {
    height: 60px;
    min-width: 151px;
  }
}
/* end checkout-success */
