/* begin reviews */
.reviews {
  &--news {
    .reviews {
      &__item {
        padding: 20px;
      }

      &__date {
        font-size: 14px;
        color: rgba($charcoal, 0.3);
      }

      &__text {
        height: 60px;
        margin: 10px 0 0;
        color: rgba($charcoal, 0.3);
      }
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    width: calc(33.33% - 10px);
    padding: 25px 20px;
    margin: 0 15px 15px 0;
    background-color: $white;
    border-radius: 5px;
    box-shadow: 0 0 7px 0 rgba(74, 74, 74, 0.1);
    transition: $transition;

    @include md {
      width: calc(50% - 7.5px);
    }

    @include sm {
      width: 100%;
      margin-right: 0;
    }

    &:hover {
      box-shadow: 0 0 10px 0 rgba(74, 74, 74, 0.2);
    }

    &:nth-child(3n) {
      margin-right: 0;

      @include md {
        margin-right: 15px;
      }

      @include sm {
        margin-right: 0;
      }
    }

    &:nth-child(2n) {
      @include md {
        margin-right: 0;
      }
    }
  }

  &__header {
    display: flex;
    margin-bottom: 20px;
    font-size: 13px;
    line-height: 14px;
    color: $pinkishGrey;
  }

  &__section {
    position: relative;
    margin-right: 22px;

    &::after {
      position: absolute;
      top: 0;
      right: -12px;
      width: 1px;
      height: 100%;
      background: $pinkishGrey;
      content: "";
    }
  }

  &__link {
    &:hover {
      .reviews {
        &__title {
          color: $orange;
        }
      }
    }
  }

  &__title {
    height: 40px;
    line-height: 20px;
    color: $charcoal;
  }

  &__text {
    height: 100px;
    margin-top: 20px;
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    color: $charcoal;
  }

  &__select {
    display: none;
    margin-bottom: 30px;

    @include sm {
      display: block;
    }

    .ss-single-selected {
      height: 50px;
      color: $charcoal;
      border: 1px solid #d7dae3;
      border-radius: 6px;

      .ss-arrow {
        span {
          border-color: $charcoal;
        }
      }
    }

    .ss-content {
      border: 1px solid #d7dae3;

      .ss-option {
        height: 50px;
      }
    }
  }

  &__body {
    display: flex;
    margin-top: 20px;
  }

  &__img {
    @include objectFit(cover, center);
    width: 100px;
    height: 100px;
    margin-right: 20px;
    border-radius: 5px;
  }

  &__slider-container {
    max-width: 460px;
    margin: 100px auto;

    @include xs {
      max-width: 100%;
      margin: 50px auto;
    }
  }

  &__slider,
  &__slider-thumbs {
    width: 100%;
  }

  &__slider-thumbs {
    height: 100px;
    margin-top: 30px;

    @include xs {
      height: calc((100vw - 40px - 15px) / 4);
    }

    .swiper-slide {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px;
      cursor: pointer;
      border: 2px solid rgba($orange, 0.1);
      border-radius: 20px;
      transition: $transition;

      &.swiper-slide-thumb-active {
        pointer-events: none;
        border-color: $orange;
      }
    }
  }

  &__slider-img {
    @include objectFit(contain, center);
    margin: 0 !important;

    &--thumb {
      width: 100% !important;
      height: 100% !important;
      max-width: 100% !important;
      max-height: 100% !important;
    }
  }
}
/* end reviews */
