/* begin support-nav */
.support-nav {
  margin: 0 0 50px;

  &--reviews {
    @include sm {
      display: none;
    }

    .support-nav {
      &__slider {
        position: relative;
        padding: 0 20px 2px;

        &::after {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 2px;
          background-color: rgba($charcoal, 0.1);
          content: "";
        }
      }

      &__list {
        justify-content: flex-start;
        border: 0;
      }

      &__item {
        position: relative;
        width: auto;
        margin: 0 30px 0 0;
      }
    }
  }

  &__list {
    display: flex;
    justify-content: center;
    border-bottom: 2px solid rgba($charcoal, 0.1);

    @include sm {
      flex-direction: column;
      border: 0;
    }

    @include xs {
      margin: 0 -20px;
    }
  }

  &__item {
    margin-right: 50px;

    @include md {
      margin-right: 19px;
    }

    @include sm {
      margin: 0;
    }

    &:last-child {
      margin-right: 0;

      @include sm {
        .support-nav__link {
          border: 0;
        }
      }
    }

    &--active {
      .support-nav {
        &__link {
          pointer-events: none;

          &::after {
            opacity: 1;
          }
        }

        &__icon {
          @include sm {
            display: none;
          }
        }
      }
    }
  }

  &__link {
    position: relative;
    display: inline-flex;
    align-items: center;
    padding: 20px 0;

    @include sm {
      width: 100%;
      padding: 21.5px 20px;
      border-bottom: 1px solid $whiteTwo;
    }

    &::after {
      position: absolute;
      bottom: -2px;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: $charcoal;
      content: "";
      opacity: 0;
      transition: $transition opacity;

      @include sm {
        top: 50%;
        width: 2px;
        height: 30px;
        transform: translateY(-50%);
      }
    }

    &:hover {
      &::after {
        opacity: 1;

        @include lg {
          opacity: 0;
        }
      }
    }
  }

  &__text {
    font-size: 14px;
    font-weight: 400;
    color: $charcoal;
  }

  &__icon {
    display: none;
    width: 12px;
    height: 12px;
    margin-left: auto;
    fill: $black;

    @include sm {
      display: inline-flex;
    }
  }

  &__button {
    position: relative;
    left: 50%;
    margin: 0 0 30px;
    transform: translateX(-50%);
  }
}
/* end support-nav */
