/* begin accordion */
.accordion {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  overflow: hidden;
  border: solid 1px rgba($charcoal, 0.05);
  border-radius: 15px;

  &:last-child {
    margin-bottom: 0;
  }

  &--open {
    .accordion {
      &__body {
        display: flex;
      }
    }
  }

  &__header {
    display: flex;
    align-items: center;
    padding: 17px 20px;
    cursor: pointer;
    background-color: rgba($charcoal, 0.05);

    &--open {
      .accordion {
        &__icon {
          transform: rotate(270deg);
        }
      }
    }
  }

  &__title {
    margin-right: 10px;
    font-size: 14px;
    color: $black;
    user-select: none;
  }

  &__icon {
    flex-shrink: 0;
    width: 14px;
    height: 14px;
    margin-left: auto;
    transform: rotate(90deg);
    transition: $transition;
    fill: $charcoal;
  }

  &__body {
    display: none;
    flex-direction: column;
    padding: 30px 20px;
  }
}
/* end accordion */
