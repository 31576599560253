/* begin rating */
.rating {
  display: flex;

  &--static {
    .rating {
      &__item {
        pointer-events: none;
      }
    }
  }

  &__item {
    display: inline-flex;
    margin-right: 3px;
    cursor: pointer;

    &:last-of-type {
      margin-right: 0;
    }

    &--active {
      .rating__icon {
        fill: $orange;
      }
    }
  }

  &__input {
    display: none;
  }

  &__icon {
    width: 15px;
    height: 15px;
    transition: $transition;
    fill: $greyPinkish;
  }

  &__text {
    margin-left: 13px;
    font-size: 16px;
    line-height: 16px;
    color: $charcoal;
  }
}
/* end rating */
