/* begin header */
.header {
  @include lg {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 8;
    width: 100%;
    background-color: $white;
    box-shadow: 0 0 7px 0 rgba(74, 74, 74, 0.1);
    transition: 0.5s left;
  }

  &--push-open {
    @include lg {
      left: 310px;
    }
  }

  &--search-open {
    @include lg {
      z-index: 99;
    }
  }

  &--mini {
    padding: 32px 0;

    @include lg {
      padding: 0;
    }

    .header {
      &__buttons {
        display: none;

        @include lg {
          display: flex;
        }
      }

      &__body {
        margin: 0;
      }

      &__mini-title {
        @include lg {
          display: none;
        }
      }

      &__mini-phone {
        @include lg {
          display: none;
        }
      }
    }
  }

  &__top {
    display: flex;
    padding: 13px 0;
    margin: 0 0 23px;

    @include lg {
      display: none;
    }
  }

  &__top-left-side {
    display: flex;
    align-items: center;
  }

  &__city {
    margin-right: 50px;
  }

  &__city-button {
    position: relative;
    top: 2px;
    display: inline-flex;
    align-items: center;
    cursor: pointer;

    &:hover {
      .header {
        &__city-title {
          color: $orange;
        }

        &__city-icon {
          fill: $orange;
        }
      }
    }
  }

  &__city-icon {
    width: 22px;
    height: 22px;
    margin-right: 7px;
    transition: $transition;
    fill: $grey;
  }

  &__city-title {
    font-size: 16px;
    font-weight: 400;
    color: $grey;
    transition: $transition;
  }

  &__top-nav {
    display: flex;
  }

  &__top-nav-link {
    margin-right: 21px;
    font-size: 16px;
    font-weight: 300;
    color: $grey;
    transition: $transition;

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      color: $orange;
    }
  }

  &__top-right-side {
    display: flex;
    align-items: center;
    margin-left: auto;
  }

  &__top-phone {
    margin-left: 53px;
    font-size: 16px;
    font-weight: 400;
    color: $black;
    transition: $transition;

    &:hover {
      color: $orange;
    }
  }

  &__body {
    display: flex;
    align-items: center;
    margin: 0 0 34px;

    @include lg {
      padding: 5.5px 0;
      margin: 0;
    }
  }

  &__logo {
    display: inline-flex;
    flex-shrink: 0;

    @include lg {
      margin-left: 32px;
    }
  }

  &__logo-img {
    width: 142px;
    height: 32px;

    @include lg {
      width: 130px;
      height: 32px;
    }
  }

  &__search {
    margin: 0 89px 0 90px;

    @include lg {
      display: none;
    }
  }

  &__body-check-order {
    height: 40px;
    min-width: 152px;
    font-size: 14px;
    border-radius: 20px;

    @include lg {
      display: none;
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    margin-left: auto;
  }

  &__buttons-item {
    display: inline-flex;
    margin-right: 36px;
    cursor: pointer;

    &:last-child {
      margin-right: 0;
    }

    &--account {
      @include lg {
        display: none;
      }
    }

    &--logout {
      @include lg {
        display: none;
      }
    }

    &--search {
      display: none;

      @include lg {
        display: inline-flex;
      }

      .header {
        &__buttons-item-link {
          position: relative;

          &::before {
            position: absolute;
            top: -12px;
            left: -12px;
            width: 39px;
            height: 39px;
            background-color: rgba($orange, 0.1);
            border-radius: 50%;
            content: "";
          }
        }

        &__buttons-item-icon {
          width: 14px;
          height: 14px;
          fill: $orange;
        }
      }
    }
  }

  &__buttons-item-link {
    position: relative;
    display: inline-flex;
  }

  &__buttons-item-icon {
    width: 26px;
    height: 26px;
    fill: $grey;
  }

  &__buttons-item-num {
    position: absolute;
    top: -8px;
    right: -3px;
    z-index: 1;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 22px;
    height: 22px;
    font-size: 11px;
    color: $white;
    background-color: $orange;
    border: 2px solid $white;
    border-radius: 50%;
  }

  &__nav {
    background-color: $whisper;

    @include lg {
      display: none;
    }
  }

  &__nav-list {
    display: flex;
    height: 70px;
  }

  &__nav-list-item {
    display: inline-flex;
    align-items: center;
    height: 100%;
    padding: 0 15px;
    font-size: 16px;
    color: $grey;
    transition: $transition;

    &:hover {
      background-color: $mystic;
    }
  }

  &__toggle-mobile-nav {
    display: none;
    padding: 20px;

    @include lg {
      display: inline-flex;
    }

    @include xs {
      padding-left: 0;
    }
  }

  &__toggle-mobile-nav-icon {
    width: 14px;
    height: 14px;
    fill: $orange;
  }

  &__mini-title {
    margin-left: 31px;
    font-size: 32px;
  }

  &__mini-phone {
    margin-left: auto;
    font-size: 20px;
    color: $black;
    transition: $transition;

    &:hover {
      color: $orange;

      @include lg {

        color: $black;
      }
    }
  }
}
/* end header */
