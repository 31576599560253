/* begin checkout */
.checkout {
  margin-top: 23px;

  @include lg {
    margin-top: 30px;
  }

  &__wrapper {
    display: flex;
    flex-wrap: wrap;
  }

  &__title {
    display: none;

    @include lg {
      display: block;
      width: 100%;
      margin-bottom: 50px;
    }

    @include xs {
      margin-bottom: 43px;
    }
  }

  &__body {
    width: calc(100% - 300px - 100px);
    margin-right: 100px;

    @include md {
      width: 100%;
      margin: 0;
    }
  }

  &__form-title {
    margin: 27px 0 23px;
    font-weight: 500;
    color: $black;

    @include xs {
      margin: 33px 0 24px;
      font-size: 16px;
    }

    span {
      color: $grayDusty;
    }
  }

  &__form-radio-row {
    margin-top: 27px;
  }

  &__form-radio {
    width: 100%;
    height: 100%;

    &--payment-method {
      .checkout__form-radio-body {
        @include xs {
          min-height: 145px;
        }
      }
    }
  }

  &__form-radio-input {
    display: none;

    &:checked + .checkout__form-radio-body {
      border-color: $orange;

      .checkout {
        &__form-radio-el {
          background-color: $orange;
          border-color: $orange;

          &::after {
            opacity: 1;
          }
        }
      }
    }
  }

  &__form-radio-body {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 18px;
    cursor: pointer;
    background-color: $white;
    border: solid 2px $greyPinkish;
    border-radius: 5px;
    transition: $transition;
  }

  &__form-radio-title {
    font-size: 16px;
    color: $black;
  }

  &__form-radio-info {
    margin: 10px 0 21px;
    font-size: 14px;
    color: $grayDusty;
  }

  &__form-radio-el {
    position: relative;
    width: 24px;
    height: 24px;
    margin-top: auto;
    border: 2px solid $greyPinkish;
    border-radius: 50%;

    &::after {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 8px;
      height: 8px;
      background-color: $white;
      border-radius: 50%;
      content: "";
      opacity: 0;
      transform: translate(-50%, -50%);
      transition: $transition;
    }
  }

  &__form-leader-list {
    width: 100%;
    margin-top: 21px;

    .leader-list {
      &__row {
        &:last-child {
          .leader-list__col {
            &:last-child {
              font-size: 22px;
              font-weight: 500;
            }
          }
        }
      }

      &__col {
        font-size: 16px;
        font-weight: 400;
        color: $black;

        &:last-child {
          width: auto;
        }
      }
    }
  }

  &__form-checkox {
    margin: 49px 0 0;

    .checkbox {
      &__text {
        font-size: 14px;
        color: #999999;
      }

      &__el {
        width: 24px;
        height: 24px;
        margin: 4px 0 0;
        border-radius: 5px;
      }
    }
  }

  &__form-button {
    width: auto;
    min-width: 204px;
    margin-top: 29px;

    @include xs {
      width: 100%;
    }
  }

  &__sidebar {
    flex-shrink: 0;
    width: 300px;
    padding: 27px 0 0;

    @include md {
      display: none;
    }
  }

  &__sidebar-info {
    padding: 30px 20px;
    font-size: 14px;
    line-height: 20px;
    color: $grayDusty;
    background-color: rgba($orange, 0.05);
    border-radius: 5px;
  }

  &__sidebar-info-list {
    margin-top: 20px;
  }

  &__sidebar-info-link {
    font-size: 14px;
    line-height: 20px;
    color: $black;
    transition: $transition;

    &:hover {
      color: $orange;

      @include lg {
        color: $black;
      }
    }
  }

  &__sidebar-info-price {
    font-size: 14px;
    color: $grayDusty;
  }

  &__sidebar-features {
    margin-top: 50px;
  }

  &__sidebar-features-item {
    display: flex;
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__sidebar-features-icon {
    flex-shrink: 0;
    width: 35px;
    height: 35px;
    margin-right: 20px;
    fill: $charcoal;
  }

  &__sidebar-features-text {
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    color: $grey;
  }

  &__pickpoint {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 23px;
  }

  &__pickpoint-title {
    margin-bottom: 12px;
    font-weight: 500;
    color: $black;
  }

  &__pickpoint-info {
    margin-bottom: 30px;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    color: $black;
  }

  &__pickpoint-button {
    height: 60px;
    min-width: 153px;
  }
}
/* end checkout */
