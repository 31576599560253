/* begin section */
.section {
  &--main-slider {
    .section {
      &__wrapper {
        @include xs {
          padding: 0;
        }
      }
    }
  }

  &--product-slider {
    .section {
      &__wrapper {
        @include xs {
          padding-right: 0;
        }
      }
    }
  }

  &--devices-mobile {
    display: none;

    @include sm {
      display: block;
    }
  }
}
/* end section */
