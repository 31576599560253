/* begin slider */
.slider {
  &--main-slider {
    width: 100%;
    padding-bottom: 30px;

    .slider {
      &__img {
        width: 100%;
      }
    }

    .swiper-pagination {
      position: static;
      margin-top: 20px;
    }
  }

  &--product-slider,
  &--news-slider,
  &--product-slider-mini {
    padding: 0 30px 45px;
    margin: 0 -30px;

    @include lg {
      padding: 0 20px 45px;
      margin: 0 -20px;
    }

    @include md {
      padding: 0 0 45px;
      margin: 0;
    }
  }

  &--product-slider,
  &--product-slider-mini {
    @include md {
      .swiper-slide {
        width: 270px !important;
      }
    }
  }

  &__title {
    margin-bottom: 35px;
  }

  .swiper-pagination {
    bottom: -3px;
  }

  .swiper-pagination-bullet {
    width: 25px;
    height: 10px;
    background-color: $bisque;
    border-radius: 8px;
    opacity: 1;
    transition: $transition;

    &:hover {
      background-color: $orange;
    }
  }

  .swiper-pagination-bullet-active {
    background-color: $orange;
  }

  &>.swiper-pagination-bullets {
    & .swiper-pagination-bullet {
      margin: 0 10px 0 0;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
/* end slider */
