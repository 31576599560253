/* begin firmware */
.firmware {
  &__wrapper {
    width: 680px;
    max-width: 100%;
    margin: 0 auto;
  }

  &__title {
    margin-bottom: 50px;
    text-align: center;

    @include sm {
      margin-bottom: 23px;
    }
  }

  &__desc {
    margin-bottom: 50px;
    font-size: 14px;
    line-height: 20px;
    color: $charcoal;

    @include sm {
      text-align: center;
    }
  }

  &__selects {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 30px;

    @include sm {
      flex-direction: column;
    }
  }

  &__select {
    width: calc(50% - 20px);
    margin-bottom: 20px;

    @include sm {
      width: 100%;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
  }

  &__item {
    margin-bottom: 20px;
  }

  &__link {
    position: relative;
    display: flex;
    align-items: center;
    min-height: 80px;
    padding: 15px 20px 15px 30px;
    overflow: hidden;
    background-color: $white;
    border-radius: 5px;
    box-shadow: 0 0 7px 0 rgba($charcoal, 0.1);
    transition: $transition;

    @include sm {
      flex-wrap: wrap;
      min-height: 0;
      padding: 30px 20px 30px 30px;
    }

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 3px;
      height: 100%;
      background-color: $blue;
      content: "";
    }

    &:hover {
      background-color: rgba($orange, 0.1);
    }
  }

  &__item-type {
    margin-right: 20px;
    font-size: 14px;
    color: $charcoal;

    @include sm {
      order: 1;
    }
  }

  &__item-changelog {
    margin-right: 30px;
    overflow: hidden;
    font-size: 13px;
    font-weight: 300;
    line-height: 20px;
    color: $charcoal;
    text-align: center;

    @include sm {
      margin-top: 20px;
      margin-right: 0;
      text-align: left;
    }
  }

  &__item-series {
    margin-right: 30px;
    font-size: 13px;
    font-weight: 300;
    line-height: 20px;
    color: $charcoal;
    text-align: center;

    @include sm {
      margin-top: 20px;
      margin-right: 0;
      text-align: left;
    }
  }

  &__item-model {
    font-size: 13px;
    line-height: 20px;
    color: $orange;
    text-align: center;

    @include sm {
      text-align: right;
    }
  }

  &__item-col {
    margin-left: auto;

    &:first-child {
      margin-left: 0;
    }

    &--type {
      flex-shrink: 0;
      width: 101px;

      @include sm {
        order: 1;
        width: auto;
      }
    }

    &--changelog {
      flex-shrink: 0;
      width: 218px;

      @include sm {
        order: 3;
        width: 100%;
      }
    }

    &--series {
      flex-shrink: 0;
      width: 210px;

      @include sm {
        order: 4;
        width: 100%;
      }
    }

    &--model {
      @include sm {
        order: 2;
      }
    }
  }
}
/* end firmware */
