/* begin select */
.select {
  .ss-single-selected {
    height: 60px;
    padding: 0 18px;
    font-size: 14px;
    color: $black;
    background-color: $white;
    border: 2px solid $greyPinkish;
    border-radius: 5px;
  }

  .ss-content {
    margin: -2px 0 0;
    border: 2px solid $greyPinkish;
    border-radius: 5px;

    .ss-list {
      .ss-option {
        &:hover {
          color: $black;
          background-color: rgba($orange, 0.1);
        }

        &.ss-highlighted {
          &:hover {
            color: $black;
            background-color: rgba($orange, 0.1);
          }
        }

        &.ss-disabled {
          &:hover {
            color: #dedede;
            cursor: not-allowed;
            background-color: $white;
          }
        }
      }
    }
  }

  .ss-option {
    display: flex;
    align-items: center;
    height: 60px;
    padding: 0 18px;
    font-size: 14px;
    color: $black;
  }
}
/* end select */
