/* begin product */
.product {
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  padding: 40px 33px 33px;
  overflow: hidden;
  border: 2px solid $greyPale;
  border-radius: 20px;
  transition: $transition box-shadow;

  @include lg {
    transition: 0s;
  }

  &:hover {
    box-shadow: 0 2px 5px 0 rgba(155, 155, 155, 0.5);

    @include lg {
      box-shadow: none;
    }
  }

  &--quick-order {
    padding: 0;
    border: 0;
    border-radius: 0;

    &:hover {
      box-shadow: none;
    }

    .product {
      &__img-link {
        padding: 0 37px;

        @include sm {
          padding: 0 17px;
        }
      }

      &__img {
        height: 225px;
      }

      &__title {
        height: auto;
        margin: 49px 0 30px;

        @include sm {
          margin: 48px 0 42px;
          line-height: 23px;
        }
      }

      &__price {
        padding: 0 0 22px;
      }
    }
  }

  &--basket {
    flex-direction: row;
    padding: 25px 0 24px 0;
    margin-bottom: 20px;
    border: 0;
    border-bottom: 1px solid $paleGrey;
    border-radius: 0;

    @include sm {
      flex-wrap: wrap;
      padding: 0 0 22px;
      margin: 0;
    }

    &:hover {
      box-shadow: none;
    }

    .product {
      &__img-link {
        margin-right: 30px;

        @include sm {
          margin-right: 15px;
          margin-bottom: 34px;
        }
      }

      &__img {
        width: 100px;
        height: 100px;
      }

      &__title {
        width: 270px;
        margin: 10px 30px 0 0;

        @include sm {
          width: calc(100% - 115px);
          margin: 9px 0 34px 0;
        }
      }

      &__footer {
        align-items: flex-start;
        padding-top: 30px;
        margin-left: auto;

        @include sm {
          padding: 0;
          margin: 0;
        }
      }

      &__price {
        width: 125px;
        padding-bottom: 22px;
        margin: 0;

        @include sm {
          width: auto;
          padding: 0;
          margin-top: 8px;
        }
      }

      &__price-current {
        @include sm {
          font-size: 22px;
        }
      }

      &__price-old {
        @include sm {
          display: none;
        }
      }

      &__price-profit {
        @include sm {
          display: none;
        }
      }
    }
  }

  &__badges {
    position: absolute;
    top: 12px;
    left: 18px;
  }

  &__compare {
    position: absolute;
    top: 18px;
    right: 18px;
  }

  &__compare-input {
    display: none;

    &:checked {
      & + .product__compare-icon {
        fill: $orange;
      }
    }
  }

  &__compare-icon {
    width: 15px;
    height: 15px;
    cursor: pointer;
    transition: $transition;
    fill: $greyish;
  }

  &__img {
    @include objectFit(contain, center);
    width: 100%;
    height: 184px;
  }

  &__title {
    flex-grow: 1;
    margin: 18px 0 12px;
    line-height: 20px;
    transition: $transition color;

    &:hover {
      color: $orange;

      @include lg {
        color: $black;
      }
    }
  }

  &__footer {
    display: flex;
  }

  &__price {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    margin: 8px 15px 0 0;
  }

  &__price-current {
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
    color: $black;
  }

  &__price-old {
    position: absolute;
    top: -20px;
    left: 0;
    font-size: 13px;
    font-weight: 300;
    line-height: 20px;
    color: $greyWarm;
    text-decoration: line-through;
  }

  &__price-profit {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 17px;
    padding: 0 9px;
    font-size: 12px;
    font-weight: 300;
    line-height: 17px;
    color: $greyWarm;
    background-color: $seashell;
    border-radius: 7.5px;
  }

  &__button {
    display: inline-flex;
    align-items: center;
    flex-shrink: 0;
    justify-content: center;
    width: 50px;
    height: 50px;
    cursor: pointer;
    background: transparent;
    border: 1px solid $gainsboro;
    border-radius: 25px;
    outline: 0;
    transition: $transition;

    &--checked {
      border-color: $orange;

      .product__button-icon {
        width: 15px;
        height: 15px;
        fill: $orange;
      }
    }

    &:hover {
      border-color: $orange;

      @include lg {
        border-color: $gainsboro;
      }

      .product__button-icon {
        fill: $orange;

        @include lg {
          fill: $grey;
        }
      }
    }
  }

  &__button-icon {
    width: 25px;
    height: 25px;
    transition: $transition;
    fill: $grey;
  }

  &__counter {
    display: inline-flex;
    align-items: center;
    flex-shrink: 0;
    justify-content: center;
    margin-right: 20px;

    @include sm {
      margin-right: 30px;
    }
  }

  &__counter-button {
    width: 30px;
    height: 30px;
    background-color: rgba($orange, 0.05);
    border: 0;
    border-radius: 5px;
    outline: 0;
  }

  &__counter-input {
    width: 28px;
    font-size: 14px;
    color: $black;
    text-align: center;
    border: 0;
    outline: 0;
  }

  &__delete {
    position: absolute;
    top: 10px;
    right: 10px;
    display: inline-flex;
    width: 16px;
    height: 16px;
    background-color: transparent;
    border: 0;
    outline: 0;

    @include sm {
      top: 87px;
      right: 0;
    }
  }

  &__delete-icon {
    width: 16px;
    height: 16px;
    fill: $grey;

    @include sm {
      width: 11px;
      height: 11px;
    }
  }
}
/* end product */
