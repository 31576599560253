/* begin popup */
.popup {
  &__item {
    &#mobile-search-popup {
      .hystmodal {
        &__window {
          width: 100%;
          height: calc(100% - 65px);
          margin: 65px 0 0;

          @include lg {
            border-radius: 0;
          }
        }
      }
    }

    &#choose-your-city-popup {
      .hystmodal {
        &__window {
          width: 1130px;
          max-width: 100%;
          padding: 30px 50px 50px;
        }
      }

      .popup {
        &__title {
          margin-bottom: 42px;
        }
      }
    }

    &#login-popup,
    &#login-code-popup {
      .hystmodal {
        &__window {
          width: 420px;
          max-width: 100%;
          padding: 50px 30px;
        }
      }

      .popup {
        &__title {
          margin-bottom: 63px;
        }
      }
    }

    &#quick-order-popup {
      .hystmodal {
        &__window {
          width: 650px;
          max-width: 100%;
          padding: 50px 138px;

          @include sm {
            width: 100%;
            height: 100%;
            padding: 73px 20px 50px;
            border-radius: 0;
          }
        }
      }
    }

    &#support-popup {
      .hystmodal {
        &__window {
          width: 420px;
          max-width: 100%;
          overflow: hidden;
          border-radius: 10px;

          @include xs {
            width: 100%;
            border-radius: 0;
          }
        }
      }
    }
  }

  &__close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 20px;
    cursor: pointer;

    &:hover {
      .popup__close-icon {
        fill: $black;
      }
    }
  }

  &__close-icon {
    width: 16px;
    height: 16px;
    fill: $grey;
    transition: $transition;
  }

  &__choose-city-list {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -30px;
  }

  &__choose-city-radio {
    width: 25%;
    margin-bottom: 29px;
  }

  &__choose-city-search {
    margin-top: 51px;

    .search {
      &__form {
        width: 100%;
        height: 60px;
        border-radius: 5px;
      }
    }
  }

  &__form {
    .form {
      &__button {
        margin-top: 20px;
      }
    }
  }

  &__link {
    font-size: 14px;
    color: $charcoal;
    transition: $transition;

    &:hover {
      color: $orange;

      @include lg {
        color: $charcoal;
      }
    }

    &--login {
      display: inline-flex;
      justify-content: center;
      width: 100%;
      margin-top: 50px;
      text-align: center;
    }

    &--resend {
      margin-top: 34px;
      color: $red;
      text-align: center;

      &:hover {
        color: $red;
      }
    }

    &--resend-seconds {
      margin-top: 34px;
      text-align: center;
      pointer-events: none;
    }
  }

  &__login-code {
    display: flex;
    justify-content: space-between;
  }

  &__login-code-input {
    display: inline-flex;
    width: calc(100% / 6 - 10px);
    height: 50px;
    font-size: 16px;
    color: $grey;
    text-align: center;
    border: 2px solid $greyPinkish;
    border-radius: 10px;
    outline: none;
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      margin: 0;
      -webkit-appearance: none;
    }
  }

  &__login-code-phone {
    display: flex;
    align-items: flex-end;
  }

  &__login-code-phone-num {
    font-size: 16px;
    color: $charcoal;
  }

  &__login-code-phone-button {
    margin-left: 30px;
    font-size: 14px;
    color: $red;
    cursor: pointer;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 23px 20px;
    background-color: $orange;

    .popup {
      &__close {
        position: static;
        padding: 0;
      }

      &__close-icon {
        width: 12px;
        height: 12px;
        fill: #ffffff;
      }
    }
  }

  &__header-title {
    font-size: 16px;
    color: $white;
  }

  &__text-block {
    padding: 20px 20px 50px;
  }
}

.hystmodal {
  &__window {
    border-radius: 10px;
    transition: transform 0.4s ease-in-out 0s, opacity 0.4s ease-in-out 0s;
  }

  &__shadow {
    background-color: $black;
    transition: opacity 0.4s ease-in-out;

    &--show {
      opacity: 0.7;
    }
  }
}
/* end popup */
