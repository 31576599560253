/* begin mmenu */
.mmenu {
  position: fixed;
  top: 0;
  left: -310px;
  z-index: 10;
  width: 310px;
  height: 100%;
  overflow: hidden;
  box-shadow: 0 0 7px 0 rgba(74, 74, 74, 0.1);

  &__frame {
    position: absolute;
    top: 0;
    left: 0;
    width: 310px;
    height: 100%;
    overflow: hidden;
    background-color: $white;
    transition: 0.5s;

    &--scroll {
      overflow-y: auto;
    }
  }

  &__frame-header {
    display: flex;
    align-items: center;
    height: 66px;
    padding: 0 20px;
    border-bottom: 1px solid $whiteTwo;
  }

  &__frame-header-icon {
    width: 26px;
    height: 26px;
    margin-right: 21px;
    fill: $grey;
  }

  &__frame-header-text {
    font-size: 16px;
    font-weight: 300;
    color: $charcoal;
  }

  &__frame-list {
    display: flex;
    flex-direction: column;

    &--account {
      & > .mmenu__frame-item {
        & > .mmenu__frame-link {
          height: 66px;
          padding: 0 20px 0 0;

          & > .mmenu__frame-link-icon-box {
            & > .mmenu__frame-link-icon {
              width: 26px;
              height: 26px;
              fill: $grey;
            }
          }

          & > .mmenu__frame-link-text {
            font-size: 16px;
          }
        }
      }
    }

    .mmenu {
      &__frame {
        left: 310px;

        &--current {
          left: 0;
        }
      }
    }
  }

  &__frame-item {
    &--separator {
      cursor: default;
      background-color: $orangeLight;

      .mmenu {
        &__frame-link {
          border: 0;
        }

        &__frame-link-text {
          color: $orange;
        }
      }

      & + .mmenu__frame-item {
        .mmenu {
          &__frame-link {
            border: 0;
          }
        }
      }
    }

    &:last-child {
      .mmenu__frame-link {
        border-bottom: 1px solid $whiteTwo;
      }
    }
  }

  &__frame-link,
  &__back {
    display: flex;
    align-items: center;
    height: 60px;
    padding-left: 20px;
    border-top: 1px solid $whiteTwo;
  }

  &__frame-link-text,
  &__back-text {
    width: 100%;
    font-size: 14px;
    font-weight: 300;
    color: $charcoal;
  }

  &__frame-link-icon-box,
  &__back-icon-box {
    display: inline-flex;
    align-items: center;
    flex-shrink: 0;
    justify-content: center;
    width: 52px;
    height: 100%;
  }

  &__frame-link-icon {
    width: 12px;
    height: 12px;
    fill: $black;
  }

  &__phone {
    display: flex;
    margin: 50px 20px 0 20px;
    font-size: 16px;
    color: $charcoal;
  }

  &__address {
    display: flex;
    max-width: 195px;
    margin: 50px 0 0 20px;
    font-size: 14px;
    line-height: 20px;
    color: $charcoal;
  }

  &__feedback {
    display: inline-flex;
    margin: 20px 0 50px 20px;
    font-size: 14px;
    color: $orange;
  }

  &__back {
    padding: 0 20px 0 0;
  }

  &__back-icon {
    width: 12px;
    height: 12px;
    transform: rotate(180deg);
    fill: $black;
  }

  &__back-text {
    font-size: 16px;
  }
}
/* end mmenu */
