// This mixin can be used to set the objectFit:
// @include objectFit(contain);
// or object-fit and object-position:
// @include objectFit(cover, top);
@mixin objectFit($fit: fill, $position: null) {
  object-fit: $fit;
  @if $position {
    font-family: "object-fit: #{$fit}; object-position: #{$position}";
    object-position: $position;
  } @else {
    font-family: "object-fit: #{$fit}";
  }
}

// Медиа-запросы
// @include lg {
//   background: red;
// }
@mixin lg {
  @media (max-width: 1279.98px) { @content; }
}

@mixin md {
  @media (max-width: 991.98px) { @content; }
}

@mixin sm {
  @media (max-width: 767.98px) { @content; }
}

@mixin xs {
  @media (max-width: 575.98px) { @content; }
}
