/* begin text-block */
.text-block {
  &--shipping-payment {
    .wrapper {
      max-width: 585px;
    }

    img {
      margin-right: 0 !important;
      margin-left: 0 !important;
    }
  }

  &--support {
    .wrapper {
      max-width: 720px;
    }
  }

  &--qa {
    .wrapper {
      max-width: 860px;
    }
  }

  &--review {
    .wrapper {
      max-width: 680px;
    }

    .reviews {
      display: flex;
      justify-content: center;

      &__header {
        margin-bottom: 50px;
      }
    }

    h1 {
      &.title {
        margin-bottom: 30px;
        text-align: center;
      }
    }
  }

  p {
    margin-bottom: 20px;
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    color: $charcoal;

    &:last-child {
      margin-bottom: 0;
    }

    @include md {
      font-size: 14px;
      line-height: 20px;
    }
  }

  h1 {
    margin: 50px 0;
    text-align: center;

    @include xs {
      text-align: left;
    }

    &:first-child {
      margin-top: 0;
    }
  }

  h3 {
    margin: 30px 0;
    font-size: 20px;
    font-weight: 400;
    color: $black;

    &:first-child {
      margin-top: 0;
    }
  }

  h4 {
    margin: 20px 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
    color: $black;

    &:first-child {
      margin-top: 0;
    }
  }

  h5 {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: $charcoal;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
    max-width: 580px;
    margin: 30px auto;
  }

  a {
    color: $orange;
    transition: $transition;

    &:hover {
      opacity: 0.75;

      @include lg {
        opacity: 1;
      }
    }
  }

  ul {
    display: flex;
    flex-direction: column;
    margin: 20px 0;
  }

  li {
    position: relative;
    padding-left: 15px;
    font-size: 14px;
    font-weight: 300;
    line-height: 30px;
    color: $charcoal;

    &::before {
      position: absolute;
      top: 14px;
      left: 0;
      width: 5px;
      height: 5px;
      background-color: $orange;
      border-radius: 50%;
      content: "";
    }
  }

  hr {
    width: 100%;
    height: 1px;
    margin: 50px 0;
    background-color: $orangeLight;
    border: 0;
  }

  &__subtitle {
    margin: 20px 0 50px;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.43;
    color: rgba($charcoal, 0.5);
    text-align: center;
  }
}
/* end text-block */
