/* begin news */
.news {
  &__img {
    @include objectFit(cover, center);
    width: 100%;
    height: 200px;
    border-radius: 20px;
    opacity: 0.69;
  }

  &__title {
    display: flex;
    margin: 20px 0 10px;
    font-weight: 500;
    line-height: 28px;
    transition: $transition;

    &:hover {
      color: $orange;

      @include lg {
        color: $black;
      }
    }
  }

  &__text {
    font-size: 14px;
    font-weight: 300;
    line-height: 25px;
    color: $charcoal;
  }
}
/* end news */
