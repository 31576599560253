/* begin checkbox */
.checkbox {
  &--red {
    .checkbox {
      &__el {
        border-color: $redFaded;
      }

      &__el-icon {
        fill: $redFaded;
      }
    }
  }

  &--quick-order {
    margin-bottom: 23px;

    .checkbox {
      &__text {
        font-size: 14px;
        font-weight: 300;
        line-height: 20px;
        color: #999999;
      }

      &__el {
        width: 24px;
        height: 24px;
        margin: 4px 0 0;
        border-radius: 5px;
      }
    }
  }

  &__input {
    display: none;

    &:checked {
      & + .checkbox__body {
        .checkbox {
          &__el-icon {
            opacity: 1;
          }
        }
      }
    }

    &:disabled {
      & + .checkbox__body {
        cursor: default;
        opacity: 0.5;
      }
    }
  }

  &__body {
    display: inline-flex;
    align-items: flex-start;
    cursor: pointer;
  }

  &__el {
    display: inline-flex;
    align-items: center;
    flex-shrink: 0;
    justify-content: center;
    width: 22px;
    height: 22px;
    border: solid 2px $greyPinkish;
    border-radius: 2px;
  }

  &__el-icon {
    width: 10px;
    height: 10px;
    opacity: 0;
    transition: $transition;
    fill: $greyPinkish;
  }

  &__text {
    margin-left: 9px;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: $charcoal;
  }

  &__link {
    color: $redFaded;
    transition: $transition;

    &:hover {
      opacity: 0.75;

      @include lg {
        opacity: 1;
      }
    }
  }
}
/* end checkbox */
