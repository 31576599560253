/* begin product-card */
.product-card {
  &--handbook {
    display: flex;
    flex-wrap: wrap;
    margin-top: 89px;

    @include xs {
      margin: auto;
    }

    .product-card {
      &__spec-tab {
        padding: 0;
        margin-top: 46px;

        @include xs {
          width: 100%;
        }
      }

      &__info-specifications,
      &__go-to-tab {
        @include xs {
          display: none;
        }
      }

      &__sliders {
        @include xs {
          margin: 31px 0 0;
        }
      }
    }
  }

  &__wrapper {
    display: flex;
    flex-wrap: wrap;

    @include md {
      flex-direction: column;
      flex-wrap: nowrap;
    }
  }

  &__title {
    @include xs {
      line-height: 30px;
    }
  }

  &__sliders {
    display: flex;
    flex-shrink: 0;
    width: 565px;
    margin-right: 40px;

    @include lg {
      width: 490px;
    }

    @include md {
      width: 565px;
      margin: 50px 0 0;
    }

    @include sm {
      width: 100%;
      margin: 50px 0 0;
    }

    @include xs {
      flex-direction: column;
    }
  }

  &__slider-thumbs {
    flex-shrink: 0;
    width: 100px;
    height: 500px;
    padding: 20px 0;
    margin: -20px 10px -20px 0;
    margin-right: 10px;

    .swiper-slide {
      padding: 8px;
      cursor: pointer;
      background-color: $white;
      border: 2px solid rgba($orange, 0.1);
      border-radius: 20px;
      transition: $transition border-color;

      &:hover {
        border-color: rgba($orange, 1);
      }

      &.swiper-slide-thumb-active {
        border-color: rgba($orange, 1);
      }
    }

    .swiper-button-prev,
    .swiper-button-next {
      left: 50%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      transform: translateX(-50%);

      @include xs {
        display: none;
      }

      &::after {
        content: none;
      }

      &:hover {
        .swiper-button-icon {
          fill: $orange;
        }
      }

      &.swiper-button-disabled {
        opacity: 1 !important;
      }
    }

    .swiper-button-prev {
      top: 15px;

      .swiper-button-icon {
        transform: rotate(-90deg);
      }
    }

    .swiper-button-next {
      top: auto;
      bottom: -7px;

      .swiper-button-icon {
        transform: rotate(90deg);
      }
    }

    .swiper-button-icon {
      width: 16px;
      height: 16px;
      transition: $transition;
      fill: $bisque;
    }

    @include xs {
      order: 1;
      width: 100%;
      height: calc((100vw - 40px - 15px) / 4);
    }
  }

  &__slider-main {
    width: 455px;
    height: 455px;

    @include lg {
      width: 380px;
    }

    @include md {
      width: 455px;
    }

    @include sm {
      width: 406px;
    }

    @include xs {
      width: 100%;
      height: calc(100vw - 40px);
      margin-bottom: 29px;
    }
  }

  &__img {
    @include objectFit(contain, center);
    width: 100%;
    height: 100%;
  }

  &__info {
    flex-shrink: 0;
    width: calc(100% - 565px - 40px);

    @include lg {
      width: calc(100% - 490px - 40px);
    }

    @include md {
      width: 100%;
    }
  }

  &__undertitle {
    display: flex;
    margin-top: 23px;

    @include lg {
      flex-wrap: wrap;
    }

    @include md {
      flex-wrap: nowrap;
    }

    @include xs {
      flex-wrap: wrap;
      margin-top: 32px;
    }
  }

  &__rating {
    margin-right: 49px;

    @include lg {
      width: 100%;
      margin: 0 0 20px;
    }

    @include md {
      width: auto;
      margin: 0 49px 0 0;
    }

    @include xs {
      width: 100%;
      margin: 0 0 30px;
    }
  }

  &__checkbox-button {
    margin-right: 28px;

    &:last-child {
      margin-right: 0;
    }

    &--compare {
      .product-card__checkbox-button-icon {
        width: 12px;
        height: 12px;
      }
    }
  }

  &__checkbox-button-body {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
  }

  &__checkbox-button-icon {
    width: 14px;
    height: 14px;
    margin-right: 10px;
    transition: $transition fill;
    fill: $charcoal;
  }

  &__checkbox-button-input {
    display: none;

    &:checked {
      & + .product-card__checkbox-button-body {
        .product-card {
          &__checkbox-button-icon {
            fill: $orange;
          }

          &__checkbox-button-text {
            color: $orange;
          }
        }
      }
    }
  }

  &__checkbox-button-text {
    position: relative;
    bottom: 1px;
    font-size: 16px;
    color: $charcoal;
    transition: $transition color;
  }

  &__info-specifications {
    margin: 26px 0 0;
  }

  &__go-to-tab {
    display: inline-flex;
    margin: 25px 0 0;
    font-size: 14px;
    color: $orange;
    background: transparent;
    border: 0;
    outline: 0;
    transition: $transition;

    &:hover {
      opacity: 0.75;

      @include lg {
        opacity: 1;
      }
    }
  }

  &__price {
    margin: 52px 0 0 0;
    font-size: 36px;
    color: $grey;

    @include xs {
      margin-top: 34px;
    }
  }

  &__buttons {
    display: flex;
    margin: 30px 0 0;

    @include xs {
      flex-direction: column;
    }
  }

  &__button {
    &--buy {
      min-width: 155px;
      margin-right: 30px;

      @include xs {
        margin: 0 0 10px;
      }
    }

    &--buy-one-click {
      min-width: 217px;
    }
  }

  &__button-icon {
    width: 22px;
    height: 22px;
    margin-right: 10px;
    fill: $white;
  }

  &__tabs {
    width: 100%;
    margin-top: 50px;

    @include xs {
      margin-top: 53px;
    }
  }

  &__tabs-slider {
    width: 100%;

    &--thumbs {
      @include xs {
        position: relative;
        width: calc(100% + 40px);
        padding-bottom: 3px;
        margin: 0 -20px;
      }

      &::after {
        @include xs {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 3px;
          background-color: rgba($charcoal, 0.1);
          content: "";
        }
      }
    }

    &--body {
      @include xs {
        width: calc(100% + 40px);
        margin: 0 -20px;
      }

      .swiper-slide {
        padding-top: 50px;
      }
    }
  }

  &__tabs-nav-link {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: auto;
    height: 60px;
    padding: 0 35px;
    font-size: 16px;
    color: $grey;
    cursor: pointer;
    background-color: rgba($orange, 0.1);
    border-radius: 5px;
    outline: 0;
    transition: $transition background-color;

    @include xs {
      height: auto;
      padding: 17px 0;
      font-size: 14px;
      color: $charcoal;
      background-color: transparent;
    }

    &::after {
      @include xs {
        position: absolute;
        bottom: -3px;
        left: 0;
        width: 100%;
        height: 3px;
        background-color: $charcoal;
        content: "";
        opacity: 0;
        transition: $transition;
      }
    }

    &:first-child {
      @include xs {
        padding-left: 20px;

        &::after {
          left: 20px;
          width: calc(100% - 20px);
        }
      }
    }

    &.swiper-slide-thumb-active,
    &:hover {
      background-color: rgba($orange, 0.2);

      @include xs {
        background-color: transparent;
      }
    }

    &.swiper-slide-thumb-active {
      pointer-events: none;

      @include xs {
        &::after {
          opacity: 1;
        }
      }
    }
  }

  &__desc-tab {
    display: flex;
    padding: 0 20px;

    @include md {
      flex-direction: column;
    }
  }

  &__desc-tab-text-block {
    width: 565px;
    max-width: 100%;
    padding: 0 21px;
    margin-right: 20px;

    @include sm {
      margin-right: 0;
    }

    @include xs {
      padding: 0;
    }

    &--docs {
      width: auto;
      margin-right: 0;

      @include md {
        margin-top: 40px;
      }
    }

    .product-card {
      &__docs-item {
        padding: 0;
        line-height: inherit;

        &::before {
          content: none;
        }
      }

      &__docs-name {
        color: $black;
      }
    }
  }

  &__docs-list {
    display: flex;
    flex-direction: column;
    margin: -3px 0 0;
  }

  &__docs-item {
    display: inline-flex;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__docs-format {
    display: inline-flex;
    align-items: center;
    width: auto;
    height: 20px;
    padding: 0 5px;
    margin-right: 10px;
    font-size: 13px;
    line-height: 20px;
    color: $white;
    background-color: $grey;
    border-radius: 5px;
  }

  &__docs-name {
    font-size: 14px;
    font-weight: 300;
    color: $black;
    transition: $transition color;

    &:hover {
      color: $orange;

      @include lg {
        color: $black;
      }
    }
  }

  &__spec-tab {
    padding: 0 20px;
  }

  &__spec-table {
    display: flex;
    flex-direction: column;
    width: 540px;
    max-width: 100%;

    @include xs {
      width: calc(100% + 40px);
      max-width: calc(100% + 40px);
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__spec-table-row {
    display: flex;
    padding: 9px 0;

    &:nth-child(odd) {
      background-color: $wildSand;
    }
  }

  &__spec-table-key,
  &__spec-table-val {
    padding: 0 20px;
    font-size: 14px;
    font-weight: 300;
    color: $black;
  }

  &__spec-table-key {
    width: 372px;

    @include sm {
      width: 320px;
    }

    @include xs {
      width: 200px;
    }
  }

  &__spec-tab-text-block {
    .product-card {
      &__spec-table {
        margin-bottom: 30px;

        @include xs {
          margin: 0 -20px 30px;
        }
      }

      &__spec-table-row {
        padding: 9px 0;
        line-height: 16px;

        &::before {
          content: none;
        }
      }
    }
  }

  &__comment-tab {
    padding: 0 20px;
  }

  &__delivery-tab {
    width: 575px;
    max-width: 100%;
    padding: 0 20px;

    .form {
      &__row {
        margin-bottom: 27px;
      }
    }
  }

  &__delivery-list {
    width: 505px;
    max-width: 100%;
  }
}

.lg-backdrop {
  background-color: rgba($black, 0.6);
}
/* end product-card */
