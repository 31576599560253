/* begin search */
.search {
  &__form {
    display: flex;
    width: 552px;
    height: 52px;
    border: 2px solid #cccccc;
    border-radius: 20px;
  }

  &__input {
    width: 100%;
    padding: 0 0 0 18px;
    font-size: 16px;
    font-weight: 300;
    color: $charcoal;
    border: 0;
    border-radius: 20px 0 0 20px;
    outline: 0;
  }

  &__button {
    flex-shrink: 0;
    height: 100%;
    padding: 0 17px;
    background: transparent;
    border: 0;
    border-radius: 0 20px 20px 0;
    outline: 0;
  }

  &__button-icon {
    width: 24px;
    height: 24px;
    fill: $grey;
  }
}
/* end search */
