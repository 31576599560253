/* begin newsletter */
.newsletter {
  padding: 18px 0 16px;
  background-color: $mystic;

  @include md {
    display: none;
  }

  &__wrapper {
    display: flex;
    align-items: center;

    @include lg {
      flex-wrap: wrap;
    }
  }

  &__logo {
    flex-shrink: 0;
    margin-right: 51px;
  }

  &__logo-img {
    width: 142px;
    height: 32px;
  }

  &__info {
    display: flex;
    flex-direction: column;
    max-width: 424px;

    @include lg {
      margin-left: auto;
      text-align: right;
    }
  }

  &__info-title {
    margin-bottom: 6px;
    font-size: 22px;
    line-height: 20px;
    color: $black;
  }

  &__info-text {
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    color: $charcoal;
  }

  &__form {
    position: relative;
    display: flex;
    flex-direction: row;
    margin-left: auto;

    @include lg {
      margin-top: 18px;
    }

    .form__row {
      margin: 0;
      opacity: 1;
      transition: $transition;
      transition-delay: $transition;
    }

    &--success {
      .form__row {
        opacity: 0;
        transition-delay: 0s;
      }

      .newsletter__button {
        opacity: 0;
        transition-delay: 0s;
      }

      .newsletter__success {
        opacity: 1;
        transition: $transition;
        transition-delay: $transition;
      }
    }
  }

  &__input {
    width: 360px;
    height: 60px;
    padding: 0 60px 0 30px;
    margin-right: -35px;
    background-color: $white;
    border: 0;
    border-radius: 20px 0 0 20px;
    outline: none;

    &::placeholder {
      color: $greyWarm;
    }
  }

  &__button {
    min-width: 165px;
    background-color: $botticelli;
    border-radius: 20px;
    transition: $transition;
    transition-delay: $transition;
  }

  &__success {
    position: absolute;
    top: 50%;
    left: 0;
    font-size: 14px;
    font-weight: 300;
    color: $charcoal;
    opacity: 0;
    transform: translateY(-50%);
    transition: $transition;

    @include lg {
      right: 0;
      left: auto;
      text-align: right;
    }
  }
}
/* end newsletter */
