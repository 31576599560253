/* begin colors */
$grey: #555555;
$greyWarm: #979797;
$black: #000000;
$orange: #fe900b;
$red: #ff5656;
$greyish: #aaaaaa;
$gainsboro: #e6e6e6;
$white: #ffffff;
$seashell: #fff5eb;
$bisque: #ffe3c2;
$blue: #456990;
$charcoal: #4a4a4a;
$mystic: #e4e8f1;
$botticelli: #d7dcea;
$whisper: #f2f3f8;
$grayDusty: #9b9b9b;
$redFaded: #d63031;
$greyPale: #f2f6fb;
$grayPeriwinkle: #cbdaed;
$whiteTwo: #f4f4f4;
$mercury: #e5e5e5;
$orangeLight: #fef3e6;
$greyPinkish: #cccccc;
$shamrock: #00ad4d;
$wildSand: #f5f5f5;
$paleGrey: #f3f6fb;
$lightPeach: #fedfba;
$pinkishGrey: #c8c8c8;
/* end colors */

/* begin other */
$transition: 0.3s;
/* end other */
