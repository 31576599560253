/* begin msearch */
.msearch {
  display: flex;
  flex-direction: column;

  &__wrapper {
    @include xs {
      padding: 0;
    }
  }

  &__header {
    padding: 17px 0;
    background-color: $whiteTwo;

    @include xs {
      padding: 17px 20px;
    }
  }

  &__input {
    width: 100%;
    height: 50px;
    padding: 0 18px;
    font-size: 15px;
    color: $charcoal;
    background-color: $white;
    border: 2px solid $mercury;
    outline: none;
  }

  &__list {
    display: flex;
    flex-direction: column;
  }

  &__item {
    border-top: 1px solid $mystic;

    &:first-child {
      border-top: 0;
    }

    &--all {
      border-top: 0;

      .msearch {
        &__link {
          background-color: $orangeLight;
        }

        &__text {
          font-weight: 400;
          color: $orange;
        }
      }
    }
  }

  &__link {
    display: flex;
    align-items: center;
    min-height: 59px;
    padding: 13px 20px;
  }

  &__text {
    width: 100%;
    margin-right: 20px;
    font-size: 14px;
    font-weight: 300;
    color: $charcoal;
  }

  &__icon {
    flex-shrink: 0;
    width: 12px;
    height: 12px;
    fill: $black;
  }
}
/* end msearch */
