/* begin title */
.title {
  font-weight: 400;
  color: $black;

  &--reviews {
    .title {
      &__h1 {
        margin-bottom: 30px;
        font-weight: 400;
        color: $black;
        text-align: center;
      }
    }
  }

  &--h1,
  &__h1 {
    font-size: 30px;

    @include xs {
      font-size: 22px;
    }
  }

  &--h2 ,
  &__h2 {
    font-size: 22px;
  }

  &--h4,
  &__h4 {
    font-size: 16px;
  }

  &--h5 ,
  &__h5 {
    font-size: 14px;
  }

  &--h6,
  &__h6 {
    font-size: 13px;
  }
}
/* end title */
