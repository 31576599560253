/* begin badge */
.badge {
  display: inline-flex;
  align-items: center;
  height: 26px;
  padding: 0 10px;
  font-size: 14px;
  font-weight: 300;
  color: $white;
  background-color: $blue;
  border-radius: 9px;

  &--blue {
    background-color: $blue;
  }

  &--black {
    background-color: $charcoal;
  }

  &--orange {
    background-color: $orange;
  }

  &--red {
    background-color: $red;
  }

  &--green {
    background-color: $shamrock;
  }
}
/* end badge */
