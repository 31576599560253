/* begin loader */
.loader {
  position: relative;
  overflow: hidden;
  pointer-events: none;

  &.product__button {
    .loader {
      &__item {
        background-size: 40px;
      }
    }

    .product {
      &__button-icon {
        opacity: 0;
      }
    }
  }

  &__item {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgba(#ffffff, 0.7);
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' fill='%23fe900b'%3E%3Cpath opacity='0.4' d='M20.201 5.169c-8.254 0-14.946 6.692-14.946 14.946 0 8.255 6.692 14.946 14.946 14.946s14.946-6.691 14.946-14.946c-.001-8.254-6.692-14.946-14.946-14.946zm0 26.58c-6.425 0-11.634-5.208-11.634-11.634 0-6.425 5.209-11.634 11.634-11.634 6.425 0 11.633 5.209 11.633 11.634 0 6.426-5.208 11.634-11.633 11.634z'/%3E%3Cpath d='M26.013 10.047l1.654-2.866a14.855 14.855 0 00-7.466-2.012v3.312c2.119 0 4.1.576 5.812 1.566z'%3E%3CanimateTransform attributeType='xml' attributeName='transform' type='rotate' from='0 20 20' to='360 20 20' dur='0.8s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 50px;
  }
}
/* end loader */
