/* begin form */
.form {
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  &--quick-order {
    margin: 53px 0 0;
  }

  &--support-popup {
    padding: 30px 20px;
    background-color: $whisper;

    .form {
      &__title {
        width: 100%;
        font-weight: 700;
        line-height: 24px;
        color: #252525;
        text-align: center;
      }

      &__inner-title {
        margin-bottom: 20px;
        font-size: 15px;
        line-height: 24px;
        color: #252525;
        text-align: center;
      }

      &__block {
        width: calc(100% + 40px);
        padding: 30px 20px;
        margin: 0 -20px;
        border-top: 1px solid $mystic;

        &:last-child {
          padding-bottom: 0;
        }
      }

      &__input,
      &__textarea {
        font-size: 14px;
        font-weight: 300;
        line-height: 20px;
        color: $charcoal;
        border: 1px solid #cccfd8;

        &::placeholder {
          color: $charcoal;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      &__button {
        margin-top: 20px;
      }

      &__disclaimer {
        margin-top: 20px;
        font-size: 13px;
        font-weight: 300;
        line-height: 20px;
        color: $charcoal;
        text-align: center;
      }

      &__row {
        margin-bottom: 10px;
      }
    }

    .ss-main {
      .ss-content {
        border: 1px solid #cccfd8;
      }

      .ss-option {
        font-size: 14px;
        font-weight: 300;
        line-height: 20px;
        color: $charcoal;
      }

      .ss-single-selected {
        border: 1px solid #cccfd8;

        .placeholder {
          font-size: 14px;
          font-weight: 300;
          line-height: 20px;
          color: $charcoal;

          .ss-disabled {
            font-size: 14px;
            font-weight: 300;
            line-height: 20px;
            color: $charcoal;
          }
        }
      }
    }
  }

  &__title {
    margin-bottom: 23px;
  }

  &__row {
    width: 100%;
    margin-bottom: 23px;

    &:last-child {
      margin-bottom: 0;
    }

    &--login-code-phone {
      margin-bottom: 42px;
    }

    &--2col {
      display: flex;

      @include xs {
        flex-direction: column;
      }

      .form {
        &__col {
          width: calc(50% - 10px);

          @include xs {
            width: 100%;
            margin: 0 0 23px;
          }

          &:first-child {
            margin-right: 10px;

            @include xs {
              margin-right: 0;
            }
          }

          &:last-child {
            margin-left: 10px;

            @include xs {
              margin: 0;
            }
          }
        }
      }
    }
  }

  &__col {
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    &--rating {
      align-items: center;
      flex-direction: row;
      margin-top: 9px;
      margin-bottom: 30px;

      @include xs {
        margin-top: 42px;
      }

      .form {
        &__label {
          margin: 0 42px 0 0;
        }
      }
    }
  }

  &__label {
    margin-bottom: 12px;
    font-size: 14px;
    color: $black;

    &--required {
      position: relative;

      &::after {
        position: absolute;
        top: -3px;
        right: -14px;
        font-size: 16px;
        color: $red;
        content: "*";
      }
    }
  }

  &__input {
    width: 100%;
    height: 60px;
    padding: 0 18px;
    font-size: 16px;
    color: $grey;
    background-color: $white;
    border: 2px solid $greyPinkish;
    border-radius: 5px;
    outline: none;
  }

  &__textarea {
    width: 100%;
    height: 120px;
    padding: 18px;
    background-color: $white;
    border: 2px solid $greyPinkish;
    border-radius: 5px;
    outline: none;
    resize: none;
  }

  &__button {
    width: 100%;
  }

  &__delivery-input-box {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 60px;
    padding-left: 12px;
    background-color: $white;
    border: solid 2px $greyPinkish;
    border-radius: 5px;
  }

  &__delivery-input-icon {
    flex-shrink: 0;
    width: 30px;
    height: 30px;
    fill: $greyPinkish;
  }

  &__delivery-input {
    width: 100%;
    height: 100%;
    padding-left: 20px;
    font-size: 16px;
    color: $black;
    border: 0;
    outline: 0;
  }

  &__delivery-input-clear-box {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 0 18px;
    background: transparent;
    border: 0;
    outline: 0;

    &:hover {
      .form__delivery-input-clear {
        fill: $black;

        @include lg {
          fill: $grey;
        }
      }
    }
  }

  &__delivery-input-clear {
    flex-shrink: 0;
    width: 10px;
    height: 10px;
    transition: $transition;
    fill: $grey;
  }

  &__hint {
    margin: 5px 5px 0;
    font-size: 14px;
    color: $red;
  }

  &__input-box {
    position: relative;
    width: 100%;
  }

  &__input-drop {
    position: absolute;
    top: calc(100% + 5px);
    left: 0;
    z-index: 10;
    width: 100%;
    max-height: 148px;
    padding: 30px 20px;
    overflow-y: auto;
    background-color: $white;
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 rgba($charcoal, 0.25);
  }

  &__input-drop-list {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }

  &__input-drop-item {
    margin-bottom: 20px;
    font-size: 14px;
    color: $black;
    cursor: pointer;
    transition: $transition;

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      color: $orange;

      @include lg {
        color: $black;
      }
    }
  }

  &__erorr {
    border-color: $red;
  }
}
/* end form */
