/* begin comment */
.comment {
  width: 575px;
  max-width: 100%;

  &--message {
    width: 100%;
    padding: 50px 0 200px;

    @include xs {
      padding: 70px 0 100px;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    padding: 0 30px 0 0;

    @include sm {
      align-items: flex-start;
    }
  }

  &__avatar {
    display: inline-flex;
    align-items: center;
    flex-shrink: 0;
    justify-content: center;
    width: 55px;
    height: 55px;
    margin-right: 20px;
    font-size: 14px;
    color: $charcoal;
    background-color: $orangeLight;
    border-radius: 50%;
  }

  &__header-info {
    display: flex;
    align-items: center;
    width: 100%;

    @include sm {
      align-items: center;
      flex-wrap: wrap;
      padding: 8px 0 0;
    }
  }

  &__username {
    flex-shrink: 0;
    margin-right: 30px;
    font-size: 16px;
    line-height: 17px;
    color: $black;
  }

  &__date {
    font-size: 14px;
    color: $grayDusty;
  }

  &__rating {
    margin: 0 0 0 auto;

    @include sm {
      order: -1;
      width: 100%;
      margin: 0 0 10px;
    }

    .rating {
      &__text {
        margin: 0 15px 0 0;
        font-size: 18.2px;
        color: $charcoal;
      }
    }
  }

  &__body {
    padding: 33px 0 0 75px;

    @include sm {
      padding-left: 0;
    }
  }

  &__form {
    padding: 0 20px 0 75px;
    margin: 99px 0 0 0;

    @include sm {
      padding: 0;
      margin: 70px 0 0 0;
    }
  }

  &__form-button {
    width: auto;
    min-width: 160px;
    margin-top: 27px;

    @include xs {
      width: 100%;
      min-width: auto;
      margin-top: 7px;
    }
  }

  &__message {
    font-size: 20px;
    font-weight: 300;
    color: $charcoal;
    text-align: center;

    @include xs {
      font-size: 14px;
    }
  }

  &__na-title {
    margin: 0 0 21px;
  }

  &__na-message {
    font-size: 14px;
    font-weight: 300;
    line-height: 22px;
    color: $black;
  }

  &__na-button {
    min-width: 198px;
    margin-top: 29px;

    @include xs {
      width: 100%;
      min-width: auto;
    }
  }
}
/* end comment */
