/* begin push */
.push {
  transition: 0.5s;

  &--open {
    left: 0;

    @include lg {
      left: 0 !important;
    }
  }
}

.push-button {
  position: relative;
}
/* end push */
