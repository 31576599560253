/* begin button */
.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  padding: 0 15px;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
  border: 0;
  border-radius: 5px;
  outline: 0;
  transition: $transition background-color, $transition color;

  &--orange {
    color: $white;
    background-color: $orange;

    &:hover {
      background-color: lighten($orange, 3%);

      @include lg {
        background-color: $orange;
      }
    }
  }

  &--botticelli {
    color: $grey;
    background-color: $botticelli;

    &:hover {
      background-color: lighten($botticelli, 3%);

      @include lg {
        background-color: $botticelli;
      }
    }
  }

  &--red {
    color: $white;
    background-color: $red;

    &:hover {
      background-color: lighten($red, 5%);

      @include lg {
        background-color: $red;
      }
    }
  }

  &--orange-opacity {
    color: $orange;
    background-color: rgba($orange, 0.2);

    &:hover {
      background-color: darken(rgba($orange, 0.2), 5%);

      @include lg {
        background-color: rgba($orange, 0.2);
      }
    }
  }
}
/* end button */
