// sass-lint:disable max-line-length
// font-weight: 100 Thin (Hairline) | 200 Extra Light (Ultra Light) | 300 Light | 400 Normal | 500 Medium | 600 Semi Bold (Demi Bold) | 700 Bold | 800 Extra Bold (Ultra Bold) | 900 Black (Heavy)
// font-style: normal | italic | oblique

/* begin fonts */
@font-face {
  font-family: "ubuntu";
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/ubuntu/ubuntu-v14-latin-ext_latin_cyrillic-ext_cyrillic-300.woff2") format("woff2"), url("../fonts/ubuntu/ubuntu-v14-latin-ext_latin_cyrillic-ext_cyrillic-300.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "ubuntu";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/ubuntu/ubuntu-v14-latin-ext_latin_cyrillic-ext_cyrillic-regular.woff2") format("woff2"), url("../fonts/ubuntu/ubuntu-v14-latin-ext_latin_cyrillic-ext_cyrillic-regular.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "ubuntu";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/ubuntu/ubuntu-v14-latin-ext_latin_cyrillic-ext_cyrillic-500.woff2") format("woff2"), url("../fonts/ubuntu/ubuntu-v14-latin-ext_latin_cyrillic-ext_cyrillic-500.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "robotofont";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/roboto/roboto-v20-latin-ext_latin_cyrillic-regular.woff2") format("woff2"), url("../fonts/roboto/roboto-v20-latin-ext_latin_cyrillic-regular.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "lg";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/lg.woff") format("woff");
  font-display: swap;
}
/* end fonts */

