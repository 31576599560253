/* begin buy */
.buy {
  &__wrapper {
    display: flex;
    flex-wrap: wrap;

    @include md {
      align-items: center;
      flex-direction: column;
    }
  }

  &__form {
    width: 100%;
    margin-bottom: 50px;

    .form {
      &__row {
        width: 545px;
        max-width: 100%;
        margin: 0 auto;
      }
    }
  }

  &__nav {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    width: 270px;
    margin-right: 30px;

    @include md {
      display: none;
    }
  }

  &__nav-item {
    transition: $transition;

    &--active {
      .buy {
        &__nav-link {
          color: $orange;
          border-color: $orangeLight;

          &::before {
            background-color: $lightPeach;
          }
        }
      }
    }

    &:hover {
      .buy {
        &__nav-link {
          color: $orange;
        }
      }
    }
  }

  &__nav-link {
    position: relative;
    display: flex;
    align-items: center;
    height: 60px;
    margin: 0 0 0 20px;
    font-size: 14px;
    font-weight: 300;
    color: $black;
    border-bottom: 1px solid rgba($charcoal, 0.1);
    transition: $transition;

    &::before {
      position: absolute;
      top: 50%;
      left: -20px;
      width: 2px;
      height: 50px;
      background-color: $charcoal;
      content: "";
      transform: translateY(-50%);
      transition: $transition;
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% - 300px);

    @include md {
      display: inline-flex;
      width: 570px;
      margin: 0 auto;
    }

    @include sm {
      width: 100%;
    }
  }

  &__item {
    width: 270px;
    height: 250px;
    padding: 30px 35px;
    margin: 0 30px 30px 0;
    background-color: $white;
    border-radius: 5px;
    box-shadow: 0 0 7px 0 rgba(74, 74, 74, 0.1);

    @include sm {
      width: 100%;
      height: 223px;
      margin-right: 0;
      text-align: center;
    }

    &:nth-child(3n) {
      margin-right: 0;

      @include lg {
        margin-right: 30px;
      }

      @include sm {
        margin-right: 0;
      }
    }

    &:nth-child(2n) {
      @include lg {
        margin-right: 0;
      }
    }
  }

  &__logo {
    @include objectFit(contain, center);
    width: 200px;
    height: 60px;
  }

  &__title {
    max-height: 80px;
    min-height: 40px;
    margin: 10px 0;
    line-height: 20px;
    color: $charcoal;
  }

  &__address {
    margin-bottom: 10px;
    font-size: 13px;
    font-weight: 300;
    line-height: 20px;
    color: $charcoal;
  }

  &__link {
    padding-bottom: 2px;
    font-size: 13px;
    font-weight: 300;
    color: $orange;
    border-bottom: 1px solid rgba($orange, 0.1);
  }

  &__nav-mobile {
    position: relative;
    display: none;
    justify-content: center;
    width: 375px;
    height: 60px;
    margin-bottom: 20px;
    border-bottom: 1px solid $whiteTwo;

    @include md {
      display: inline-flex;
    }

    @include sm {
      width: 100%;
    }

    @include xs {
      width: calc(100% + 40px);
      margin: 0 -20px 20px;
    }

    &::before {
      position: absolute;
      top: 50%;
      left: 0;
      width: 2px;
      height: 30px;
      background-color: $charcoal;
      content: "";
      transform: translateY(-50%);
    }
  }

  &__nav-mobile-select {
    width: 100%;
    padding: 0 20px;
    border: 0;
    outline: none;
    -moz-appearance: none;
    -webkit-appearance: none;
  }

  &__nav-mobile-icon {
    position: absolute;
    top: 50%;
    right: 20px;
    width: 12px;
    height: 12px;
    transform: rotate(90deg);
    fill: $black;
  }
}
/* end buy */
