/* begin contacts */
.contacts {
  &__wrapper {
    display: flex;

    @include md {
      flex-direction: column;
    }
  }

  &__map {
    flex-shrink: 0;
    width: 545px;
    height: 1033px;
    margin-right: 40px;

    @include md {
      width: 100%;
      height: 300px;
      margin-bottom: 50px;
    }

    @include xs {
      width: calc(100% + 40px);
      margin: 0 -20px 50px;
    }
  }

  &__text-block {
    h1 {
      margin-bottom: 30px;
      text-align: left;
    }

    h5 {
      color: $orange;
    }
  }

  &__button {
    min-width: 249px;
  }
}
/* end contacts */
