/* begin overlay */
.overlay {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9;
  width: 100%;
  height: 100%;
}
/* end overlay */
